<template>
  <div v-if="getJsonData">
    <b-card>
      <div slot="header">
        <strong>Article Selection</strong>
      </div>
      <div>
        {{ selectedFiscalWeek }}
        <!-- <b-alert v-model="errorFlag" variant="danger" dismissible>!! {{messageText}}</b-alert> -->
        <!-- <div v-if="errorFlag" style="color: red;" align="center">{{messageText}}</div> -->
        <data-table
          :colDefs="articlesColDefs"
          :data="getJsonData.articles.selections"
          :masterDetailflag="true"
          :count="getJsonData.articles.selections.length"
          @removedSuccess="removedSuccess"
          @onClear="onClear"
          :key="forceRerender"
          @cellValueChanged="cellValueChanged"
          @step2MassApply="onMassApply"
          @updatedDataOnRemove="updatedDataOnRemove"
          @selectedItems="(v) => (selectedItems = v)"
        >
          <template slot-scope="props">
            Actions:
            <span>
              <i
                v-if="!selectedItems.length"
                v-b-tooltip.hover
                title="Please select an article to use this option"
                class="fas fa-paint-brush cursor-not-allowed"
                style="margin-right: 1rem"
              ></i>
              <i
                v-else
                class="fas fa-paint-brush cursor-pointer"
                style="margin-right: 1rem"
                id="massUpdatePopOver1"
                @click="popoverShowCheck"
              ></i>
            </span>
            <span>
              <b-button
                v-if="!selectedItems.length"
                variant="link"
                style="
                  cursor: not-allowed;
                  border: none !important;
                  margin-bottom: 7px;
                  margin-left: -12px;
                "
                >Clear</b-button
              >

              <b-button
                v-else
                variant="link"
                @click="props.onClear"
                style="
                  border: none !important;
                  margin-bottom: 7px;
                  margin-left: -12px;
                "
                >Clear</b-button
              >
            </span>
              <b-modal
              id="adminSettings"
              v-model="popoverShowWarning"
              size="md"
              centered
              @ok="popoverShow = true"
            >
            There are rows where the DC20 checkbox is disabled. On DC mass apply, DC's for these rows would default to DC01/DC04/DC06.
            </b-modal>
            <b-modal
              id="adminSettings"
              v-model="popoverShow"
              size="md"
              centered
              title="Apply Values on Multiple Select"
              hide-footer
              @show="onShow"
            >
              <template slot="title">
                <b-button
                  class="close"
                  style="color: #ffffff"
                  aria-label="Close"
                  @click="onClose"
                >
                  <span class="d-inline-block" aria-hidden="true"
                    >&times;</span
                  > </b-button
                >Apply Values on Multiple Select
              </template>
              <!-- <div v-on-click-outside="close"> -->
              <div>
                <div>
                  <b-form-group>
                    <b-row class="py-1">
                      <b-col sm="5">
                        <label>Season Start Fiscal Week:</label>
                      </b-col>
                      <b-col sm="7">
                        <b-form-select
                          style="width: 50%"
                          v-model="week"
                          :options="weeks"
                          size="sm"
                          @change="onWeek"
                          :close-on-select="true"
                          :clear-on-select="false"
                          :preserve-search="true"
                          placeholder="Merch Hierarchy"
                        >
                          <template
                            slot="selection"
                            slot-scope="{ values, search, isOpen }"
                          >
                            <span v-if="values.length &amp;&amp; !isOpen"
                              >{{ values.length }} options selected</span
                            >
                          </template>
                        </b-form-select>
                        <b-form-select
                          style="width: 50%"
                          v-model="year"
                          :options="years"
                          size="sm"
                          @change="onYear"
                          :close-on-select="true"
                          :clear-on-select="false"
                          :preserve-search="true"
                          placeholder="Merch Hierarchy"
                        >
                          <template
                            slot="selection"
                            slot-scope="{ values, search, isOpen }"
                          >
                            <span v-if="values.length &amp;&amp; !isOpen"
                              >{{ values.length }} options selected</span
                            >
                          </template>
                        </b-form-select>
                      </b-col>
                    </b-row>
                    <!-- 
                  <b-row class="my-1">
                    <b-col sm="5">
                      <label>In Store Week:</label>
                    </b-col>
                    <b-col sm="7">
                      <input v-model="inStoreDate" type="week" name="week" id="camp-week" value="" disabled required>
                    </b-col>
                    </b-row>-->

                    <b-row class="my-1">
                      <b-col sm="1">
                        <input
                          style="vertical-align: middle"
                          type="checkbox"
                          name="dropShiprOrDcTwenty"
                          ref="dropShipMassApplyCheckBoxRef"
                          v-model="dropShipMassApplyCheckBox"
                          @change="onHandleDropShipOrDcTwenty($event, 'dc09')"
                        />
                      </b-col>
                      <b-col sm="5">
                        <label
                          style="vertical-align: middle"
                          for="articleOwnSalesHistory"
                        >
                          Drop Ship
                        </label>
                      </b-col>
                      <b-col sm="1">
                        <input
                          style="vertical-align: middle"
                          name="dropShiprOrDcTwenty"
                          ref="dcTwentyMassApplyCheckBoxRef"
                          type="checkbox"
                          v-model="dcTwentyMassApplyCheckBox"
                          @change="onHandleDropShipOrDcTwenty($event, 'dc20')"
                        />
                      </b-col>
                      <b-col sm="5">
                        <label
                          style="vertical-align: middle"
                          for="articleOwnSalesHistory"
                        >
                          DC20
                        </label>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </div>
                <div>
                  <b-form-group>
                    <b-row class="my-1">
                      <b-col sm="3">
                        <label></label>
                      </b-col>
                      <b-col sm="4">
                        <label>Min:</label>
                      </b-col>
                      <b-col sm="4">
                        <label>Max:</label>
                      </b-col>
                    </b-row>
                    <b-row
                      v-bind:key="inputType.label"
                      v-for="inputType of inputList"
                      class="my-1"
                    >
                      <b-col sm="3">
                        <label>{{ inputType.label }}</label>
                      </b-col>
                      <b-col sm="4">
                        <b-form-input
                          @change="onHandleMin(inputType)"
                          v-model="inputType.min.value"
                          type="number"
                          placeholder="Min"
                          width="50%"
                        />
                      </b-col>
                      <b-col sm="4">
                        <b-form-input
                          @change="onHandleMax(inputType)"
                          v-model="inputType.max.value"
                          type="number"
                          placeholder="Max"
                          width="50%"
                        />
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <div align="center" style="color: red">{{ errorMsg }}</div>
                  <b-button
                    @click="onApply(props.onUpdate)"
                    size="sm"
                    variant="primary"
                    >Apply</b-button
                  >
                </div>
              </div>
            </b-modal>
            <!-- <b-popover
              v-if="selectedItems.length"
              target="massUpdatePopOver1"
              triggers="click"
              :show.sync="popoverShow"
              placement="auto"
              container="myContainer"
              ref="popover"
              @show="onShow"
            >-->

            <!-- </b-popover> -->
            <i
              v-b-tooltip.hover
              title="Download Template"
              class="fa fa-download cursor-pointer"
              style="
                margin-right: 1rem;
                font-size: 1.1rem;
                color: #70be00;
                font-weight: 500;
              "
              @click="downloadTemplate"
            ></i>
            <i
              v-b-tooltip.hover
              title="Upload Template"
              class="fa fa-upload cursor-pointer"
              style="
                margin-right: 1rem;
                font-size: 1.1rem;
                color: #70be00;
                font-weight: 500;
              "
              @click="uploadTemplate"
            ></i>
            <i
              v-b-tooltip.hover
              title="Expand All"
              @click="props.expand"
              class="fa fa-plus-square-o cursor-pointer"
              style="
                margin-right: 1rem;
                font-size: 1.1rem;
                color: #c71585;
                font-weight: 500;
              "
            ></i>
            <i
              v-b-tooltip.hover
              title="Collapse All"
              @click="props.collapse"
              class="fa fa-minus-square-o cursor-pointer"
              style="
                margin-right: 1rem;
                font-size: 1.1rem;
                color: #c71585;
                font-weight: 500;
              "
            ></i>
            <i
              v-if="!selectedItems.length"
              v-b-tooltip.hover
              title="Please select row(s) to Exclude"
              class="far fa-trash-alt cursor-not-allowed"
              style="
                margin-right: 1rem;
                font-size: 1.1rem;
                color: #c71585;
                font-weight: 500;
              "
            ></i>
            <i
              v-else
              class="far fa-trash-alt cursor-pointer"
              style="
                margin-right: 1rem;
                font-size: 1.1rem;
                color: #c71585;
                font-weight: 500;
              "
              @click="props.onDelete"
            ></i>
            <!-- <i
              @click="props.onDelete"
              v-b-tooltip.hover title="Exclude row(s)"
              class="far fa-trash-alt cursor-pointer"
              style="margin-right:1rem;font-size: 1.1rem; color: #c71585; font-weight: 500;"
            ></i>-->
          </template>
        </data-table>
      </div>
    </b-card>
    <b-card>
      <div slot="header">
        <strong>Article Exclusion</strong>
      </div>
      <selections
        @excludeByPastingArticles1="excludeByPastingArticles"
        @filterData="excludeByPastingArticles"
      ></selections>
      <div>
        <data-table
          :colDefs="ArticleExclusionColDef"
          :data="getJsonData.articles.exclusions"
          :count="getJsonData.articles.exclusions.length"
          :ifRowClasRule="true"
          :key="forceRerender"
          @removedSuccess="removedSuccessExclusion"
          @updatedDataOnRemove="updatedExclusionTableDataOnRemove"
          @selectedItems="(v) => (exclusionTableSelectedItems = v)"
        >
          <template slot-scope="props">
            Actions:
            <span>
              <i
                v-if="!exclusionTableSelectedItems.length"
                v-b-tooltip.hover
                title="Please select row(s) to Reinclude"
                class="far fa-trash-alt cursor-not-allowed"
                style="margin-right: 1rem; color: #c71585"
              ></i>
              <i
                v-else
                class="far fa-trash-alt cursor-pointer"
                style="margin-right: 1rem; color: #c71585"
                @click="props.onDelete"
              ></i>
            </span>
            <!-- <i
              v-b-tooltip.hover title="Reinclude row(s)"
              @click="props.onDelete"
              class="far fa-trash-alt cursor-pointer ml-2"
              style="color: #c71585"
            ></i>-->
          </template>
        </data-table>
      </div>
    </b-card>
    <b-modal
      v-model="onSuccess"
      centered
      title="BootstrapVue"
      ok-only
      hide-header
    >
      <div align="middle">
        <h5 class="my-4">This information was saved successfully.</h5>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { format } from "date-fns";
import { concat, isEqual } from "lodash";
import Selections from "@/components/Selections";
import DataTable from "@/components/DataTable";
import articlesColDefs from "@/constants/tableDefinitions/articlesColDefs";
import fiscalWeeks from "@/constants/fiscalWeek";
import ArticleExclusionColDef from "@/constants/tableDefinitions/ArticleExclusionColDef";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { filter, cloneDeep } from "lodash";
import { constants } from "zlib";
import { parse } from "url";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
var handler = function (e) {
  var confirmationMessage = "Your unsave data will lost.";
  let tab = e || window.event;
  tab.returnValue = confirmationMessage;
  return confirmationMessage;
};

export default {
  props: [
    "isStep2ForceRenderer",
    "allocationId",
    "prevState",
  ],
  computed: {
    ...mapGetters({
      getJsonData: "CreateAllocation/getJsonData",
      getTempJsonData: "CreateAllocation/getTempJsonData",
      getFetchMinMaxDateAndFW: "CreateAllocation/getFetchMinMaxDateAndFW",
    }),
  },
  data() {
    return {
      forceRerender: false,
      dialogOptions: {
        cancelText: "Cancel",
        animation: "bounce",
        backdropClose: true,
      },
      errorFlag: false,
      messageText: "",
      selectedFiscalWeek: "",
      fiscalWeeks,
      errorMsg: "",
      weeks: [],
      years: [],
      week: null,
      year: null,
      seasonStartDate2: "",
      fileSelector: null,
      flagDownload: false,
      selectedFile: null,
      format,
      message: "This step may take some time. Thank you for your patience.2",
      loaderText: "",
      onSuccess: false,
      dropShipMassApplyCheckBox: false,
      dcTwentyMassApplyCheckBox: false,
      dropShipMassApplyCheckBoxRef: null,
      dcTwentyMassApplyCheckBoxRef: null,
      isErrorInMinMax: false,
      inputList: [
        {
          label: "All Stores:",
          min: {
            key: "allStoresMin",
            value: null,
          },
          max: {
            key: "allStoresMax",
            value: null,
          },
        },
        {
          label: "AVL01:",
          min: {
            key: "AVL01Min",
            value: null,
          },
          max: {
            key: "AVL01Max",
            value: null,
          },
        },
        {
          label: "AVL02:",
          min: {
            key: "AVL02Min",
            value: null,
          },
          max: {
            key: "AVL02Max",
            value: null,
          },
        },
        {
          label: "AVL03:",
          min: {
            key: "AVL03Min",
            value: null,
          },
          max: {
            key: "AVL03Max",
            value: null,
          },
        },
        {
          label: "AVL04:",
          min: {
            key: "AVL04Min",
            value: null,
          },
          max: {
            key: "AVL04Max",
            value: null,
          },
        },
        {
          label: "AVL05",
          min: {
            key: "AVL05Min",
            value: null,
          },
          max: {
            key: "AVL05Max",
            value: null,
          },
        },
        {
          label: "AVL06:",
          min: {
            key: "AVL06Min",
            value: null,
          },
          max: {
            key: "AVL06Max",
            value: null,
          },
        },
        {
          label: "AVL07",
          min: {
            key: "AVL07Min",
            value: null,
          },
          max: {
            key: "AVL07Max",
            value: null,
          },
        },
        {
          label: "AVL08:",
          min: {
            key: "AVL08Min",
            value: null,
          },
          max: {
            key: "AVL08Max",
            value: null,
          },
        },
        {
          label: "AVL09:",
          min: {
            key: "AVL09Min",
            value: null,
          },
          max: {
            key: "AVL09Max",
            value: null,
          },
        },
      ],
      seasonStartFiscalWeek: null,
      // inStoreDate: null,
      popoverShow: false,
      popoverShowWarning: false,
      objsToUpdate: [],
      tableData: [],
      articlesColDefs,
      ArticleExclusionColDef,
      exclusionTableData: [],
      selectedItems: [],
      exclusionTableSelectedItems: [],
    };
  },
  components: {
    Selections,
    DataTable,
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", handler);
    this.articlesColDefs = null;
    this.ArticleExclusionColDef = null;
    this.selectedItems = null;
  },
  created() {
    this.$root.$on("GridAPI", this.getRefApi);
    let minFW = this.getFetchMinMaxDateAndFW.minFW.slice(4, 6);
    let maxFW = this.getFetchMinMaxDateAndFW.maxFW.slice(4, 6);
    let mixFWY = this.getFetchMinMaxDateAndFW.minFW.slice(0, 4);
    let maxFWY = this.getFetchMinMaxDateAndFW.maxFW.slice(0, 4);
    for (let i = Number(minFW); i <= Number(maxFW); i++) {
      if (i <= 9) {
        this.weeks.push("0" + i);
      } else {
        this.weeks.push(i.toString());
      }
    }
    for (let i = Number(mixFWY); i <= Number(maxFWY); i++) {
      this.years.push(i.toString());
    }
    // this.$root.$on("filterData", this.excludeByPastingArticles);
    // if (this.allocationId && this.prevState) {
    //   this.allocationByAllocationId({allocationID: this.allocationId});
    // }
    // if (this.allocationId) {
    //   this.allocationByAllocationId({allocationID: this.allocationId}).then(resp => {
    //     this.$router.push(`/createAllocation/${resp.allocationBasics.allocationStage}/${this.allocationId}`)
    //   })
    // }
  },
  mounted() {
    let showPopup = true;
    let isNoInventoryAssociated = false;
    let isNoLMAssociated = false;

    let errorMessage = [];

    if (showPopup) {
      this.getJsonData.articles.exclusions.forEach((obj) => {
        if (!obj.lms.length) {
          isNoLMAssociated = true
        } else {
          isNoInventoryAssociated = true
        }
      });

      if (isNoLMAssociated) {
        errorMessage.push(
          "Some of the articles don’t have a LM associated with them."
        );
      }

      if (isNoInventoryAssociated) {
        errorMessage.push(
          "Some of the articles don’t have a Inventory associated with them."
        );
      }

      errorMessage.push("These are moved to the Exclusions table below.");

      if (errorMessage.length > 1) {
        this.$dialog.alert(errorMessage.join("<br>"), { html: true });
        showPopup = false;
      }
    }
    let disableFlag = isEqual(
      this.getTempJsonData.articles.exclusions,
      this.getJsonData.articles.exclusions
    );
    this.$root.$emit("step2Flag", disableFlag);

    let colArr = [];
    this.articlesColDefs.columnDefs.forEach((obj, index) => {
      if (obj.field != "id") {
        colArr.push(obj);
      }
    });
    colArr.forEach((obj) => {
      if (obj.field === "article_id") {
        obj.headerCheckboxSelection = true;
        obj.headerCheckboxSelectionFilteredOnly = true;
        obj.checkboxSelection = true;
      }
      if (obj.field === "calender") {
        (obj.filter = "agDateColumnFilter"),
          (obj.filterParams = {
            comparator: (filterLocalDateAtMidnight, cellValue) => {
              var dateAsString = cellValue;
              var dateParts = dateAsString.split("/");
              var cellDate = new Date(
                Number(dateParts[2]),
                Number(dateParts[1]) - 1,
                Number(dateParts[0])
              );
              if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                return 0;
              }
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              }
              if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              }
            },
          });
      } else {
        //obj.filter = "agTextColumnFilter";
      }
      // obj.filter= "agTextColumnFilter"
    });
    this.articlesColDefs.columnDefs = colArr;
  },
  watch: {
    isStep2ForceRenderer(val) {
      this.forceRerender = !this.forceRerender;
    },
    flagDownload(val) {
      if (val) {
        document.body.removeChild(this.fileSelector);
        this.flagDownload = false;
        // this.fileSelector = null
      }
    },
    seasonStartFiscalWeek(val) {
      for (const date in this.fiscalWeeks) {
        if (val === date) {
          this.selectedFiscalWeek = this.fiscalWeeks[date];
          return;
        }
      }
      let a = this.selectedFiscalWeek.slice(0, 2);
      let b = this.selectedFiscalWeek.slice(2, 7);
      this.selectedFiscalWeek = a.concat(b);
      this.objsToUpdate.push({
        key: "seasonStartFiscalWeek",
        value: this.selectedFiscalWeek,
      });
    },
    "$store.state.CreateAllocation.jsonData": function (newVal, oldVal) {
      // this.setJsonData(this.getJsonData);
    },
    // inStoreDate(val) {
    //   let a = val.slice(0, 4);
    //   let b = val.slice(6);
    //   let c = a.concat(b);
    //   val = c;
    //   this.objsToUpdate.push({ key: "in_store_date", value: val });
    // },
    dropShipMassApplyCheckBox(val) {},
  },
  methods: {
    onHandleDropShip(e) {
      this.dropShipMassApplyCheckBox = e.target.checked;
      let dropShipVal;
      if (this.dropShipMassApplyCheckBox) {
        dropShipVal = 1;
      } else {
        dropShipVal = 0;
      }
      this.objsToUpdate.push({ key: "supplyingSource", value: dropShipVal });
      this.objsToUpdate.push({
        key: "is_dc09",
        value: this.dropShipMassApplyCheckBox,
      });
    },
    popoverShowCheck(){
      let data = this.selectedItems.filter((el) => {
        if(!el.data.is_dc01 && !el.data.is_dc04 && !el.data.is_dc06){
          return true
        } else {
          return false
        }
      })
      if(data && data.length){
        this.popoverShowWarning = true
      }else {
        this.popoverShow = true
      }
    },
    onHandleDcTwenty(e) {
      this.dcTwentyMassApplyCheckBox = e.target.checked;
      let trigger = this.dcTwentyMassApplyCheckBox ? 1 : 0;
      this.objsToUpdate.push({ key: "dcTwenty", value: trigger });
      this.objsToUpdate.push({
        key: "is_dc20",
        value: this.dcTwentyMassApplyCheckBox,
      });
    },
    onHandleDropShipOrDcTwenty(e, dc) {
      if (dc == "dc09") {
        this.dropShipMassApplyCheckBox = e.target.checked;
        this.$refs.dcTwentyMassApplyCheckBoxRef.checked = false;
        this.dcTwentyMassApplyCheckBox = false;
      }

      if (dc == "dc20") {
        this.dcTwentyMassApplyCheckBox = e.target.checked;
        this.$refs.dropShipMassApplyCheckBoxRef.checked = false;
        this.dropShipMassApplyCheckBox = false;
      }

      this.objsToUpdate.push({
        key: "supplyingSource",
        value: this.dropShipMassApplyCheckBox ? 1 : 0,
      });
      this.objsToUpdate.push({
        key: "is_dc09",
        value: this.dropShipMassApplyCheckBox,
      });

      this.objsToUpdate.push({
        key: "dcTwenty",
        value: this.dcTwentyMassApplyCheckBox ? 1 : 0,
      });
      this.objsToUpdate.push({
        key: "is_dc20",
        value: this.dcTwentyMassApplyCheckBox,
      });
    },
    onWeek(val) {
      if (this.week && this.year) {
        this.objsToUpdate.push({
          key: "seasonStartFiscalWeek",
          value: this.week.concat(this.year),
        });
      }
    },
    onYear(val) {
      if (this.week && this.year) {
        this.objsToUpdate.push({
          key: "seasonStartFiscalWeek",
          value: this.week.concat(this.year),
        });
      }
    },
    removedSuccessExclusion(val) {
      let disableFlag = isEqual(
        this.getTempJsonData.articles.exclusions,
        this.getJsonData.articles.exclusions
      );
      this.$root.$emit("step2Flag", disableFlag);
    },
    isColorValidation(rowObj) {
      let newValue = +rowObj.eventVal.value;
      let selColStr = rowObj.eventVal.colDef.field;
      let revampStr = selColStr.split("");
      revampStr.splice(6, 8);
      let extensionArr = ["a", "x"];
      let compareStr = revampStr.concat(extensionArr).join("");
      let comapreValue = null;
      if (rowObj.eventVal.colDef.field && rowObj.eventVal.data[compareStr]) {
        comapreValue = +rowObj.eventVal.data[compareStr];
        if (newValue > comapreValue) {
          this.isErrorInMinMax = true;
        }
      }
    },
    removedSuccess(val) {
      let disableFlag = isEqual(
        this.getTempJsonData.articles.selections,
        this.getJsonData.articles.selections
      );
      this.$root.$emit("step2Flag", disableFlag);
    },

    onClear(val) {
      for (let i = 0; i < this.selectedItems.length; i++) {
        this.selectedItems[i].data.allStoresMin = "";
        this.selectedItems[i].data.allStoresMax = "";
        this.selectedItems[i].data.AVL01Min = "";
        this.selectedItems[i].data.AVL01Max = "";
        this.selectedItems[i].data.AVL02Min = "";
        this.selectedItems[i].data.AVL02Max = "";
        this.selectedItems[i].data.AVL03Min = "";
        this.selectedItems[i].data.AVL03Max = "";
        this.selectedItems[i].data.AVL04Min = "";
        this.selectedItems[i].data.AVL04Max = "";
        this.selectedItems[i].data.AVL05Min = "";
        this.selectedItems[i].data.AVL05Max = "";
        this.selectedItems[i].data.AVL06Min = "";
        this.selectedItems[i].data.AVL06Max = "";
        this.selectedItems[i].data.AVL07Min = "";
        this.selectedItems[i].data.AVL07Max = "";
        this.selectedItems[i].data.AVL08Min = "";
        this.selectedItems[i].data.AVL08Max = "";
        this.selectedItems[i].data.AVL09Min = "";
        this.selectedItems[i].data.AVL09Max = "";
        this.selectedItems[i].data.supplyingSource = 0;
        this.selectedItems[i].data.is_dc09 = false;
        this.selectedItems[i].data.dcTwenty = 0;
        this.selectedItems[i].data.is_dc20 = false;
      }

       this.$gtag.event('Clicked_ArticleSelectionClearButton', {
        'event_category': 'Article Selection Screen',
        'event_label': 'Click on Clear Button',
      })
    },
    onMassApply(val) {
      this.selectedItems.forEach((element) => {
        this.triggerDc09andDc20({
          article: element.data.article,
          is_dc20: element.data.is_dc20,
          dcTwenty: element.data.dcTwenty,
          is_dc09: element.data.is_dc09,
          supplyingSource: element.data.supplyingSource ? 1 : 0,
        });

        this.getJsonData.allocationTemplateFileContents.forEach((obj) => {
          obj.seasonStartFiscalWeek = element.data.seasonStartFiscalWeek;
        });
      }),
        (val.gridOption.getRowStyle = function (params) {
          if (
            (params.data.AVL01Max &&
              Number(params.data.AVL01Min) > Number(params.data.AVL01Max)) ||
            (params.data.AVL02Max &&
              Number(params.data.AVL02Min) > Number(params.data.AVL02Max)) ||
            (params.data.AVL03Max &&
              Number(params.data.AVL03Min) > Number(params.data.AVL03Max)) ||
            (params.data.AVL04Max &&
              Number(params.data.AVL04Min) > Number(params.data.AVL04Max)) ||
            (params.data.AVL05Max &&
              Number(params.data.AVL05Min) > Number(params.data.AVL05Max)) ||
            (params.data.AVL06Max &&
              Number(params.data.AVL06Min) > Number(params.data.AVL06Max)) ||
            (params.data.AVL07Max &&
              Number(params.data.AVL07Min) > Number(params.data.AVL07Max)) ||
            (params.data.AVL08Max &&
              Number(params.data.AVL08Min) > Number(params.data.AVL08Max)) ||
            (params.data.AVL09Max &&
              Number(params.data.AVL09Min) > Number(params.data.AVL09Max)) ||
            (params.data.allStoresMax &&
              Number(params.data.allStoresMin) >
                Number(params.data.allStoresMax))
          ) {
            return { background: "#ecd1cc" };
          } else {
            return false;
          }
        });
      let disableFlag =
        isEqual(
          this.getTempJsonData.articles.exclusions,
          this.getJsonData.articles.exclusions
        ) &&
        isEqual(
          this.getTempJsonData.articles.selections,
          this.getJsonData.articles.selections
        );
      this.$root.$emit("step2Flag", disableFlag);
      val.gridApi.refreshCells();
    },
    ...mapMutations({
      setJsonData: "CreateAllocation/setJsonData",
      setLoader: "CreateAllocation/setLoader",
    }),
    ...mapActions({
      allocationByAllocationId: "CreateAllocation/allocationByAllocationId",
      downloadMinMaxTemplate: "CreateAllocation/downloadMinMaxTemplate",
      uploadMinMaxTemplate: "CreateAllocation/uploadMinMaxTemplate",
      saveAllocationDetails: "CreateAllocation/saveAllocationDetails",
      triggerDc09andDc20: "CreateAllocation/triggerDc09andDc20",
    }),
    close() {
      this.popoverShow = false;
    },
    cellValueChanged(val) {
      val.eventVal.value = +val.eventVal.value;
      val.eventVal.data[val.eventVal.colDef.field] = +val.eventVal.data[
        val.eventVal.colDef.field
      ];
      let ifValidateColor = false;
      this.getJsonData.allocationTemplateFileContents.forEach((obj) => {
        if (obj.articleID == val.eventVal.data.article) {
          obj.seasonStartFiscalWeek = val.eventVal.data.seasonStartFiscalWeek;
        }
      });
      this.isColorValidation(val);
      if (this.isErrorInMinMax) {
        val.gridOption.rowStyle = { background: "#ffeee2" };
        ifValidateColor = false;
      } else {
        val.gridOption.rowStyle = null;
      }

      let disableFlag =
        isEqual(
          this.getTempJsonData.articles.exclusions,
          this.getJsonData.articles.exclusions
        ) &&
        isEqual(
          this.getTempJsonData.articles.selections,
          this.getJsonData.articles.selections
        );
      this.$root.$emit("step2Flag", disableFlag);
    },
    uploadTemplate() {
      // let onChangeFlag = false
      if (this.fileSelector) {
        this.fileSelector.removeAttribute("type");
        this.fileSelector.removeAttribute("id");
      }
      this.fileSelector = document.createElement("input");
      this.fileSelector.setAttribute("type", "file");
      this.fileSelector.setAttribute("id", "uploadTemplate1");
      var selectDialogueLink = document.createElement("a");
      selectDialogueLink.setAttribute("href", "");
      selectDialogueLink.innerText = "Select File";
      document.body.appendChild(this.fileSelector);
      this.fileSelector.click();
      this.fileSelector.onchange = () => {
        // onChangeFlag = true
        this.selectedFile = document.getElementById("uploadTemplate1").files[0];
        this.uploadMinMaxTemplate({
          allocationID: this.getJsonData.allocationBasics.allocationID,
          file: this.selectedFile,
        })
          .then((res) => {
            // this.errorFlag = false;
            this.setJsonData(res.jsonData);
            this.setLoader(false);
            this.flagDownload = true;
          })
          .catch((err) => {
            this.flagDownload = true;

            console.error(err);
            console.dir(err);
            
            Swal.fire({
              title: "Error!",
              text: err.message,
              icon: "error",
              confirmButtonText: "Ok",
            });
            // this.errorFlag = true;
            // this.messageText = err.message;
            // this.setLoader(false)
          });
        this.selectedFile = null;
      };
      // if(!onChangeFlag) {
      //     this.flagDownload = true;
      //   }

      this.$gtag.event('Clicked_UploadLMTemplate', {
        'event_category': 'Article Selection Screen',
        'event_label': 'Click on Upload Template',
      })
    },
    downloadTemplate() {
      this.selectedItems.forEach((element) => {
        this.getJsonData.allocationTemplateFileContents.forEach((obj) => {
          if (obj.articleID === element.data.article) {
            obj.seasonStartFiscalWeek = element.data.seasonStartFiscalWeek;
          }
        });
      });
      if (!isEqual(this.getTempJsonData.articles, this.getJsonData.articles)) {
        this.$dialog
          .confirm(
            "Do you want to save changes and then download the template",
            this.dialogOptions
          )
          .then(() => {
            this.saveAllocationDetails({
              allocationID: this.getJsonData.allocationBasics.allocationID,
              jsonData: this.getJsonData,
            }).then(() => {
              this.downloadMinMaxTemplate({
                allocationID: this.getJsonData.allocationBasics.allocationID,
              }).then((data) => {
                let a1 = document.createElement("A");
                (a1.href = ""), (a1.href = data.url);
                a1.setAttribute("target", "_blank");
                document.body.appendChild(a1);
                a1.click();
                document.body.removeChild(a1);
              });
            });
          })
          .catch((e) => {
            this.downloadMinMaxTemplate({
              allocationID: this.getJsonData.allocationBasics.allocationID,
            }).then((data) => {
              let a1 = document.createElement("A");
              (a1.href = ""), (a1.href = data.url);
              a1.setAttribute("target", "_blank");
              document.body.appendChild(a1);
              a1.click();
              document.body.removeChild(a1);
            });
          });
      } else {
        this.downloadMinMaxTemplate({
          allocationID: this.getJsonData.allocationBasics.allocationID,
        }).then((data) => {
          let a1 = document.createElement("A");
          (a1.href = ""), (a1.href = data.url);
          a1.setAttribute("target", "_blank");
          document.body.appendChild(a1);
          a1.click();
          document.body.removeChild(a1);
        });
      }

       this.$gtag.event('Clicked_DownloadLMTemplate', {
        'event_category': 'Article Selection Screen',
        'event_label': 'Click on Download Template',
      })
    },
    onHandleMin(obj) {
      this.objsToUpdate.push({ key: obj.min.key, value: +obj.min.value });
      let val = +obj.min.value;
      if (isNaN(val) || val < 0) {
        obj.min.value = null;
      } else {
        obj.min.value = val;
        if (obj.min.value && obj.max.value && val > obj.max.value) {
          obj.min.value = null;
        }
      }
    },
    onHandleMax(obj) {
      this.objsToUpdate.push({ key: obj.max.key, value: +obj.max.value });
      let val = +obj.max.value;
      if (isNaN(val) || val <= 0) {
        obj.max.value = null;
      } else {
        obj.max.value = val;
        if (obj.max.value && obj.min.value && val < obj.min.value) {
          obj.max.value = null;
        }
      }
    },
    onShow() {
      this.objsToUpdate = [];
      // this.seasonStartFiscalWeek = "";
      this.inputList.forEach((obj) => {
        obj.min.value = null;
        obj.max.value = null;
      });
      this.dropShipMassApplyCheckBox = false;
      this.dcTwentyMassApplyCheckBox = false;
      // this.inStoreDate = ""
      this.week = "";
      this.year = "";

      this.$gtag.event('Clicked_ArticleSelectionPaintBrushApplyButton', {
        'event_category': 'Article Selection Screen',
        'event_label': 'Click on Article Selection screen paint Brush Apply button ',
      })
    },
    onClose() {
      this.popoverShow = false;
    },
    excludeByPastingArticles(val) {
      this.updatedDataOnRemove(val);

       this.$gtag.event('Exclude_By_search_article', {
        'event_category': 'Screen 2',
        'event_label': 'Click on Exclude By search article',
      })
    },
    updatedDataOnRemove(data) {
      this.getJsonData.articles.selections = data.existingData;
      this.getJsonData.articles.exclusions = concat(
        this.getJsonData.articles.exclusions,
        data.removedData
      );
      this.forceRerender = !this.forceRerender;
    },
    updatedExclusionTableDataOnRemove(data) {
      this.getJsonData.articles.exclusions = data.existingData;
      this.getJsonData.articles.selections = concat(
        this.getJsonData.articles.selections,
        data.removedData
      );
      this.setJsonData(this.getJsonData);
      this.forceRerender = !this.forceRerender;
    },
    onApply(onUpdateFunc) {
      this.popoverShow = false;
      onUpdateFunc(this.objsToUpdate);
      // this.refGridApi.api.refreshCells();
    },
  },
};
</script>

<style scoped>
input[type="week"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
.col-sm-4 {
  padding-right: 10px;
  padding-left: 5px;
}
</style>
<style scoped>
.fa,
.fas {
  font-weight: 900;
  color: royalblue;
  padding-left: 5px;
}
.vue-form-wizard .wizard-tab-content {
  padding-top: 30px important;
}
.vue-form-wizard .wizard-tab-content {
  min-height: 100px;
  padding: 30px 0px 0px !important;
}
.abc {
  background-color: #d8d8d8 !important;
}
</style>
