import {
  percentageFormatternew1,
  percentageFormatternew2,
  decimalFormatter,
  decimalFormatterSalesBasis
} from "@/helpers/formatters";

export default {
  animateRow: true,
  columnDefs: [
    {
      resizable: true,
      headerName: "ARTICLE ID",
      field: "productID",
      filter: "agTextColumnFilter",
      sortable: true,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      sortable: true,
      pinned: "left",
      width: 150,
      unSortIcon: true
    },
    {
      resizable: true,
      headerName: "DC",
      field: "supplyingDC",
      filter: "agTextColumnFilter",
      sortable: true,
      pinned: "left",
      width: 100,
      unSortIcon: true
    },
    {
      resizable: true,
      headerName: "UNRESTRICTED INVENTORY",
      field: "unrestrictedInventory",
      filter: "agTextColumnFilter",
      sortable: true,
      unSortIcon: true,
      width: 220,
      headerTooltip: "Unrestricted OH"
    },
    {
      resizable: true,
      headerName: "ORDERED QUANTITY",
      field: "orderedQuantity",
      filter: "agTextColumnFilter",
      sortable: true,
      unSortIcon: true,
      headerTooltip: "DC On Order"
    },
    {
      resizable: true,
      headerName: "IN-TRANSIT QUANTITY",
      field: "inTransitQuantity",
      filter: "agTextColumnFilter",
      sortable: true,
      unSortIcon: true,
      headerTooltip: "DC In Transit"
    },
    {
      resizable: true,
      headerName: "DC DEMAND",
      field: "dcDemand",
      filter: "agTextColumnFilter",
      sortable: true,
      unSortIcon: true
    },
    {
      resizable: true,
      headerName: "AVAILABLE INVENTORY",
      field: "availableInventory",
      filter: "agTextColumnFilter",
      sortable: true,
      unSortIcon: true,
      valueFormatter: decimalFormatter,
      headerTooltip: "(Unrestricted Inventory - DC Demand + Ordered Quantity)"
    },
    {
      resizable: true,
      headerName: "TOTAL BUY",
      field: "totalBuy",
      filter: false,
      sortable: true,
      unSortIcon: true,
      valueFormatter: decimalFormatter
    },
    {
      resizable: true,
      headerName: "ALLOCATED TILL DATE",
      field: "allocatedTillDate",
      filter: false,
      sortable: true,
      valueFormatter: decimalFormatter,
      unSortIcon: true
    },
    {
      resizable: true,
      headerName: "% ALREADY ALLOCATED",
      field: "percentageAlreadyAllocated",
      valueFormatter: percentageFormatternew1,
      filter: false,
      sortable: true,
      unSortIcon: true
    },
    {
      resizable: true,
      headerName: "TARGET ALLOCATION UNIT",
      field: "targetAllocationUnits",
      valueFormatter: decimalFormatter,
      filter: false,
      sortable: true,
      width: 280,
      unSortIcon: true
    },
    {
      resizable: true,
      headerName: "TARGET ALLOCATION %",
      field: "targetAllocationPercentage",
      valueFormatter: percentageFormatternew2,
      filter: false,
      sortable: true,
      unSortIcon: true
    },
    {
      resizable: true,
      headerName: "ALLOCATION",
      field: "allocation",
      filter: false,
      sortable: true,
      unSortIcon: true,
      cellStyle: {
        "background-color": "#bbedc0"
      }
    },
    {
      resizable: true,
      headerName: "DC INVENTORY POST ALLOCATION",
      field: "dcInventoryPostAllocation",
      filter: false,
      width: 270,
      sortable: true,
      valueFormatter: decimalFormatter,
      unSortIcon: true,
      cellStyle: function(params) {
        if (params.value < 0) {
          return {
            "background-color": "#f77b65",
            "border-left": "1px solid grey"
          };
        }
      }
    },
    {
      resizable: true,
      headerName: "SALES HISTORY START DATE I",
      field: "salesHistoryStartDate1",
      filter: false,
      sortable: true,
      width: 250,
      unSortIcon: true
    },
    {
      resizable: true,
      headerName: "SALES HISTORY END DATE I",
      field: "salesHistoryEndDate1",
      filter: false,
      width: 250,
      sortable: true,
      unSortIcon: true
    },

    {
      resizable: true,
      headerName: "MERCH HIERARCHY (DEPTH) I",
      field: "merchHierarchy1",
      filter: false,
      width: 280,
      sortable: true,
      unSortIcon: true
    },
    {
      resizable: true,
      headerName: "SELECTION (DEPTH ELEMENT) I",
      field: "merchSelections1",
      filter: "agTextColumnFilter",
      sortable: true,
      width: 280,
      unSortIcon: true
    },
    {
      resizable: true,
      headerName: "WEIGHTAGE I",
      field: "weightBasis1",
      filter: false,
      sortable: true,
      valueFormatter: percentageFormatternew2,
      width: 150,
      unSortIcon: true
    },

    {
      resizable: true,
      headerName: "SALES HISTORY START DATE II",
      field: "salesHistoryStartDate2",
      filter: false,
      sortable: true,
      width: 240,
      unSortIcon: true
    },
    {
      resizable: true,
      headerName: "SALES HISTORY END DATE II",
      field: "salesHistoryEndDate2",
      filter: false,
      width: 240,
      sortable: true,
      unSortIcon: true
    },
    {
      resizable: true,
      headerName: "MERCH HIERARCHY (DEPTH) II",
      field: "merchHierarchy2",
      filter: false,
      width: 250,
      sortable: true,
      unSortIcon: true
    },
    {
      resizable: true,
      headerName: "SELECTION (DEPTH ELEMENT) II",
      field: "merchSelections2",
      filter: "agTextColumnFilter",
      sortable: true,
      width: 250,
      unSortIcon: true
    },
    {
      resizable: true,
      headerName: "WEIGHTAGE II",
      field: "weightBasis2",
      valueFormatter: percentageFormatternew2,
      filter: false,
      sortable: true,
      width: 150,
      unSortIcon: true
    },
    {
      resizable: true,
      headerName: "TARGET INDEX",
      field: "Target_index",
      filter: false,
      sortable: true,
      width: 150,
      unSortIcon: true,
      valueFormatter: decimalFormatterSalesBasis
    }
  ]
};
