<template>
  <div v-if="getJsonData">
    <b-card>
      <div slot="header">
        <strong>Allocation Strategy</strong>
      </div>
      <div>
        <data-table
          :data="this.getJsonData.strategy"
          :colDefs="StrategyColDef"
          idForMultiSelect="productID"
          :count="getJsonData.strategy.length"
          :ifmultiSelect="true"
          :key="forceRerender"
          :defaultErrorForSalesBasisOnZero="true"
          :calSum1="calSum1"
          :defaultErrorTALessThanPAA="true"
          :calSum2="calSum2 ? calSum2 : false"
          :defaultCalSum2="true"
          :defaultErrorForPAAAndTA="true"
          @onClear="onClear"
          @cellValueChanged="cellValueChanged"
          @selectedItems="v => selectedItems = v"
          @updatedSuccesfully="massUpdateTargetCells"
          @updatedTotalBuy="onTotalBuySumMassUpdate"
        >
          <template slot-scope="props">
            Actions:
            <span>
              <i
                v-if="!selectedItems.length"
                v-b-tooltip.hover
                id="step4PaintBrush"
                title="Please select an article to use this option"
                class="fas fa-paint-brush cursor-not-allowed"
                style="margin-right:1rem"
              ></i>
              <i
                v-else
                class="fas fa-paint-brush cursor-pointer"
                style="margin-right:1rem"
                id="step4PaintBrush"
                @click="showPaintBrush=true"
              ></i>
            </span>
            <span>
              <b-button
                v-if="!selectedItems.length"
                variant="link"
                style="cursor: not-allowed; border:none !important;margin-bottom: 7px;margin-left: -12px;"
              >Clear</b-button>

              <b-button
                v-else
                variant="link"
                @click="props.onClear"
                style="border:none !important;margin-bottom: 7px;margin-left: -12px;"
              >Clear</b-button>
            </span>
            <span>
              <b-button
                v-if="!selectedItems.length"
                class="btn-download cursor-not-allowed"
                style="cursor: not-allowed"
              >
                Update Buy
                <i
                  class="fa fa-pencil-square-o cursor-not-allowed"
                  style="padding-left:0.25rem; color:#73bd00!important"
                ></i>
              </b-button>

              <b-button
                v-else
                class="btn-download"
                @click="onApplyTotalBuy(props.onUpdateBuy)"
                download
              >
                Update Buy
                <i
                  class="fa fa-pencil-square-o cursor-not-allowed"
                  style="padding-left:0.25rem; color:#73bd00!important"
                ></i>
              </b-button>
            </span>
            <span>
              <b-button class="btn-download" style="margin-left: 5px;" @click="showInfoModal=true">
                JAM error codes
                <i
                  v-b-tooltip.hover
                  class="fas fa-info-circle"
                  style="padding-left:0.25rem; color:#73bd00!important"
                ></i>
              </b-button>
            </span>

            <span>
              <b-button
                class="btn-download"
                style="margin-left: 5px;"
                @click="onAdminClick()"
                download
              >
                Index and Ref store
                <i
                  class="fa fa-cogs cursor-not-allowed"
                  style="padding-left:0.25rem; color:#73bd00!important"
                ></i>
              </b-button>
            </span>
            <span>
              <b-button
                class="btn-download"
                style="margin-left: 5px;"
                @click="props.downloadTable('Allocation_Strategy')"
                download
              >
                Download Table
                <i
                  class="fa fa-download cursor-not-allowed"
                  style="padding-left:0.25rem; color:#73bd00!important"
                ></i>
              </b-button>
            </span>
            <!-- <b-popover
              v-if="selectedItems.length"
             
              target="massUpdatePopOverstep4"
              triggers="click"
              placement="auto"
              :show.sync="popoverShow"
              container="myContainer"
              ref="popover"
              @show="onShow"
            >-->
            <b-modal
              id="adminSettings"
              v-model="showPaintBrush"
              size="xl"
              centered
              title="Apply Values on Multiple Select"
              hide-footer
              @show="onShow"
              @hidden="onModelClose"
            >
              <vue-element-loading :active="loaderState" spinner="bar-fade-scale" />
              <template slot="title">
                <b-button
                  class="close"
                  aria-label="Close"
                  style="color: #FFFFFF;"
                  @click="onClosePopOver"
                >
                  <span class="d-inline-block" aria-hidden="true">&times;</span>
                </b-button>
              </template>
              <!-- <div v-on-click-outside="close"> -->
              <div>
                <strong>
                  <label style="text-decoration: underline;">Sales History Settings</label>
                </strong>
                <b-row>
                  <b-col sm="3">
                    <label>Date Range:</label>
                  </b-col>
                  <b-col sm="3">
                    <div class="datepicker-trigger">
                      <b-form-input
                        type="text"
                        size="sm"
                        autocomplete="off"
                        id="datepicker-trigger-massSelect1"
                        placeholder="MM/DD/YYYY"
                        :value="formatDates(salesHistoryForm.saleHistoryStartDate1,salesHistoryForm.saleHistoryEndDate1)"
                      ></b-form-input>

                      <AirbnbStyleDatepicker
                        :trigger-element-id="'datepicker-trigger-massSelect1'"
                        :mode="'range'"
                        :close-after-select="true"
                        :show-action-buttons="false"
                        :fullscreen-mobile="true"
                        :show-month-year-select="true"
                        :show-shortcuts-menu-trigger="false"
                        :showShortcutsMenuTrigger="false"
                        :date-one="salesHistoryForm.saleHistoryStartDate1"
                        :date-two="salesHistoryForm.saleHistoryEndDate1"
                        :endDate="todayDate"
                        :minDate="pastDate"
                        @date-one-selected="onSaleHistoryStartDate1"
                        @date-two-selected="onSaleHistoryEndDate1"
                      />
                      <!-- {{salesHistoryForm.saleHistoryStartDate1}} - {{salesHistoryForm.saleHistoryEndDate1}} -->
                    </div>
                  </b-col>
                  <b-col sm="3">
                    <label>Merch Hierarchy Selection:</label>
                  </b-col>
                  <b-col sm="3" class="mt-1">
                    <b-form-select
                      v-model="selectedHierarchy1"
                      :options="options1"
                      size="sm"
                      @change="onSelectedMerchHierarchy"
                      :close-on-select="true"
                      :clear-on-select="false"
                      :preserve-search="true"
                      placeholder="Merch Hierarchy"
                    >
                      <template slot="selection" slot-scope="{ values, search, isOpen }">
                        <span
                          v-if="values.length &amp;&amp; !isOpen"
                        >{{ values.length }} options selected</span>
                      </template>
                    </b-form-select>
                  </b-col>
                </b-row>
                <b-row>
                  <!-- <b-col sm="4">
                    <label>Merch Hierarchy Selection:</label>
                  </b-col>
                  <b-col sm="8" class="mt-1">
                    <b-form-select
                      v-model="selectedHierarchy1"
                      :options="options1"
                      size="sm"
                      @change="onSelectedMerchHierarchy"
                      :close-on-select="true"
                      :clear-on-select="false"
                      :preserve-search="true"
                      placeholder="Merch Hierarchy"
                    >
                      <template slot="selection" slot-scope="{ values, search, isOpen }">
                        <span
                          v-if="values.length &amp;&amp; !isOpen"
                        >{{ values.length }} options selected</span>
                      </template>
                    </b-form-select>
                  </b-col>-->
                </b-row>
                <b-row class="mt-3">
                  <b-col sm="3">
                    <label>Merch Hierarchy Mapping:
                    </label>
                  </b-col>
                  <b-col sm="3" v-if="selectedHierarchy1 === 'Article'">
                    <b-form-input
                      size="sm"
                      v-model="selectedArticle1"
                      type="text"
                      placeholder="article"
                      width="60%"
                    />
                  </b-col>
                  <b-col sm="3" v-show="selectedHierarchy1 === 'MerchCat'">
                    <model-select
                      :options="optionMerchCats"
                      v-model="valueMerchSelection1"
                      placeholder="Select Merch Cat"
                    ></model-select>
                  </b-col>
                  <!-- <b-col sm="8" v-else>

                  </b-col>-->
                  <b-col sm="3" v-show="selectedHierarchy1 === 'Cat'">
                    <model-select
                      :options="optionCats"
                      v-model="valueMerchSelection1"
                      placeholder="Select Cat"
                    ></model-select>
                  </b-col>

                  <b-col sm="3" v-show="selectedHierarchy1 === 'Char Collection'">
                    <model-select
                      :options="collection"
                      v-model="valueMerchSelection1"
                      placeholder="Select Char Collection"
                    ></model-select>
                  </b-col>
                  <b-col sm="3" v-if="!selectedHierarchy1"></b-col>
                  <b-col sm="3">
                    <label style="margin-top: 0.5rem;">Weight 1 %</label>
                  </b-col>
                  <b-col sm="3">
                    <b-form-input v-model="weight1" type="text" placeholder="weight 1" />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12">
                    <b-form-text>If this field is left blank, the algorithm will default to the article/ merch cat/ category own sales history depending on your selection above</b-form-text>
                  </b-col>
                </b-row>
                <b-form-group>
                  <!-- <b-row class="my-1">
                    <b-col sm="3">
                      <label style="margin-top: 0.5rem;">Weight 1 %</label>
                    </b-col>
                    <b-col sm="3">
                      <b-form-input v-model="weight1" type="text" placeholder="weight 1" />
                    </b-col>
                  </b-row>-->
                  <b-row class="my-1">
                    <b-col sm="3">
                      <label style="margin-top: 0.5rem;">Use Reference Store</label>
                    </b-col>
                    <b-col sm="3" id="rates">
                      <b-form-checkbox
                        v-model="useReferenceStore1"
                        :indeterminate.sync="useReferenceStore1IntermediateStatus"
                      >
                        <span
                          v-if="useReferenceStore1IntermediateStatus && !useReferenceStore1"
                        >No Change</span>
                        <span v-if="!useReferenceStore1IntermediateStatus && useReferenceStore1">Yes</span>
                        <span v-if="!useReferenceStore1IntermediateStatus && !useReferenceStore1">No</span>
                      </b-form-checkbox>
                      <!-- <b-form-group>
                        <b-form-radio-group
                          id="referenceStore1"
                          v-model="useReferenceStore1"
                          name="radioSubComponent"
                        >
                          <b-form-radio value="Y" name="some-radios">Yes</b-form-radio>
                          <b-form-radio value="N" name="some-radios">No</b-form-radio>
                        </b-form-radio-group>
                      </b-form-group>-->
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12">
                      <b-form-text>If 'Yes' is selected, the Sales History retrieved will consider the reference stores. If 'No' is selected, the Sales History retrieved will consider those of the own store IDs.</b-form-text>
                    </b-col>
                  </b-row>
                  <hr style="margin-top:30px;margin-bottom:30px" />
                  <b-row class="mt-3">
                    <b-col sm="3">
                      <label>Date Range2:</label>
                    </b-col>
                    <b-col sm="3">
                      <div class="datepicker-trigger">
                        <b-form-input
                          type="text"
                          size="sm"
                          autocomplete="off"
                          id="datepicker-trigger-massSelect2"
                          placeholder="MM/DD/YYYY"
                          :value="formatDates(salesHistoryForm.saleHistoryStartDate2,salesHistoryForm.saleHistoryEndDate2)"
                        ></b-form-input>

                        <AirbnbStyleDatepicker
                          :trigger-element-id="'datepicker-trigger-massSelect2'"
                          :mode="'range'"
                          :close-after-select="true"
                          :show-action-buttons="false"
                          :fullscreen-mobile="true"
                          :show-month-year-select="true"
                          :show-shortcuts-menu-trigger="false"
                          :showShortcutsMenuTrigger="false"
                          :date-one="salesHistoryForm.saleHistoryStartDate2"
                          :date-two="salesHistoryForm.saleHistoryEndDate2"
                          :endDate="todayDate"
                          :minDate="pastDate"
                          @date-one-selected="onSaleHistoryStartDate2"
                          @date-two-selected="onSaleHistoryEndDate2"
                        />
                      </div>
                    </b-col>
                    <b-col sm="3">
                      <label>Merch Hierarchy Selection:</label>
                    </b-col>
                    <b-col sm="3" class="mt-1">
                      <b-form-select
                        v-model="selectedHierarchy2"
                        :options="options1"
                        size="sm"
                        @change="onSelectedMerchHierarchy"
                        :close-on-select="true"
                        :clear-on-select="false"
                        :preserve-search="true"
                        placeholder="Merch Hierarchy"
                      >
                        <template slot="selection" slot-scope="{ values, search, isOpen }">
                          <span
                            v-if="values.length &amp;&amp; !isOpen"
                          >{{ values.length }} options selected</span>
                        </template>
                      </b-form-select>
                    </b-col>
                  </b-row>

                  <b-row>
                    <!-- <b-col sm="4">
                      <label>Merch Hierarchy Selection:</label>
                    </b-col>
                    <b-col sm="8" class="mt-1">
                      <b-form-select
                        v-model="selectedHierarchy2"
                        :options="options1"
                        size="sm"
                        @change="onSelectedMerchHierarchy"
                        :close-on-select="true"
                        :clear-on-select="false"
                        :preserve-search="true"
                        placeholder="Merch Hierarchy"
                      >
                        <template slot="selection" slot-scope="{ values, search, isOpen }">
                          <span
                            v-if="values.length &amp;&amp; !isOpen"
                          >{{ values.length }} options selected</span>
                        </template>
                      </b-form-select>
                    </b-col>-->
                  </b-row>
                  <b-row class="mt-3">
                    <!-- <input
                  type="checkbox"
                  id="articleOwnSalesHistory"
                  v-model="salesHistoryForm.articleOwnSalesHistorys"
                  @change="invokeParentMethod($event)"
                    >-->
                    <b-col sm="3">
                      <label>Merch Hierarchy Mapping:</label>
                    </b-col>
                    <b-col sm="3" v-if="selectedHierarchy2 === 'Article'">
                      <b-form-input
                        size="sm"
                        v-model="selectedArticle2"
                        type="text"
                        placeholder="article"
                        width="60%"
                      />
                    </b-col>
                    <b-col sm="3" v-else-if="selectedHierarchy2 === 'MerchCat'">
                      <model-select
                        :options="optionMerchCats"
                        v-model="valueMerchSelection2"
                        placeholder="Select Merch Cat"
                      ></model-select>
                    </b-col>
                    <b-col sm="3" v-else-if="selectedHierarchy2 === 'Cat'">
                      <model-select
                        :options="optionCats"
                        v-model="valueMerchSelection2"
                        placeholder="Select Cat"
                      ></model-select>
                    </b-col>
                    <b-col sm="3" v-else-if="selectedHierarchy2 === 'Char Collection'">
                      <model-select
                        :options="collection"
                        v-model="valueMerchSelection2"
                        placeholder="Select Char Collection"
                      ></model-select>
                    </b-col>
                    <b-col sm="3" v-else-if="!selectedHierarchy2"></b-col>
                    <b-col sm="3">
                      <label style="margin-top: 0.5rem;">Weight 2 %</label>
                    </b-col>
                    <b-col sm="3">
                      <b-form-input v-model="weight2" type="text" placeholder="weight 2" />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12">
                      <b-form-text>If this field is left blank, the algorithm will default to the article/ merch cat/ category own sales history depending on your selection above</b-form-text>
                    </b-col>
                  </b-row>
                  <b-row class="my-1">
                    <!-- <b-col sm="4">
                      <label style="margin-top: 0.5rem;">Weight 2 %</label>
                    </b-col>
                    <b-col sm="8">
                      <b-form-input v-model="weight2" type="text" placeholder="weight 2" />
                    </b-col>-->
                  </b-row>
                  <b-row class="my-1">
                    <b-col sm="3">
                      <label style="margin-top: 0.5rem;">Use Reference Store</label>
                    </b-col>
                    <b-col sm="3">
                      <!-- <b-form-group>
                        <b-form-radio-group
                          id="referenceStore2"
                          v-model="useReferenceStore2"
                          name="radioSubComponent2"
                        >
                          <b-form-radio value="Y" name="some-radios2">Yes</b-form-radio>
                          <b-form-radio value="N" name="some-radios2">No</b-form-radio>
                        </b-form-radio-group>
                      </b-form-group>-->
                      <b-form-checkbox
                        v-model="useReferenceStore2"
                        :indeterminate.sync="useReferenceStore2IntermediateStatus"
                      >
                        <span
                          v-if="useReferenceStore2IntermediateStatus && !useReferenceStore2"
                        >No Change</span>
                        <span v-if="!useReferenceStore2IntermediateStatus && useReferenceStore2">Yes</span>
                        <span v-if="!useReferenceStore2IntermediateStatus && !useReferenceStore2">No</span>
                      </b-form-checkbox>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12">
                      <b-form-text>If 'Yes' is selected, the Sales History retrieved will consider the reference stores. If 'No' is selected, the Sales History retrieved will consider those of the own store IDs.</b-form-text>
                    </b-col>
                  </b-row>
                  <hr />
                  <strong>
                    <label style="text-decoration: underline;">Target Allocation</label>
                  </strong>
                  <b-row class="my-1">
                    <b-col sm="3">
                      <b-form-radio
                        value="A"
                        v-model="selected"
                        name="some-radios"
                        style="margin-top: 0.5rem;"
                      >Target Allocation Units</b-form-radio>
                    </b-col>
                    <b-col sm="3">
                      <b-form-input
                        size="sm"
                        :disabled="disableA"
                        v-model="salesHistoryForm.targrtAllocationUnit"
                        type="number"
                        placeholder="Units"
                        width="50%"
                      />
                    </b-col>
                    <b-col sm="3">
                      <b-form-radio
                        value="B"
                        v-model="selected"
                        name="some-radios"
                        style="margin-top: 0.5rem;"
                      >Target Allocation %</b-form-radio>
                    </b-col>
                    <b-col sm="3">
                      <b-form-input
                        size="sm"
                        :disabled="disableB"
                        v-model="salesHistoryForm.targrtAllocationPer"
                        type="number"
                        placeholder="%"
                        width="60%"
                      />
                    </b-col>
                  </b-row>

                  <b-row class="my-1">
                    <!-- <b-col sm="8">
                      <b-form-radio
                        value="B"
                        v-model="selected"
                        name="some-radios"
                        style="margin-top: 0.5rem;"
                      >Target Allocation %</b-form-radio>
                    </b-col>
                    <b-col sm="4">
                      <b-form-input
                        size="sm"
                        :disabled="disableB"
                        v-model="salesHistoryForm.targrtAllocationPer"
                        type="number"
                        placeholder="%"
                        width="60%"
                      />
                    </b-col>-->
                  </b-row>
                  <hr />
                  <b-row class="my-1">
                    <b-col sm="3" v-if="excludeOOIntermediateStatus || !excludeOOStatus">
                      <strong>Please select to exclude OO</strong>
                    </b-col>
                    <b-col sm="3" v-else-if="!excludeOOIntermediateStatus && excludeOOStatus">
                      <strong>Please deselect to include OO</strong>
                    </b-col>
                    <b-col sm="2">
                      <b-form-checkbox
                        v-model="excludeOOStatus"
                        :indeterminate.sync="excludeOOIntermediateStatus"
                      ></b-form-checkbox>
                    </b-col>
                  </b-row>
                  <b-row class="my-1 mt-3">
                    <b-col sm="3">
                      <label style="margin-top: 0.5rem;">Use All Index Values</label>
                    </b-col>
                    <b-col sm="3">
                      <b-form-checkbox
                        v-model="useAllIndexValues"
                        :indeterminate.sync="useAllIndexValuesIntermediateStatus"
                      >
                        <span
                          v-if="useAllIndexValuesIntermediateStatus && !useAllIndexValues"
                        >No Change</span>
                        <span v-if="!useAllIndexValuesIntermediateStatus && useAllIndexValues">Yes</span>
                        <span v-if="!useAllIndexValuesIntermediateStatus && !useAllIndexValues">No</span>
                      </b-form-checkbox>

                      <!-- <b-form-group>
                        <b-form-radio-group
                          id="allIndexValues"
                          v-model="useAllIndexValues"
                          name="radioSubComponent3"
                        >
                          <b-form-radio value="Y" name="some-radios3">Yes</b-form-radio>
                          <b-form-radio value="N" name="some-radios3">No</b-form-radio>
                        </b-form-radio-group>
                      </b-form-group>-->
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12">
                      <b-form-text>If 'Yes' is selected, the indices used will be from the 'Index & Ref Stores' page. If 'No' is selected, the Index used will be 1.</b-form-text>
                    </b-col>
                  </b-row>
                  <strong>
                    <label
                      class="mt-2"
                      style="text-decoration: underline;"
                    >Poisson Distribution Input</label>
                  </strong>
                  <b-row class="my-1">
                    <b-col sm="3">Enter Value between 0-100%:</b-col>
                    <b-col sm="3">
                      <b-form-input
                        size="sm"
                        v-model="poissonInput"
                        type="text"
                        @change="onPoissonInput"
                        placeholder="%"
                        width="60%"
                      />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12">
                      <span style="color:red">{{message}}</span>
                    </b-col>
                  </b-row>
                  <br />
                </b-form-group>

                <b-row>
                  <b-col sm="1">
                    <b-button @click="onApplyClicked(props.onUpdate)" size="sm" variant="primary">
                      Apply
                    </b-button>
                  </b-col>
                  <!-- <b-col sm="1">
                    <b-button @click="onClearClicked(props.onUpdate)" size="sm" variant="outline-primary">
                      Reset
                    </b-button>
                  </b-col> -->
                </b-row>

              </div>
            </b-modal>
            <b-modal
              id="adminSettings"
              v-model="showInfoModal"
              size="xl"
              centered
              title="JAM Error Codes"
              hide-footer
            >
              <vue-element-loading :active="loaderState" spinner="bar-fade-scale" />
              <template slot="title">
                <b-button
                  class="close"
                  aria-label="Close"
                  style="color: #FFFFFF;"
                  @click="onClosePopOver"
                >
                  <span class="d-inline-block" aria-hidden="true">&times;</span>
                </b-button>
              </template>
              <!-- <div v-on-click-outside="close"> -->
              <div>
                <table class="table-info">
                  <tr>
                    <th>COLOR</th>
                    <th>COLUMN</th>
                    <th>DESCRIPTION</th>
                  </tr>
                  <tr>
                    <td>
                      <div class="info-box-red"></div>
                    </td>
                    <td>% ALREADY ALLOC</td>
                    <td>Cases where % Already Allocated is greater than 100% due to which JAM will not be doing any allocation</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="info-box-red"></div>
                    </td>
                    <td>TARGET ALLOC %</td>
                    <td>Cases where there is Insufficient Target Allocation (Target allocation % is less than % Already Allocated)</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="info-box-red"></div>
                    </td>
                    <td>TARGET ALLOC</td>
                    <td>Cases where there is Insufficient Target Allocation (Target allocation units are less than those calculated based on % Already Allocated)</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="info-box-red"></div>
                    </td>
                    <td>SALES BASIS 1/SALES BASIS 2</td>
                    <td>Cases where there Sales basis 1 and Sales basis 2 becomes 0</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="info-box-purple"></div>
                    </td>
                    <td>Sales History Settings (Date range, Merch Hierarchy Selection/Mapping, Weights)</td>
                    <td>If any inputs are missing for both the sales history ranges as the inputs are considered invalid (This check happens after the algorithm is run))</td>
                  </tr>
                </table>
              </div>
            </b-modal>
          </template>
        </data-table>
      </div>
    </b-card>
    <!-- <b-alert v-model="totalBuyFlag" variant="danger" dismissible>!! {{totalBuyErrorMesg}}</b-alert> -->
    <!-- <b-alert v-model="targetAllocationUnitFlag" variant="danger" dismissible>!! {{targetAllocationErrorMesg}}</b-alert> -->
    <!-- <div
      style="color: red;"
      align="center"
      v-if="totalBuyFlag"
    ></div>-->
    <!-- <div
      style="color: red;"
      align="center"
      v-if="targetAllocationUnitFlag"
    >In the highlighted rows, some values are incorrect</div>-->
    <b-modal v-model="onSuccess" centered title="BootstrapVue" ok-only hide-header>
      <div align="middle">
        <h5 class="my-4">This information was saved successfully.</h5>
      </div>
    </b-modal>
    <div style="width:100%">
      <b-modal
        id="adminSettings"
        v-model="adminModal"
        size="xl"
        centered
        title="Index & Reference Stores"
        hide-footer
      >
        <div>
          <settings />
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { isEqual } from "lodash";
import { ModelSelect } from "vue-search-select";
import format from "date-fns/format";
import DataTable from "@/components/DataTable";
import StrategyColDef from "@/constants/tableDefinitions/StrategyColDef";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { arch } from "os";
import { debug } from "util";
import { constants } from "zlib";
import Settings from "../Settings";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
var handler = function(e) {
  var confirmationMessage = "Your unsave data will lost.";
  let tab = e || window.event;
  tab.returnValue = confirmationMessage;
  return confirmationMessage;
};
export default {
  data() {
    return {
      totalBuyErrorMesg:
        "Please re-enter the Target Allocation % / Target Allocation units for the cases where Total buy is updated.",
      targetAllocationErrorMesg:
        "In the highlighted rows, some values are incorrect",
      showInfoModal: false,
      showPaintBrush: false,
      useReferenceStore1: null,
      useReferenceStore1IntermediateStatus: true,
      useReferenceStore2: null,
      useReferenceStore2IntermediateStatus: true,
      useAllIndexValues: null,
      adminModal: false,
      excludeOOStatus: null,
      excludeOOIntermediateStatus: true,
      useAllIndexValuesIntermediateStatus: true,
      totalBuyFlag: false,
      poissonInput: this.getPoissonDistribution,
      weight1: null,
      weight2: null,
      tempselectOption: "",
      targetAllocationUnitFlag: false,
      tempOption: [{ value: "", text: "" }],
      tempSelectOption: null,
      optionswww: [
        { value: "1", text: "aa" + " - " + "1", ddd: "443" },
        { value: "2", text: "ab" + " - " + "2", ddd: "443" },
        { value: "3", text: "bc" + " - " + "3", ddd: "443" },
        { value: "4", text: "cd" + " - " + "4", ddd: "443" },
        { value: "5", text: "de" + " - " + "5", ddd: "443" }
      ],
      searchText: "",
      forceRerender: false,
      loaderState: false,
      onDialogOpen: false,
      valueMerchSelection1: {},
      valueMerchSelection2: {},
      optionCats: [],
      optionMerchCats: [],
      selectedArticle1: "",
      selectedArticle2: "",
      selectedHierarchy1: "",
      selectedHierarchy2: "",
      collection: [],
      dcOnHand: false,
      dcOnOrder: false,
      isSavedValueIsFillingInPopup: false,
      options1: ["Article", "Char Collection", "MerchCat", "Cat"],
      messageLoader:
        "This step may take some time. Thank you for your patience.",
      message: "",
      onSuccess: false,
      onError: false,
      todayDate: new Date(),
      dateFormat: "MM/DD/YYYY",
      popoverShow: false,
      columToUpdae: "",
      valueToUpdate: "",
      disableA: false,
      disableB: true,
      isADisabled: false,
      isBDisabled: false,
      selected: "A",
      selectedArticlesPerPage: 50,
      options: [
        { value: 50, text: 50 },
        { value: 100, text: 100 },
        { value: 200, text: 200 },
        { value: 500, text: 500 }
      ],
      StrategyColDef,
      objsToUpdate: [],
      selectedItems: [],
      payloadSharedWorker: false,
      payloadGenerated: false,
      jsonData: {},
      mapRealVaribleToObjToUpdate: {
        weight1: "weightBasis1",
        weight2: "weightBasis2",
        selectedArticle1: "merchSelections1",
        selectedArticle2: "merchSelections2",
        valueMerchSelection1: "merchSelections1",
        valueMerchSelection2: "merchSelections2",
        excludeOOStatus: "includeDCOO",
        selectedHierarchy1: "merchHierarchy1",
        selectedHierarchy2: "merchHierarchy2",
        poissonInput: "Target_index",
        salesHistoryForm: {
          targrtAllocationUnit: "targetAllocationUnits",
          targrtAllocationPer: "targetAllocationPercentage",
          articleOwnSalesHistorys: "articleOwnSalesHistory",
          saleHistoryEndDate1: "salesHistoryEndDate1",
          saleHistoryStartDate1: "salesHistoryStartDate1",
          saleHistoryEndDate2: "salesHistoryEndDate2",
          saleHistoryStartDate2: "salesHistoryStartDate2",
        }
      }
    };
  },
  components: {
    DataTable,
    ModelSelect,
    Settings
  },
  props: [
    "isStep4ForceRenderer",
    "salesHistoryForm",
    "isStep4Success",
    "calSum1",
    "calSum2"
  ],
  computed: {
    pastDate() {
      var date = "2017-12-31";
      return date;
    },
    ...mapGetters({
      getMerchCatAndCat: "CreateAllocation/getMerchCatAndCat",
      getJsonData: "CreateAllocation/getJsonData",
      getTempJsonData: "CreateAllocation/getTempJsonData",
      getLoader: "CreateAllocation/getLoader",
      getLoaderForHierarchy: "CreateAllocation/getLoaderForHierarchy",
      getPoissonDistribution: "CreateAllocation/getPoissonDistribution"
    })
  },
  destroyed() {
    this.calSum2 = false;
    this.forceRenderCalSumTable = false;
    // this.setJsonData(null)
  },
  methods: {
    onSaleHistoryEndDate1(val) {
      if (val) {
        this.salesHistoryForm.saleHistoryEndDate1 = val;
        this.objsToUpdate.push({ key: "salesHistoryEndDate1", value: val });
      }
    },
    onSaleHistoryStartDate1(val) {

      if (val) {
        this.salesHistoryForm.saleHistoryStartDate1 = val;
        this.objsToUpdate.push({ key: "salesHistoryStartDate1", value: val });
      }
    },
    onSaleHistoryEndDate2(val) {
      if (val) {
        this.salesHistoryForm.saleHistoryEndDate2 = val;
        this.objsToUpdate.push({ key: "salesHistoryEndDate2", value: val });
      }
    },
    onSaleHistoryStartDate2(val) {
      if (val) {
        this.salesHistoryForm.saleHistoryStartDate2 = val;
        this.objsToUpdate.push({ key: "salesHistoryStartDate2", value: val });
      }
    },
    getPainBrush() {
      this.showPaintBrush = true;
    },
    // onWeight2(val) {
    //   let vInt = parseFloat(val).toFixed(2);
    //   if(vInt>=0 && vInt <= 100) {
    //     this.weight2 = vInt.toFixed(2);
    //     this.message = "";
    //     this.weight1 = (100 - this.weight2).toFixed(2);
    //     this.objsToUpdate.push({ key: "weightBasis2", value:  this.weight2});
    //     this.objsToUpdate.push({ key: "weightBasis1", value: this.weight1 });
    //   } else {
    //     this.weight2 = null;
    //     this.weight1 = null;
    //     this.message = "Percentage should not be more than 100";
    //   }
    // },
    // onWeight1(val) {
    //   let vInt = parseFloat(val).toFixed(2);
    //   if(vInt>=0 && vInt <= 100) {
    //     this.weight1 = vInt.toFixed(2);
    //     this.message = '';
    //     this.weight2 = (100 - this.weight1).toFixed(2);
    //     this.objsToUpdate.push({ key: "weightBasis2", value:  this.weight2});
    //     this.objsToUpdate.push({ key: "weightBasis1", value: this.weight1 });
    //   } else {
    //     this.weight2 = '';
    //     this.weight1 = '';
    //     this.message = "Percentage should not be more than 100";
    //   }
    // },
    ...mapActions({
      actionGetMerchCatAndCat: "CreateAllocation/actionGetMerchCatAndCat",
      fetchPoissonDistribution: "CreateAllocation/fetchPoissonDistribution"
    }),
    ...mapMutations({
      allocationByAllocationId: "CreateAllocation/allocationByAllocationId",
      setJsonData: "CreateAllocation/setJsonData",
      setLoader: "CreateAllocation/setLoader"
    }),
    onAdminClick() {
      this.adminModal = true;

      this.$gtag.event('Clicked_IndexAndRefrenceStoreButton', {
        'event_category': 'Strategy Screen',
        'event_label': 'Click on Index and Refrence Store Button',
      })
    },
    close() {
      this.popoverShow = false;
    },
    reset() {
      this.valueMerchSelection = null;
    },
    onClear(val) {
      for (let i = 0; i < this.selectedItems.length; i++) {
        this.selectedItems[i].data.targetAllocationUnits = "";
        this.selectedItems[i].data.targetAllocationPercentage = "";
        this.selectedItems[i].data.salesHistoryStartDate1 = "";
        this.selectedItems[i].data.salesHistoryEndDate1 = "";
        this.selectedItems[i].data.merchHierarchy1 = "";
        this.selectedItems[i].data.merchSelections1 = "";
        this.selectedItems[i].data.weightBasis1 = "";
        this.selectedItems[i].data.salesHistoryStartDate2 = "";
        this.selectedItems[i].data.salesHistoryEndDate2 = "";
        this.selectedItems[i].data.merchHierarchy2 = "";
        this.selectedItems[i].data.merchSelections2 = "";
        this.selectedItems[i].data.weightBasis2 = "";
        this.selectedItems[i].data.Target_index = this.getPoissonDistribution;
        this.selectedItems[i].data.useAllIndexValues = "Y";
        this.selectedItems[i].data.useReferenceStore1 = "Y";
        this.selectedItems[i].data.useReferenceStore2 = "Y";
        // if (this.selectedItems[i].data.includeDCOO) {
        //   this.selectedItems[i].data.includeDCOO = null
        // }
        let onOrder = Number(this.selectedItems[i].data.orderedQuantity);
        let unRestrictedInventory = Number(
          this.selectedItems[i].data.unrestrictedInventory
        );
        let dcDemand = Number(this.selectedItems[i].data.dcDemand);
        if (this.selectedItems[i].data.includeDCOO) {
          // let availableInventory = element.data.availableInventory
          //element.data.availableInventory = availableInventory - onOrder
          this.selectedItems[i].data.includeDCOO = null;
          this.selectedItems[i].data.availableInventory = 0;
          this.selectedItems[i].data.availableInventory =
            unRestrictedInventory + onOrder - dcDemand;
          let totalBuy =
            this.selectedItems[i].data.storeTotalOH +
            this.selectedItems[i].data.storeTotalOO +
            this.selectedItems[i].data.inSeasonSales +
            this.selectedItems[i].data.availableInventory;
          this.selectedItems[i].data.totalBuy = totalBuy;
          if (
            this.selectedItems[i].data.targetAllocationUnits &&
            this.selectedItems[i].data.targetAllocationPercentage
          ) {
            this.selectedItems[i].data.targetAllocationPercentage = "";
            this.selectedItems[i].data.targetAllocationUnits = "";
          }
          this.selectedItems[i].data.percentageAlreadyAllocated =
            ((Number(this.selectedItems[i].data.storeTotalOH) +
              Number(this.selectedItems[i].data.storeTotalOO) +
              Number(this.selectedItems[i].data.inSeasonSales)) /
              totalBuy) *
            100;
        }
      }

       this.$gtag.event('Clicked_ClearAll', {
        'event_category': 'Screen 4',
        'event_label': 'Click on Clear All',
      })
    },
    clearDataOnApplyAtPopup() {
      for (let i = 0; i < this.selectedItems.length; i++) {
        this.selectedItems[i].data.salesHistoryStartDate1 = "";
        this.selectedItems[i].data.salesHistoryEndDate1 = "";
        this.selectedItems[i].data.merchHierarchy1 = "";
        this.selectedItems[i].data.merchSelections1 = "";
        this.selectedItems[i].data.weightBasis1 = "";
        this.selectedItems[i].data.salesHistoryStartDate2 = "";
        this.selectedItems[i].data.salesHistoryEndDate2 = "";
        this.selectedItems[i].data.merchHierarchy2 = "";
        this.selectedItems[i].data.merchSelections2 = "";
        this.selectedItems[i].data.weightBasis2 = "";
        this.selectedItems[i].data.useReferenceStore1 = "Y";
        this.selectedItems[i].data.useReferenceStore2 = "Y";
      }
    },
    onSelectedMerchHierarchy() {},
    opneDialog4NextStep() {
      this.onDialogOpen = true;
    },
    async onStep5() {
      this.setLoader(true);
      const response = await this.updatePayloadForR(this.getJsonData);
      this.$root.$emit("onStep5Finished", true);
      this.setLoader(false);
    },
    invokeParentMethod() {},
    onShow() {
      this.isSavedValueIsFillingInPopup = true;
      this.poissonInput = this.getPoissonDistribution;
      this.message = "";
      this.tempSelectOption = "";
      this.dcOnHand = false;
      this.dcOnOrder = false;
      this.objsToUpdate = [];
      this.salesHistoryForm.articleOwnSalesHistorys = false;
      this.salesHistoryForm.targrtAllocationUnit = "";
      this.salesHistoryForm.targrtAllocationPer = "";
      this.selectedHierarchy1 = "";
      this.selectedHierarchy2 = "";
      this.selectedArticle1 = "";
      this.selectedArticle2 = "";
      this.valueMerchSelection1 = {};
      this.valueMerchSelection2 = {};
      this.excludeOOIntermediateStatus = true;
      this.excludeOOStatus = null;
      this.useAllIndexValuesIntermediateStatus = true;
      this.useReferenceStore2IntermediateStatus = true;
      this.useReferenceStore1IntermediateStatus = true;
      this.useReferenceStore2 = null;
      this.useReferenceStore1 = null;
      this.useAllIndexValues = null;
      this.salesHistoryForm.saleHistoryEndDate1 = "";
      this.salesHistoryForm.saleHistoryStartDate1 = "";
      this.salesHistoryForm.saleHistoryEndDate2 = "";
      this.salesHistoryForm.saleHistoryStartDate2 = "";
      (this.weight1 = null),
        (this.weight2 = null),
        // this.optionCats = null;
        // this.optionMerchCats = null;
        (this.selected = "A");

      if(this.selectedItems && this.selectedItems.length > 0 && 
          window.localStorage.strategyScreen) {
        let previousDetails = 
          JSON.parse(window.localStorage.strategyScreen).previousDetails;

        let previousDetailsForSelectedArticles = this.getPreviousDetailsOfPopup();

        if(previousDetailsForSelectedArticles) {

          for(let key in previousDetailsForSelectedArticles.salesHistoryForm) {
            this.salesHistoryForm[key] = previousDetailsForSelectedArticles.salesHistoryForm[key];
            let keyForObjsToUpdate = this.mapRealVaribleToObjToUpdate.salesHistoryForm[key];
            
            if(keyForObjsToUpdate  && !(this.salesHistoryForm[key] == null || this.salesHistoryForm[key] == undefined || this.salesHistoryForm[key] === "")) {
              this.objsToUpdate.push({
                key: keyForObjsToUpdate,
                value: this.salesHistoryForm[key]
              });
            }
          }  

          for(let key in previousDetailsForSelectedArticles) {
            if(key != "salesHistoryForm") {
              this[key] = previousDetailsForSelectedArticles[key];

              let keyForObjsToUpdate = this.mapRealVaribleToObjToUpdate[key];

                if(keyForObjsToUpdate && !(this[key] == null || this[key] == undefined || this[key] === "")) {
                  this.objsToUpdate.push({
                    key: keyForObjsToUpdate,
                    value: this[key]
                  });
                }
              // }
              
            }
          }

          // setTimeout(() => {
          //   ["valueMerchSelection1", "valueMerchSelection2"].forEach(key => {
          //     this[key] = 
          //       previousDetailsForSelectedArticles[key];
          //     let keyForObjsToUpdate = this.mapRealVaribleToObjToUpdate[key];
          //     if(keyForObjsToUpdate) {
          //       this.objsToUpdate.push({
          //         key: keyForObjsToUpdate,
          //         value: this[key]
          //       });
          //     }
          //   });
          // }, 300);
          
          if((!this.valueMerchSelection1.value) || (this.valueMerchSelection1.value && typeof this.valueMerchSelection1.value != "string")) {
            this.valueMerchSelection1 = {};
          }

          if((!this.valueMerchSelection2.value) || (this.valueMerchSelection2.value && typeof this.valueMerchSelection2.value != "string")) {
            this.valueMerchSelection2 = {};
          }

        }
      }  
      setTimeout(() => this.isSavedValueIsFillingInPopup = false, 500); 
    },
    onClosePopOver() {
      this.popoverShow = false;
    },
    formatDates(dateOne, dateTwo) {
      let formattedDates = "";
      if (dateOne) {
        formattedDates = format(dateOne, this.dateFormat);
      }
      if (dateTwo) {
        formattedDates += " - " + format(dateTwo, this.dateFormat);
      }
      return formattedDates;
    },
    updatePayloadForR(jsonData) {
      return new Promise(resolve => {
        if (!!window.Worker) {
          const payloadWorker = new Worker("PayloadCreatorDedicatedWorker.js");

          payloadWorker.postMessage(jsonData);
          const _this = this;
          payloadWorker.onmessage = function(e) {
            if (typeof e.data !== "undefined") {
              _this.setJsonData(e.data);
              resolve(e.data);
            }
          };
        }
      });
    },
    cellValueChanged(val) {
      let targetPer;
      let targetUnit;
      let totalBuy = Number(val.eventVal.data.totalBuy);
      let perAlreadyAlloc = val.eventVal.data.percentageAlreadyAllocated;

      if (val.eventVal.colDef.field == "weightBasis1") {
        if (val.eventVal.value == "") {
          val.eventVal.data.weightBasis2 = "";
          return;
        }
        if (val.eventVal.value > 100 || val.eventVal.value < 0) {
          val.eventVal.data.weightBasis1 = val.eventVal.oldValue;
          return;
        }
        val.eventVal.data.weightBasis2 = 100 - val.eventVal.value;
      }
      if (val.eventVal.colDef.field == "weightBasis2") {
        if (val.eventVal.value == "") {
          val.eventVal.data.weightBasis1 = "";
          return;
        }
        if (val.eventVal.value > 100 || val.eventVal.value < 0) {
          val.eventVal.data.weightBasis2 = val.eventVal.oldValue;
          return;
        }
        val.eventVal.data.weightBasis1 = 100 - val.eventVal.value;
      }
      if (val.eventVal.colDef.field == "Target_index") {
        if (val.eventVal.value > 100 || val.eventVal.value < 0) {
          val.eventVal.data.weightBasis2 = val.eventVal.oldValue;
          return;
        }
      }
      if (val.eventVal.colDef.field == "totalBuy") {
        this.calculateUploadBuy(val);
      }
      if (val.eventVal.colDef.field == "targetAllocationUnits") {
        if (val.eventVal.value == "") {
          val.eventVal.data.targetAllocationPercentage = "";
        }
        // this.totalBuyFlag = false;
        targetUnit = Number(val.eventVal.value);
        this.calculateTargetAllocationUnit(
          val,
          targetPer,
          perAlreadyAlloc,
          totalBuy,
          targetUnit
        );
      }
      if (val.eventVal.colDef.field == "targetAllocationPercentage") {
        // this.totalBuyFlag = false;
        if (val.eventVal.value == "") {
          val.eventVal.data.targetAllocationUnits = "";
          val.gridOption.rowStyle = null;
        }
        targetPer = Number(val.eventVal.value);
        this.calculateTargetAllocationPer(
          val,
          targetPer,
          perAlreadyAlloc,
          totalBuy,
          targetUnit
        );
      }
      let disableFlag = isEqual(
        this.getTempJsonData.strategy,
        this.getJsonData.strategy
      );
      this.$root.$emit("step4Flag", disableFlag);
    },
    calculateTargetAllocationUnit(
      val,
      targetPer,
      perAlreadyAlloc,
      totalBuy,
      targetUnit
    ) {
      // val.gridOption.rowStyle = null;
      if (targetUnit > totalBuy) {
        val.eventVal.data.targetAllocationUnits = "";
        val.eventVal.data.targetAllocationPercentage = "";
        val.gridOption.rowStyle = null;
        return;
      } else {
        targetPer = (targetUnit * 100) / totalBuy;
        if (perAlreadyAlloc >= targetPer) {
          // this.targetAllocationUnitFlag = true;
          Swal.fire({
            // title: 'Error!',
            text: this.targetAllocationErrorMesg,
            icon: "warning",
            confirmButtonText: "Ok"
          });
          val.gridOption.rowStyle = { background: "#ecd1cc" };
        } else {
          val.gridOption.rowStyle = null;
        }
        if (isNaN(targetPer)) {
          targetPer = null;
          val.gridOption.rowStyle = null;
        }
        if (targetPer != null) {
          val.eventVal.data.targetAllocationPercentage = targetPer.toString();
        }
      }
    },
    calculateUploadBuy(val) {
      if (val.eventVal.value < 0) {
        val.eventVal.data.totalBuy = val.eventVal.oldValue;
        return;
      }
      let iputTotalBuy = Number(val.eventVal.value);
      val.eventVal.data.percentageAlreadyAllocated =
        ((Number(val.eventVal.data.storeTotalOH) +
          Number(val.eventVal.data.storeTotalOO) +
          Number(val.eventVal.data.inSeasonSales)) /
          iputTotalBuy) *
        100;
      val.eventVal.data.percentageAllocatedAfterMin =
        ((Number(val.eventVal.data.inventoryUnitsAllocatedForMinPres) +
          Number(val.eventVal.data.storeTotalOH) +
          Number(val.eventVal.data.storeTotalOO) +
          Number(val.eventVal.data.inSeasonSales)) /
          iputTotalBuy) *
        100;
      if (val.eventVal.data.percentageAlreadyAllocated >= 100) {
        val.gridOption.rowStyle = { background: "#ecd1cc" };
      } else {
        if (val.eventVal.data.percentageAlreadyAllocated) {
          val.gridOption.rowStyle = null;
        }
      }
      if (
        // val.eventVal.data.targetAllocationUnits &&
        val.eventVal.data.targetAllocationPercentage
      ) {
        // val.eventVal.data.targetAllocationPercentage = "";
        val.eventVal.data.targetAllocationUnits = (val.eventVal.data.totalBuy * val.eventVal.data.targetAllocationPercentage) / 100;
        // Swal.fire({
        //   // title: 'Error!',
        //   text: this.totalBuyErrorMesg,
        //   icon: "warning",
        //   confirmButtonText: "Ok"
        // });
        val.gridOption.rowStyle = { background: "#ecd1cc" };
        // this.totalBuyFlag = true;
      } else {
        val.gridOption.rowStyle = null;
      }
    },
    calculateTargetAllocationPer(
      val,
      targetPer,
      perAlreadyAlloc,
      totalBuy,
      targetUnit
    ) {
      if (
        this.getJsonData.allocationBasics.push === "CLEANOUT" &&
        val.eventVal.data.supplyingDC === "DSD/DC09"
      ) {
        if (targetPer > 100 || targetPer < 0) {
          val.eventVal.data.targetAllocationPercentage = val.eventVal.oldValue;
          val.gridOption.rowStyle = null;
          return;
        } else {
          if (perAlreadyAlloc >= targetPer) {
            // this.targetAllocationUnitFlag = true;
            Swal.fire({
              // title: 'Error!',
              text: this.targetAllocationErrorMesg,
              icon: "warning",
              confirmButtonText: "Ok"
            });
            val.gridOption.rowStyle = { background: "#ecd1cc" };
          } else {
            val.gridOption.rowStyle = null;
          }
          targetUnit = (totalBuy * targetPer) / 100;
          if (isNaN(targetUnit)) {
            targetUnit = null;
          }
          if (targetUnit != null) {
            val.eventVal.data.targetAllocationUnits = targetUnit.toString();
          }
        }
      } else if (this.getJsonData.allocationBasics.push === "CLEANOUT") {
        if (targetPer > 100 || targetPer < 0) {
          val.eventVal.data.targetAllocationPercentage = val.eventVal.newValue;
          val.gridOption.rowStyle = null;
          return;
        } else {
          if (perAlreadyAlloc >= targetPer) {
            // this.targetAllocationUnitFlag = true;
            Swal.fire({
              // title: 'Error!',
              text: this.targetAllocationErrorMesg,
              icon: "warning",
              confirmButtonText: "Ok"
            });
            val.gridOption.rowStyle = { background: "#ecd1cc" };
          } else {
            val.gridOption.rowStyle = null;
          }
          targetUnit = (totalBuy * targetPer) / 100;
          if (isNaN(targetUnit)) {
            targetUnit = null;
          }
          if (targetUnit != null) {
            val.eventVal.data.targetAllocationUnits = targetUnit.toString();
          }
        }
      } else {
        if (targetPer > 100 || targetPer < 0) {
          val.eventVal.data.targetAllocationPercentage = val.eventVal.oldValue;
          val.gridOption.rowStyle = null;
          return;
        } else {
          if (perAlreadyAlloc >= targetPer) {
            // this.targetAllocationUnitFlag = true;
            Swal.fire({
              // title: 'Error!',
              text: this.targetAllocationErrorMesg,
              icon: "warning",
              confirmButtonText: "Ok"
            });
            val.gridOption.rowStyle = { background: "#ecd1cc" };
          } else {
            val.gridOption.rowStyle = null;
          }
          targetUnit = (totalBuy * targetPer) / 100;
          if (isNaN(targetUnit)) {
            targetUnit = null;
          }
          if (targetUnit != null) {
            val.eventVal.data.targetAllocationUnits = targetUnit.toString();
          }
        }
      }
    },
    onApplyClicked(onUpdateFunc) {
      this.savePopupDetails();
      this.clearDataOnApplyAtPopup();
      this.showPaintBrush = false;
      onUpdateFunc(this.objsToUpdate);
      let disableFlag = isEqual(
        this.getTempJsonData.strategy,
        this.getJsonData.strategy
      );
      this.$root.$emit("step4Flag", disableFlag);

      this.$gtag.event('Clicked_StrategyPaintBrushApplyButton', {
        'event_category': 'Strategy Screen',
        'event_label': 'Click on Apply Button',
      })
    },
    onClearClicked(onUpdateFunc) {
      this.poissonInput = this.getPoissonDistribution;
      this.message = "";
      this.tempSelectOption = "";
      this.dcOnHand = false;
      this.dcOnOrder = false;
      this.objsToUpdate = [];
      this.salesHistoryForm.articleOwnSalesHistorys = false;
      this.salesHistoryForm.targrtAllocationUnit = "";
      this.salesHistoryForm.targrtAllocationPer = "";
      this.selectedHierarchy1 = "";
      this.selectedHierarchy2 = "";
      this.selectedArticle1 = "";
      this.selectedArticle2 = "";
      this.valueMerchSelection1 = {};
      this.valueMerchSelection2 = {};
      this.excludeOOIntermediateStatus = true;
      this.excludeOOStatus = null;
      this.useAllIndexValuesIntermediateStatus = true;
      this.useReferenceStore2IntermediateStatus = true;
      this.useReferenceStore1IntermediateStatus = true;
      this.useReferenceStore2 = null;
      this.useReferenceStore1 = null;
      this.useAllIndexValues = null;
      this.salesHistoryForm.saleHistoryEndDate1 = "";
      this.salesHistoryForm.saleHistoryStartDate1 = "";
      this.salesHistoryForm.saleHistoryEndDate2 = "";
      this.salesHistoryForm.saleHistoryStartDate2 = "";
      this.weight1 = null;
      this.weight2 = null;
    
      this.selected = "A";

      // this.setPreviousDetailsOfPopup({});
    },
    getPreviousDetailsOfPopup() {
      let localStorageObject =  window.localStorage.strategyScreen ? JSON.parse(window.localStorage.strategyScreen) : {previousDetails: {}};
      let allocationId = this.getJsonData.allocationBasics.allocationID;
      return localStorageObject.previousDetails[allocationId] || {};
    },
    setPreviousDetailsOfPopup(details){
      let localStorageObject =  window.localStorage.strategyScreen ? JSON.parse(window.localStorage.strategyScreen) : {previousDetails: {}};
      localStorageObject = localStorageObject || { previousDetails: {} };
      let allocationId = this.getJsonData.allocationBasics.allocationID;

      localStorageObject.previousDetails[allocationId] = details;
      
      window.localStorage.setItem(
         "strategyScreen", JSON.stringify(localStorageObject)
      );
    },
    onModelClose(){
             
      
    },
    savePopupDetails() {
      let previousDetailsForSelectedArticles = this.getPreviousDetailsOfPopup();
      let salesHistoryForm = {...this.salesHistoryForm};
      delete salesHistoryForm.targrtAllocationPer;
      delete salesHistoryForm.targrtAllocationUnit;

      previousDetailsForSelectedArticles = {
        salesHistoryForm,
        weight1: this.weight1,
        weight2: this.weight2,
        useReferenceStore1: this.useReferenceStore1,
        useReferenceStore2: this.useReferenceStore2,
        useReferenceStore1IntermediateStatus: this.useReferenceStore1IntermediateStatus,
        useReferenceStore2IntermediateStatus: this.useReferenceStore2IntermediateStatus,
        selectedHierarchy1: this.selectedHierarchy1,
        selectedHierarchy2: this.selectedHierarchy2,
      };

      if(this.selectedArticle1) {
        previousDetailsForSelectedArticles.selectedArticle1 = this.selectedArticle1;
      }

      if(this.selectedArticle2) {
        previousDetailsForSelectedArticles.selectedArticle2 = this.selectedArticle2;
      }

      if(this.valueMerchSelection1.value && typeof this.valueMerchSelection1.value == "string") {
        previousDetailsForSelectedArticles.valueMerchSelection1 = this.valueMerchSelection1;
      }

      if(this.valueMerchSelection2.value && typeof this.valueMerchSelection2.value == "string") {
        previousDetailsForSelectedArticles.valueMerchSelection2 = this.valueMerchSelection2;
      }

      this.setPreviousDetailsOfPopup(previousDetailsForSelectedArticles); 
    },
    onApplyTotalBuy(onUpdateFunc) {
      this.popoverShow = false;
      onUpdateFunc(null);

       this.$gtag.event('Clicked_UpdateBuyButton', {
        'event_category': 'Strategy Screen',
        'event_label': 'Click on Update Buy Button',
      })
    },
    onTotalBuySumMassUpdate(val) {
      this.selectedItems.forEach(element => {
        let totalBuy =
          element.data.storeTotalOH +
          element.data.storeTotalOO +
          element.data.inSeasonSales +
          element.data.availableInventory;
        element.data.totalBuy = totalBuy;
        element.data.percentageAlreadyAllocated =
          ((Number(element.data.storeTotalOH) +
            Number(element.data.storeTotalOO) +
            Number(element.data.inSeasonSales)) /
            totalBuy) *
          100;
        element.data.percentageAllocatedAfterMin =
          ((Number(element.data.inventoryUnitsAllocatedForMinPres) +
            Number(element.data.storeTotalOH) +
            Number(element.data.storeTotalOO) +
            Number(element.data.inSeasonSales)) /
            totalBuy) *
          100;
        if (element.data.percentageAlreadyAllocated >= 100) {
          val.gridOption.rowStyle = { background: "#ffeee2" };
          val.gridApi.redrawRows({ rowNodes: [element] });
        } else {
          val.gridOption.rowStyle = null;
          val.gridApi.redrawRows({ rowNodes: [element] });
        }
        if (
          Number(element.data.targetAllocationUnits) ||
          Number(element.data.targetAllocationPercentage)
        ) {
          // element.data.targetAllocationPercentage = "";
          element.data.targetAllocationUnits = (element.data.totalBuy * element.data.targetAllocationPercentage) / 100;
          // Swal.fire({
          //   // title: 'Error!',
          //   text: this.totalBuyErrorMesg,
          //   icon: "warning",
          //   confirmButtonText: "Ok"
          // });
          val.gridOption.rowStyle = { background: "#ecd1cc" };
          val.gridApi.redrawRows({ rowNodes: [element] });
          // val.gridOption.getRowStyle = { background: "#ffeee2" };
          // val.gridApi.redrawRows({ rowNodes: [element] });
          // this.totalBuyFlag = true;
        }
      });
      let disableFlag = isEqual(
        this.getTempJsonData.strategy,
        this.getJsonData.strategy
      );
      this.$root.$emit("step4Flag", disableFlag);
    },
    massUpdateTargetCells(val) {
      // this.totalBuyFlag = false;
      this.selectedItems.forEach(element => {
        let targetPer;
        let targetUnit;
        let totalBuy = Number(element.data.totalBuy);
        let perAlreadyAlloc = element.data.percentageAlreadyAllocated;
        let onOrder = Number(element.data.orderedQuantity);
        let unRestrictedInventory = Number(element.data.unrestrictedInventory);
        let dcDemand = Number(element.data.dcDemand);
        if (!this.useAllIndexValuesIntermediateStatus) {
          element.data.useAllIndexValues = this.useAllIndexValues ? "Y" : "N";
        }
        if (!this.useReferenceStore1IntermediateStatus) {
          element.data.useReferenceStore1 = this.useReferenceStore1 ? "Y" : "N";
        }
        if (!this.useReferenceStore2IntermediateStatus) {
          element.data.useReferenceStore2 = this.useReferenceStore2 ? "Y" : "N";
        }
        if (this.excludeOOStatus === false) {
          // let availableInventory = element.data.availableInventory
          element.data.availableInventory = 0;
          element.data.availableInventory =
            unRestrictedInventory + onOrder - dcDemand;
          let totalBuy =
            element.data.storeTotalOH +
            element.data.storeTotalOO +
            element.data.inSeasonSales +
            element.data.availableInventory;
          element.data.totalBuy = totalBuy;
          // if (
          //   (element.datatargetAllocationUnits ||
          //     element.datatargetAllocationUnits === 0) &&
          //   (element.datatargetAllocationPercentage ||
          //     element.datatargetAllocationPercentage === 0)
          // ) {
          //   element.data.targetAllocationPercentage = "";
          //   element.data.targetAllocationUnits = "";
          //   Swal.fire({
          //     // title: 'Error!',
          //     text: this.totalBuyErrorMesg,
          //     icon: 'warning',
          //     confirmButtonText: 'Ok'
          //   })
          //   // this.totalBuyFlag = true;
          // }
          element.data.percentageAlreadyAllocated =
            ((Number(element.data.storeTotalOH) +
              Number(element.data.storeTotalOO) +
              Number(element.data.inSeasonSales)) /
              totalBuy) *
            100;
          //element.data.availableInventory = availableInventory - onOrder
        } else if (this.excludeOOStatus === true) {
          element.data.availableInventory = 0;
          element.data.availableInventory = unRestrictedInventory - dcDemand;
          let totalBuy =
            element.data.storeTotalOH +
            element.data.storeTotalOO +
            element.data.inSeasonSales +
            element.data.availableInventory;
          element.data.totalBuy = totalBuy;
          if (
            element.data.targetAllocationUnits &&
            element.data.targetAllocationPercentage
          ) {
            // element.data.targetAllocationPercentage = "";
            element.data.targetAllocationUnits = (element.data.totalBuy * element.data.targetAllocationPercentage) / 100;
            // Swal.fire({
            //   // title: 'Error!',
            //   text: this.totalBuyErrorMesg,
            //   icon: "warning",
            //   confirmButtonText: "Ok"
            // });
            // this.totalBuyFlag = true;
          }
          element.data.percentageAlreadyAllocated =
            ((Number(element.data.storeTotalOH) +
              Number(element.data.storeTotalOO) +
              Number(element.data.inSeasonSales)) /
              totalBuy) *
            100;
        }

        if (this.selected == "A") {
          if (
            this.objsToUpdate.map(e => e.key).includes("merchHierarchy1") &&
            !this.objsToUpdate.map(e => e.key).includes("merchSelections1")
          ) {
            if (element.data.merchHierarchy1 == "Article") {
              element.data.merchSelections1 = element.data.productID;
            } else if (element.data.merchHierarchy1 == "MerchCat") {
              element.data.merchSelections1 = element.data.merchCategoryID;
            } else if (element.data.merchHierarchy1 == "Char Collection") {
              element.data.merchSelections1 = element.data.collection;
            } else {
              element.data.merchSelections1 = element.data.categoryID;
            }
          }
          if (
            this.objsToUpdate.map(e => e.key).includes("merchHierarchy2") &&
            !this.objsToUpdate.map(e => e.key).includes("merchSelections2")
          ) {
            if (element.data.merchHierarchy2 == "Article") {
              element.data.merchSelections2 = element.data.productID;
            } else if (element.data.merchHierarchy2 == "MerchCat") {
              element.data.merchSelections2 = element.data.merchCategoryID;
            } else if (element.data.merchHierarchy2 == "Char Collection") {
              element.data.merchSelections2 = element.data.collection;
            } else {
              element.data.merchSelections2 = element.data.categoryID;
            }
          }
          if (totalBuy < element.data.targetAllocationUnits) {
            // this.targetAllocationUnitFlag = true;
            Swal.fire({
              // title: 'Error!',
              text: this.targetAllocationErrorMesg,
              icon: "warning",
              confirmButtonText: "Ok"
            });
            element.data.targetAllocationPercentage = "";
            element.data.targetAllocationUnits = "";
            val.gridOption.rowStyle = { background: "#ecd1cc" };
            val.gridApi.redrawRows({ rowNodes: [element] });
            return;
          } else {
            // this.targetAllocationUnitFlag = false;
            targetUnit = Number(element.data.targetAllocationUnits);
            targetPer = (targetUnit * 100) / totalBuy;
            if (perAlreadyAlloc >= targetPer) {
              // if (targetPer) {
              // this.targetAllocationUnitFlag = true;
              Swal.fire({
                // title: 'Error!',
                text: this.targetAllocationErrorMesg,
                icon: "warning",
                confirmButtonText: "Ok"
              });
              val.gridOption.rowStyle = { background: "#ecd1cc" };
              val.gridApi.redrawRows({ rowNodes: [element] });
              element.data.targetAllocationPercentage = targetPer.toString();
              // }
              return;
            } else {
              val.gridOption.rowStyle = null;
              val.gridApi.redrawRows({ rowNodes: [element] });
              if (isNaN(targetPer)) {
                targetPer = "";
              }
              if (targetPer) {
                element.data.targetAllocationPercentage = targetPer.toString();
              }
            }
          }
        } else {
          
          if (
            this.objsToUpdate.map(e => e.key).includes("merchHierarchy1") &&
            !this.objsToUpdate.map(e => e.key).includes("merchSelections1")
          ) {
            if (element.data.merchHierarchy1 == "Article") {
              element.data.merchSelections1 = element.data.productID;
            } else if (element.data.merchHierarchy1 == "MerchCat") {
              element.data.merchSelections1 = element.data.merchCategoryID;
            } else if (element.data.merchHierarchy1 == "Char Collection") {
              element.data.merchSelections1 = element.data.collection;
            } else {
              element.data.merchSelections1 = element.data.categoryID;
            }
          }
          if (
            this.objsToUpdate.map(e => e.key).includes("merchHierarchy2") &&
            !this.objsToUpdate.map(e => e.key).includes("merchSelections2")
          ) {
            if (element.data.merchHierarchy2 == "Article") {
              element.data.merchSelections2 = element.data.productID;
            } else if (element.data.merchHierarchy2 == "MerchCat") {
              element.data.merchSelections2 = element.data.merchCategoryID;
            } else if (element.data.merchHierarchy2 == "Char Collection") {
              element.data.merchSelections2 = element.data.collection;
            } else {
              element.data.merchSelections2 = element.data.categoryID;
            }
          }
          targetPer = Number(element.data.targetAllocationPercentage);
          targetUnit = (totalBuy * targetPer) / 100;
          if (perAlreadyAlloc >= targetPer) {
            // if (targetPer) {
            // this.targetAllocationUnitFlag = true;
            Swal.fire({
              // title: 'Error!',
              text: this.targetAllocationErrorMesg,
              icon: "warning",
              confirmButtonText: "Ok"
            });
            element.data.targetAllocationUnits = targetUnit.toString();
            val.gridOption.rowStyle = { background: "#ecd1cc" };
            val.gridApi.redrawRows({ rowNodes: [element] });
            // }
            return;
          } else {
            if (isNaN(targetUnit)) {
              targetUnit = null;
            }
            element.data.targetAllocationUnits = targetUnit.toString();
            val.gridOption.rowStyle = null;
            val.gridApi.redrawRows({ rowNodes: [element] });
          }
        }
      });
      let disableFlag = isEqual(
        this.getTempJsonData.strategy,
        this.getJsonData.strategy
      );
      this.$root.$emit("step4Flag", disableFlag);
      // val.gridOption.refreshCells();
      // val.gridApi.api.refreshCells()
      val.gridOption.api.refreshCells();
    },
    forceRenderCalSumTable(val) {
      this.forceRerender = !this.forceRerender;
    },
    onPoissonInput(val) {
      if (val === "-") {
        this.poissonInput = "0";
        this.message = "percent should be between 0 and 100";
        return;
      }
      if (val != "" && val <= 100 && val >= 0) {
        this.message = "";
        this.objsToUpdate.push({ key: "Target_index", value: val });
      } else {
        this.poissonInput = "";
        this.message = "Percentage should not be more than 100";
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", handler);
    this.StrategyColDef = null;
    this.selectedItems = null;
  },
  created() {
    this.$root.$on("forceRender", this.forceRenderCalSumTable);
    this.actionGetMerchCatAndCat().then(resp => {
      this.optionCats = resp.cats;
      this.optionMerchCats = resp.merch_cats;
      this.collection = resp.collection;
    });
    window.addEventListener("beforeunload", handler);
    this.$root.$on("onStep5", this.onStep5);
    this.$root.$on("dialog4NextStep", this.opneDialog4NextStep);
  },
  mounted() {
    this.fetchPoissonDistribution();
  },
  watch: {
    isStep4ForceRenderer(val) {
      this.forceRerender = !this.forceRerender;
    },
    selectedHierarchy1(val) {
      this.valueMerchSelection1 = {};
      searchText = "";
    },
    selectedHierarchy2(val) {
      this.valueMerchSelection2 = {};
    },
    // poissonInput(val) {
    //   if (val === "-") {
    //     this.poissonInput = "0";
    //     this.message = "percent should be between 0 and 100";
    //     return;
    //   }
    //   if (val != "" && val <= 100 && val >= 0) {
    //     this.message = "";
    //     this.objsToUpdate.push({ key: "Target_index", value: val });
    //   } else {
    //     this.poissonInput = "";
    //     this.message = "Percentage should not be more than 100";
    //   }
    // },
    weight2(val) {
      if (val === "-") {
        this.weight2 = "0";
        this.weight1 = "100";
        this.message = "percent should be between 0 and 100";
        return;
      }
      if (val === "") {
        this.weight1 = "";
        this.weight2 = "";
        this.message = "percent should be between 0 and 100";
        this.objsToUpdate.push({
          key: "weightBasis1",
          value: this.weight1
        });
        this.objsToUpdate.push({
          key: "weightBasis2",
          value: this.weight2
        });
        return;
      }
      let v = val.toString();
      let vInt = parseFloat(val).toFixed(2);

      if (v != "" && !isNaN(vInt) && vInt <= 100 && vInt >= 0) {
        this.message = "";
        if (
          (parseFloat(this.weight1) + parseFloat(this.weight2)).toFixed(2) !=
          100.0
        ) {
          this.weight1 = (100 - vInt).toFixed(2);
          this.objsToUpdate.push({ key: "weightBasis2", value: vInt });
          this.objsToUpdate.push({
            key: "weightBasis1",
            value: Number(this.weight1)
          });
        }
      } else if (v != "") {
        this.weight2 = "";
        this.weight1 = "";
        this.objsToUpdate.push({
          key: "weightBasis1",
          value: this.weight1
        });
        this.objsToUpdate.push({
          key: "weightBasis2",
          value: this.weight2
        });
        this.message = "Percentage should not be more than 100";
      }
    },
    weight1(val) {
      if (val === "-") {
        this.weight1 = "0";
        this.weight2 = "100";
        this.message = "percent should be between 0 and 100";
        console.log(`${this.weight1} - ${this.weight2}`, " if (val === '-') {")
        return;
      }
      if (val === "") {
        this.weight1 = "";
        this.weight2 = "";
        this.message = "percent should be between 0 and 100";
        this.objsToUpdate.push({
          key: "weightBasis1",
          value: this.weight1
        });
        this.objsToUpdate.push({
          key: "weightBasis2",
          value: this.weight2
        });
        console.log(`${this.weight1} - ${this.weight2}`, " if (val === '') {")

        return;
      }
      let v = val.toString();
      let vInt = parseFloat(val);
      if (v != "" && !isNaN(vInt) && vInt <= 100 && vInt >= 0) {
        if (
          (parseFloat(this.weight1) + parseFloat(this.weight2)).toFixed(2) !=
          100.0
        ) {
          this.message = "";
          this.weight2 = (100 - vInt).toFixed(2);
          this.objsToUpdate.push({
            key: "weightBasis2",
            value: Number(this.weight2)
          });
          this.objsToUpdate.push({ key: "weightBasis1", value: vInt });
        }
      } else if (v != "") {
        this.weight1 = "";
        this.weight2 = "";
        this.objsToUpdate.push({
          key: "weightBasis1",
          value: this.weight1
        });
        this.objsToUpdate.push({
          key: "weightBasis2",
          value: this.weight2
        });
        console.log(`${this.weight1} - ${this.weight2}`, `} else if (v != "") {`)

        this.message = "Percentage should not be more than 100";
      }
    },
    // getMerchCatAndCat(val) {
    //   this.optionCats = val.cats;
    //   this.optionMerchCats = val.merch_cats;
    // },
    selected(val) {
      if (val === "A") {
        this.salesHistoryForm.targrtAllocationPer = "";
        this.disableB = !this.disableB;
        this.disableA = !this.disableA;
      } else {
        this.salesHistoryForm.targrtAllocationUnit = "";
        this.disableA = !this.disableA;
        this.disableB = !this.disableB;
      }
    },
    valueMerchSelection1(val) {
      // if(this.isSavedValueIsFillingInPopup) return;
      if (val.value)
        this.objsToUpdate.push({ key: "merchSelections1", value: val.value });
    },
    excludeOOStatus(val) {
      this.objsToUpdate.push({ key: "includeDCOO", value: !!val });
    },
    valueMerchSelection2(val) {
      if (val.value)
        this.objsToUpdate.push({ key: "merchSelections2", value: val.value });
    },
    selectedArticle1(val) {
      if (val) {
        this.objsToUpdate.push({ key: "merchSelections1", value: val });
      } else {
        this.objsToUpdate = 
          this.objsToUpdate.filter(item => item.key !== "merchSelections1");
      } 
    },
    selectedArticle2(val) {
      if (val) {
        this.objsToUpdate.push({ key: "merchSelections2", value: val });
      } else {
        this.objsToUpdate = 
          this.objsToUpdate.filter(item => item.key !== "merchSelections2");
      }   
    },
    selectedHierarchy1(val) {
      if(this.isSavedValueIsFillingInPopup) return;

      if (val != ""){
        this.objsToUpdate.push({ key: "merchHierarchy1", value: val });
        this.valueMerchSelection1 = {};

        this.objsToUpdate = 
          this.objsToUpdate.filter(item => item.key !== "merchSelections1");
      }
    },
    selectedHierarchy2(val) {
      if(this.isSavedValueIsFillingInPopup) return;

      if (val != "") {
        this.objsToUpdate.push({ key: "merchHierarchy2", value: val });
        this.valueMerchSelection2 = {};
        this.objsToUpdate = 
          this.objsToUpdate.filter(item => item.key !== "merchSelections2");
      }
    },
    "salesHistoryForm.targrtAllocationUnit"(val) {
      if (val) {
        this.objsToUpdate.push({ key: "targetAllocationUnits", value: val });
      }
    },
    "salesHistoryForm.targrtAllocationPer"(val) {
      if (val) {
        if (this.getJsonData.allocationBasics.push === "CLEANOUT") {
          if (val != "" && val >= 0) {
            this.message = "";
            this.objsToUpdate.push({
              key: "targetAllocationPercentage",
              value: val
            });
          }
        } else {
          if (val != "" && val <= 100 && val >= 0) {
            this.message = "";
            this.objsToUpdate.push({
              key: "targetAllocationPercentage",
              value: val
            });
          } else {
            this.salesHistoryForm.targrtAllocationPer = "";
            this.message = "Percentage should not be more than 100";
          }
        }
      }
      // if (val != "" && val <= 100 && val >= 0) {
      //   this.message = "";
      //   this.objsToUpdate.push({
      //     key: "targetAllocationPercentage",
      //     value: val
      //   });
      // } else {
      //   this.salesHistoryForm.targrtAllocationPer = "";
      //   this.message = "Percentage should not be more than 100";
      // }
    },
    "salesHistoryForm.articleOwnSalesHistorys"(val) {
      if (val)
        this.objsToUpdate.push({
          key: "articleOwnSalesHistory",
          value: "Article"
        });
      else
        this.objsToUpdate.push({
          key: "articleOwnSalesHistory",
          value: "Merch_cat"
        });
    }
  }
};
</script>
<style>
input[type="date"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>

<style scoped>
::placeholder {
  /* Firefox, Chrome, Opera */
  color: #e4e4e5;
}
hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-color: blue;
}
.btn-download {
  color: #71be00 !important;
  border-color: #71be00;
  background-color: white !important;
}
.btn-download:hover {
  color: #71be00 !important;
  border-color: #71be00;
  background-color: white !important;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.col-sm-4 {
  padding-right: 10px;
  padding-left: 10px;
}
.fa,
.fas {
  font-weight: 900;
  color: royalblue;
  padding-left: 5px;
}
</style>
<style>
.asd__month-year-select {
  color: #73bd00 !important;
}
.modal-footer .btn {
  background-color: rgb(115, 189, 0) !important;
  border-color: rgb(115, 189, 0) !important;
  color: white !important;
}
#adminSettings___BV_modal_header_ {
  color: #ffffff !important;
  background-color: #000000;
}
.close {
  color: white !important;
}
.popover {
  /* overflow: auto !important; */
}
.table-info {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  background-color: transparent;
  width: 90%;
  margin: 0.5rem auto;
}
.table-info tr {
  border: 1px solid grey;
}
.table-info tr th {
  padding: 0.4rem;
  background-color: rgb(228, 225, 225);
}
.table-info tr td {
  padding: 0.4rem;
}
.info-box-red {
  background-color: #f77b65;
  width: 2.5rem;
  height: 1rem;
  margin: 0 auto;
}
.info-box-purple {
  background-color: #e9a7f0;
  width: 2.5rem;
  height: 1rem;
  margin: 0 auto;
}
select:focus {
  outline: none !important;
}
</style>