<template>
  <div v-if="getJsonData">
    <vue-element-loading :active="loaderState" spinner="bar-fade-scale" :text="message" />
    <div>
      <b-card-group deck style="padding-bottom:1rem;">
        <b-card>
          <div slot="header">
            <strong>Store Selection</strong>
            <div>
              <br />
            </div>
          </div>
          <div>
            <data-table
              :data="computedSiteSelctionData"
              :colDefs="siteSelectionColDef"
              pivotPanelShow="never"
              :key="forceRerender"
              :count="computedSiteSelctionData.length"
              :sideBar="false"
              @removedSuccess="removedSuccess"
              @cellClicked="getStoreInfo"
              @updatedDataOnRemove="excludeAll"
              @selectedItems="onSelectionChanged"
            >
              <template slot-scope="props">
                Actions:
                <span>
                  <span>
                    <i
                      v-if="!selectedStoresArticleId.length"
                      v-b-tooltip.hover
                      title="Please select an article to use this option"
                      class="fas fa-paint-brush cursor-not-allowed"
                      style="margin-right:0.5rem"
                    ></i>
                    <i
                      v-else
                      class="fas fa-paint-brush cursor-pointer"
                      style="margin-right:0.5rem"
                      id="massUpdatePopOverStep3"
                    ></i>
                    <i
                      v-if="!selectedStoresArticleId.length"
                      v-b-tooltip.hover
                      title="Please select row(s) to Exclude"
                      class="fa fa-times cursor-not-allowed"
                      id="exclude"
                      style="margin-right:0.5rem;font-size: 1.1rem; color: #CF2A28; font-weight: 500;"
                    ></i>
                    <i
                      v-else
                      id="exclude"
                      class="fa fa-times cursor-pointer"
                      style="margin-right:0.5rem;font-size: 1.1rem; color: #CF2A28; font-weight: 500;"
                      @click="onActionClick(props.onDelete,`exclude`)"
                    ></i>
                    <i
                      v-if="!selectedStoresArticleId.length"
                      v-b-tooltip.hover
                      id="include"
                      title="Please select row(s) to Include"
                      class="fa fa-check cursor-not-allowed"
                      style="margin-right:0.5rem;font-size: 1.1rem; color: #1AA830; font-weight: 500;"
                    ></i>
                    <i
                      v-else
                      id="include"
                      class="fa fa-check cursor-pointer"
                      style="margin-right:0.5rem;font-size: 1.1rem; color: #1AA830; font-weight: 500;"
                      @click="onActionClick(props.onDelete,`include`)"
                    ></i>
                  </span>
                </span>
                <b-popover
                  v-if="selectedStoresArticleId.length"
                  target="massUpdatePopOverStep3"
                  triggers="click"
                  :show.sync="popoverShow"
                  placement="auto"
                  container="myContainer"
                  ref="popover"
                  @show="onShow"
                >
                  <template slot="title">
                    <b-button
                      class="close"
                      aria-label="Close"
                      @click="onClose"
                      style="color: #FFFFFF;"
                    >
                      <span class="d-inline-block" aria-hidden="true">&times;</span>
                    </b-button>
                    <b-row>
                      <b-col style="padding-right:0; padding-top: 0.3rem;" cols="8">Exclude/Include</b-col>
                      <b-col style="padding-left:0;padding-right:0;" cols="4">
                        <b-form-select
                          v-model="selectedOptionsForReIncludeAndExclude"
                          :options="options"
                          size="sm"
                        ></b-form-select>
                      </b-col>
                    </b-row>
                  </template>
                  <div v-if="selectedOptionsForReIncludeAndExclude != 'Select'">
                    <b-form-group>
                      <b-form-radio-group
                        id="radios2"
                        v-model="selectedOption"
                        name="radioSubComponent"
                      >
                        <b-form-radio
                          value="A"
                          name="some-radios"
                        >Exclude {{selectedOptionsForReIncludeAndExclude}}</b-form-radio>
                        <b-form-radio
                          value="B"
                          name="some-radios"
                        >Include {{selectedOptionsForReIncludeAndExclude}}</b-form-radio>
                      </b-form-radio-group>
                    </b-form-group>
                    <b-form-group>
                      <b-form-input
                        v-model="IDstoExclude"
                        :placeholder="placeHolderForSelectedOptionsForReIncludeAndExclude"
                        type="text"
                        width="100%"
                      />
                    </b-form-group>
                    <div align="right">
                      <b-button @click="excludeStoreIds" size="sm" variant="primary">Apply</b-button>
                    </div>
                  </div>
                  <div v-else>
                    <div
                      style="color: grey; padding: 1rem;"
                    >Please select an option for Exclude/Re-include</div>
                  </div>
                </b-popover>
              </template>
            </data-table>
          </div>
        </b-card>

        <b-card>
          <div slot="header">
            <b-row>
              <b-col md="6">
                <strong>{{storeSelectionType}}</strong>
              </b-col>
              <b-col md="6">
                <strong>Article ID : {{selectedArticleIDObj.articleID}}</strong>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                md="6"
                v-if="findLodash(computedSiteSelctionData, {'articleID': selectedArticleIDObj.articleID})"
              >
                <span v-if="storeSelectionType === 'Net Store Selection'">
                  <strong>{{findLodash(computedSiteSelctionData, {'articleID': selectedArticleIDObj.articleID}).netStoreSelctionCount}}</strong>Distinct,
                  <strong>{{findLodash(computedSiteSelctionData, {'articleID': selectedArticleIDObj.articleID}).netStoreSelectionDistinct}}</strong> Records
                </span>
                <span v-else>
                  <strong>{{findLodash(computedSiteSelctionData, {'articleID': selectedArticleIDObj.articleID}).storeExclusionCount}}</strong>Distinct,
                  <strong>{{findLodash(computedSiteSelctionData, {'articleID': selectedArticleIDObj.articleID}).netStoreExclusionDistinctCount}}</strong> Records
                </span>
              </b-col>
              <b-col md="6" v-else>
                <strong>--</strong> Records,
                <strong>--</strong> Distinct
              </b-col>
            </b-row>
          </div>
          <selections-filter-step3
            v-if="showSelections"
            @ExcludedStoreIdFromFilter="deleteByPastingStoreIDinFilterFromExclusionTable"
            :filterData="filterData"
            :articleId="selectedArticleIDObj.articleID"
          ></selections-filter-step3>
          <div>
            <data-table
              :data="storeData"
              :count="storeData.length"
              :colDefs="siteExclusionColDef"
              :ifDisplayCount="false"
              :step3ExpandAll="true"
              @removedSuccess="removedSuccess"
              @updatedDataOnRemove="updatedDataOnRemove"
              @selectedItems="v => selectedItems = v"
            >
              <template slot-scope="props">
                Actions:
                <span v-if="storeSelectionType == 'Net Store Selection'">
                  <i
                    v-if="!selectedItems.length"
                    v-b-tooltip.hover
                    title="Please select row(s) to Exclude"
                    class="fa fa-times cursor-not-allowed"
                    style="margin-right:1rem;font-size: 1.1rem; color: #CF2A28; font-weight: 500;"
                  ></i>
                  <i
                    v-else
                    class="fa fa-times cursor-pointer"
                    style="margin-right:0.5rem;font-size: 1.1rem; color: #CF2A28; font-weight: 500;"
                    @click="props.onDelete"
                  ></i>
                </span>
                <span v-else>
                  <i
                    v-if="!selectedItems.length"
                    v-b-tooltip.hover
                    title="Please select row(s) to Include"
                    class="fa fa-check cursor-not-allowed"
                    style="margin-right:1rem;font-size: 1.1rem; color: #1AA830; font-weight: 500;"
                  ></i>
                  <i
                    v-else
                    class="fa fa-check cursor-pointer"
                    style="margin-right:0.5rem;font-size: 1.1rem; color: #1AA830; font-weight: 500;"
                    @click="props.onDelete"
                  ></i>
                </span>
              </template>
            </data-table>
          </div>
        </b-card>
      </b-card-group>
    </div>
    <b-modal v-model="onSuccess" centered title="BootstrapVue" ok-only hide-header>
      <div align="middle">
        <h5 class="my-4">This information was saved successfully.</h5>
      </div>
    </b-modal>
    <b-modal v-model="onError" centered title="BootstrapVue" ok-only hide-header>
      <div align="middle">
        <div class="my-4" style="color: orange; font-size: 0.9rem;">
          <span>
            <i
              class="fa fa-exclamation-circle"
              style="margin-right:0.2rem; font-size: 0.9rem; color: orange;font-weight: 600;"
            ></i>
          </span>Some Stores/LM's/AVL's/Corp Tier were not excluded because they were either already excluded or not present originally in the Store Selection Table.
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { isEqual } from "lodash";
import { format } from "date-fns";
import { uniq, map, filter, includes, find, concat, upperCase } from "lodash";
import selectionsFilterStep3 from "@/components/selectionsFilterStep3";
import DataTable from "@/components/DataTable";
import siteSelectionColDef from "@/constants/tableDefinitions/siteSelectionColDef";
import siteExclusionColDef from "@/constants/tableDefinitions/siteExclusionColDef";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { setTimeout } from "timers";
var handler = function(e) {
  var confirmationMessage = "Your unsave data will lost.";
  let tab = e || window.event;
  tab.returnValue = confirmationMessage;
  return confirmationMessage;
};
export default {
  props: ["isStep3ForceRenderer", "allocationId"],
  data() {
    return {
      forceRerender: false,
      options: [
        { value: "Stores", text: "Stores" },
        { value: "LM's", text: "LM's" },
        { value: "AVL", text: "AVL" },
        { value: "Corp Tier", text: "Corp Tier" }
      ],
      selectedOptionsForReIncludeAndExclude: "Stores",
      placeHolderForSelectedOptionsForReIncludeAndExclude: "Paste Stores",
      format,
      selectionFilterFlag: false,
      onError: false,
      message: "This step may take some time. Thank you for your patience.",
      selectedOption: "A",
      onSuccess: false,
      loaderSave: false,
      loaderState: false,
      selectionsDataForArticle: [],
      IDstoExclude: [],
      filterData: [],
      showSelections: false,
      storeData: [],
      filter,
      popoverShow: false,
      storeSelectionType: "Net Store Selection",
      selectedArticleIDObj: "",
      LMValue: "A001",
      selectedItems: [],
      siteSelectionColDef,
      siteExclusionColDef,
      selectedStoresArticleId: [],
      findLodash: find
    };
  },
  watch: {
    isStep3ForceRenderer(val) {
      this.forceRerender = !this.forceRerender;
    },
    selectedOption(val) {
      if (
        val == "B" &&
        this.selectedOptionsForReIncludeAndExclude == "Stores"
      ) {
        this.placeHolderForSelectedOptionsForReIncludeAndExclude =
          "Paste Stores";
      } else if (
        val == "A" &&
        this.selectedOptionsForReIncludeAndExclude == "Stores"
      ) {
        this.placeHolderForSelectedOptionsForReIncludeAndExclude =
          "Paste Stores";
      } else if (
        val == "B" &&
        this.selectedOptionsForReIncludeAndExclude == "LM's"
      ) {
        this.placeHolderForSelectedOptionsForReIncludeAndExclude = "Paste LM's";
      } else if (
        val == "A" &&
        this.selectedOptionsForReIncludeAndExclude == "LM's"
      ) {
        this.placeHolderForSelectedOptionsForReIncludeAndExclude = "Paste LM's";
      } else if (
        val == "B" &&
        this.selectedOptionsForReIncludeAndExclude == "AVL"
      ) {
        this.placeHolderForSelectedOptionsForReIncludeAndExclude = "Paste AVL";
      } else if (
        val == "A" &&
        this.selectedOptionsForReIncludeAndExclude == "AVL"
      ) {
        this.placeHolderForSelectedOptionsForReIncludeAndExclude = "Paste AVL";
      } else if (
        val == "B" &&
        this.selectedOptionsForReIncludeAndExclude == "Tier"
      ) {
        this.placeHolderForSelectedOptionsForReIncludeAndExclude =
          "Paste Corp Tier";
      } else {
        this.placeHolderForSelectedOptionsForReIncludeAndExclude =
          "Paste Corp Tier";
      }
    },
    selectedOptionsForReIncludeAndExclude() {
      if (this.selectedOptionsForReIncludeAndExclude == "Stores") {
        this.placeHolderForSelectedOptionsForReIncludeAndExclude =
          "Paste Store";
      } else if (this.selectedOptionsForReIncludeAndExclude == "LM's") {
        this.placeHolderForSelectedOptionsForReIncludeAndExclude = "Paste LM's";
      } else if (this.selectedOptionsForReIncludeAndExclude == "AVL") {
        this.placeHolderForSelectedOptionsForReIncludeAndExclude = "Paste AVL";
      } else {
        this.placeHolderForSelectedOptionsForReIncludeAndExclude =
          "Paste Corp Tier";
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", handler);
    this.siteSelectionColDef = null;
    this.siteExclusionColDef = null;
    this.selectedItems = null;
  },
  computed: {
    ...mapGetters({
      getJsonData: "CreateAllocation/getJsonData",
      getTempJsonData: "CreateAllocation/getTempJsonData",
      getLoader: "CreateAllocation/getLoader",
      getSitesByIds: "CreateAllocation/getSitesByIds"
    }),
    computedSiteSelctionData() {
      let data = [];
      if (this.getJsonData.sites != null) {
        this.getJsonData.sites.forEach(element => {
          if (element.excusions === undefined) {
            element.excusions = [];
          }
          var newObj = {};
          newObj["articleID"] = element.ArticleId;
          newObj["netStoreSelectionDistinct"] = element.Selections.length;
          newObj["netStoreSelctionCount"] = uniq(
            map(element.Selections, e => e.store_id)
          ).length;
          newObj["netStoreExclusionDistinctCount"] = element.excusions.length;
          newObj["storeExclusionCount"] = uniq(
            map(element.excusions, e => e.store_id)
          ).length;
          newObj["selctionIds"] = element.Selections;
          newObj["exclusionIds"] = element.excusions;
          this.selectionsDataForArticle = element.Selections;
          this.exclusioDataTable = element.excusions;
          data.push(newObj);
        });
      }
      this.forceRerender = !this.forceRerender;
      return data;
    }
  },
  components: {
    DataTable,
    selectionsFilterStep3
  },
  created() {
    this.sitesByIds({ siteIds: [] });
    this.$root.$on(
      "siteFilteredData1",
      this.deleteByPastingStoreIDinFilterFromExclusionTable
    );
  },
  methods: {
    ...mapActions({
      sitesByIds: "CreateAllocation/sitesByIds"
    }),
    onActionClick(fun, value) {
      this.storeData = [];
      let val = {
        target: {
          id: value
        }
      };
      fun(val);
    },
    removedSuccess(val) {
      let disableFlag = isEqual(
        this.getTempJsonData.sites,
        this.getJsonData.sites
      );
      this.$root.$emit("step3Flag", disableFlag);
    },
    onClose() {
      this.popoverShow = false;
    },
    close() {
      this.popoverShow = false;
    },
    onShow() {
      this.selectedOptionsForReIncludeAndExclude = "Stores";
      this.selectedOption = "A";
      this.IDstoExclude = null;

      this.$gtag.event('Clicked_SiteExclusionPaintBrushApplyButton', {
        'event_category': 'Site Exclusion Screen',
        'event_label': 'Click on Apply Button',
      })
    },
    getFilterDataOptions() {
      var jp = require("jsonpath");
      let dataSites = [];
      let siteIds = [];
      let storeId = "";
      this.selectionsDataForArticle.forEach(e => {
        storeId = e.store_id;
        var storeData = this.getSitesByIds.find(v => {
          return v.store_id === storeId;
        });
        let returnNewObj;
        if (storeData) {
          returnNewObj = { ...e, ...storeData };
          dataSites.push(returnNewObj);
        }
        // storeId = e.store_id;
        // var storeData = jp.query(
        //   this.getSitesByIds,
        //   "$..[?(@.store_id==" + storeId + ")]"
        // );
        // let returnNewObj = this.addDataToColumns(storeData);
        // dataSites.push(returnNewObj);
      });
      this.filterData = dataSites;
    },
    excludeAll(finalData) {
      finalData.removedData.forEach((element, index) => {
        this.getJsonData.sites.forEach(obj => {
          if (element.articleID === obj.ArticleId) {
            if (finalData.selectedId != "exclude") {
              obj.Selections = concat(element.exclusionIds, obj.Selections);
              obj.excusions = [];
            } else {
              obj.Selections = [];
              obj.excusions = concat(element.selctionIds, obj.excusions);
            }
          }
        });
      });
      this.selectedStoresArticleId = [];
      this.forceRerender = !this.forceRerender;
    },
    updatedDataOnRemove(finalData) {
      let ArticleId = finalData.removedData[0].articleID;
      if (this.storeSelectionType == "Net Store Selection") {
        this.getJsonData.sites.forEach(element => {
          if (element.ArticleId === ArticleId) {
            element.Selections = finalData.existingData;
            element.excusions = concat(
              element.excusions,
              finalData.removedData
            );
          }
        });
      } else {
        this.getJsonData.sites.forEach(element => {
          if (element.ArticleId === ArticleId) {
            element.excusions = finalData.existingData;
            element.Selections = concat(
              element.Selections,
              finalData.removedData
            );
          }
        });
      }
      this.forceRerender = !this.forceRerender;
    },

    getStoreInfo(val) {
      this.loaderState = true;
      let dataSites = [];
      this.selectedStoresArticleId = [];
      let siteIds = [];
      let avlLocal = "";
      let lmLocal = "";
      let storeId = "";
      this.selectedArticleIDObj = val.data;
      let selectedArticleId = val.data.articleID;
      this.selectedStoresArticleId.push(selectedArticleId);
      let ArticleSelectionObj = this.getJsonData.articles.selections.find(v => {
        return v.article === selectedArticleId;
      });
      let dropShip = ArticleSelectionObj.supplyingSource;
      let dcTwenty = ArticleSelectionObj.is_dc20;
      setTimeout(() => {
        if (val.colDef.field === "netStoreSelctionCount") {
          this.showSelections = false;
          this.storeSelectionType = "Net Store Selection";
          val.data.selctionIds.forEach(e => {
            storeId = e.store_id;
            avlLocal = e.avl;
            lmLocal = e.layout_module;
            var storeData = this.getSitesByIds.find(v => {
              return v.store_id === storeId;
            });
            let returnNewObj;
            if (storeData) {
              returnNewObj = { ...e, ...storeData };
              returnNewObj["articleID"] = selectedArticleId;
              returnNewObj["avl"] = avlLocal;
              returnNewObj["layout_module"] = lmLocal;
              returnNewObj["supplyingSource"] = "DC";
              returnNewObj["supplying_dc"] = e.dc;
            }
            if (dropShip === 1) {
              returnNewObj["supplyingSource"] = "DSD/DC09";
              returnNewObj["supplying_dc"] = "NA";
            }
            dataSites.push(returnNewObj);
          });
          this.storeData = dataSites;
          // this.getFilterDataOptions();
          this.loaderState = false;
        } else {
          this.showSelections = true;
          this.storeSelectionType = "Store Exclusion";
          val.data.exclusionIds.forEach(e => {
            storeId = e.store_id;
            avlLocal = e.avl;
            lmLocal = e.layout_module;
            var storeData = this.getSitesByIds.find(v => {
              return v.store_id === storeId;
            });
            let returnNewObj;
            if (storeData) {
              returnNewObj = { ...e, ...storeData };
              returnNewObj["articleID"] = selectedArticleId;
              returnNewObj["avl"] = avlLocal;
              returnNewObj["layout_module"] = lmLocal;
              returnNewObj["supplyingSource"] = "DC";
              returnNewObj["supplying_dc"] = e.dc;
              if (dropShip === 1) {
                returnNewObj["supplyingSource"] = "DSD/DC09";
                returnNewObj["supplying_dc"] = "NA";
              }
              dataSites.push(returnNewObj);
            }
          });
          this.storeData = dataSites;
          this.loaderState = false;
          this.getFilterDataOptions();
        }
      }, 500);
    },
    addDataToColumns(storeData) {
      var newObjSites = {};
      newObjSites["store_id"] = storeData.store_id;
      newObjSites["corprate_tier"] = storeData.corprate_tier;
      newObjSites["region"] = storeData.region;
      newObjSites["sales_district"] = storeData.sales_district;
      newObjSites["state"] = storeData.state;
      newObjSites["city"] = storeData.city;
      newObjSites["postal_code"] = storeData.postal_code;
      newObjSites["supplyingSource"] = storeData.supplyingSource;
      newObjSites["supplying_dc"] = storeData.supplying_dc;
      return newObjSites;
    },
    onSelectionChanged(selectedData) {
      (this.selectedStoresArticleId = []),
        selectedData.forEach(element => {
          this.selectedStoresArticleId.push(element.data.articleID);
        });
    },
    deleteByPastingStoreIDinFilterFromExclusionTable(Ids) {
      this.selectionFilterFlag = true;
      this.selectedOption = "A";
      if (Ids.onExclude) {
        this.IDstoExclude = Ids.productIds;
        this.excludeStoreIds(Ids.onExclude);
      } else {
        this.IDstoExclude = Ids;
        this.excludeStoreIds();
      }
    },
    excludeStoreIds(flag) {
      this.storeData = [];
      this.onError = false;
      let finalData = {
        existingData: [],
        removedData: []
      };
      let exclusiomArr = [];
      let ProIds = [];
      if (flag) {
        this.popoverShow = false;
        if (this.selectedOptionsForReIncludeAndExclude == "LM's") {
          ProIds = this.IDstoExclude.toUpperCase()
            .trim()
            .split(/[\s,]+/);
        } else {
          ProIds = this.IDstoExclude.trim().split(/[\s,]+/);
        }
      } else {
        ProIds = this.IDstoExclude;
      }
      if (this.selectedOptionsForReIncludeAndExclude == "Stores") {
        this.getJsonData.sites.forEach(element => {
          if (includes(this.selectedStoresArticleId, element.ArticleId)) {
            if (this.selectedOption == "A") {
              finalData.removedData = filter(element.Selections, v =>
                includes(ProIds, v.store_id)
              );
              finalData.existingData = filter(
                element.Selections,
                v => !includes(ProIds, v.store_id)
              );
              element.Selections = finalData.existingData;
              element.excusions = concat(
                element.excusions,
                finalData.removedData
              );
              exclusiomArr = element.excusions;
            } else {
              finalData.removedData = filter(
                element.excusions,
                v => !includes(ProIds, v.store_id)
              );
              finalData.existingData = concat(
                element.Selections,
                filter(element.excusions, v => includes(ProIds, v.store_id))
              );
              element.Selections = finalData.existingData;
              element.excusions = finalData.removedData;
              exclusiomArr = element.excusions;
            }
          }
        });
      } else if (this.selectedOptionsForReIncludeAndExclude == "LM's") {
        this.getJsonData.sites.forEach(element => {
          if (includes(this.selectedStoresArticleId, element.ArticleId)) {
            if (this.selectedOption == "A") {
              finalData.removedData = filter(element.Selections, v =>
                includes(ProIds, v.layout_module)
              );
              finalData.existingData = filter(
                element.Selections,
                v => !includes(ProIds, v.layout_module)
              );
              element.Selections = finalData.existingData;
              element.excusions = concat(
                element.excusions,
                finalData.removedData
              );
              exclusiomArr = element.excusions;
            } else {
              finalData.removedData = filter(
                element.excusions,
                v => !includes(ProIds, v.layout_module)
              );
              finalData.existingData = concat(
                element.Selections,
                filter(element.excusions, v =>
                  includes(ProIds, v.layout_module)
                )
              );
              element.Selections = finalData.existingData;
              element.excusions = finalData.removedData;
              exclusiomArr = element.excusions;
            }
          }
        });
      } else if (this.selectedOptionsForReIncludeAndExclude == "AVL") {
        this.getJsonData.sites.forEach(element => {
          if (includes(this.selectedStoresArticleId, element.ArticleId)) {
            if (this.selectedOption == "A") {
              finalData.removedData = filter(element.Selections, v =>
                includes(ProIds, v.avl)
              );
              finalData.existingData = filter(
                element.Selections,
                v => !includes(ProIds, v.avl)
              );
              element.Selections = finalData.existingData;
              element.excusions = concat(
                element.excusions,
                finalData.removedData
              );
              exclusiomArr = element.excusions;
            } else {
              finalData.removedData = filter(
                element.excusions,
                v => !includes(ProIds, v.avl)
              );
              finalData.existingData = concat(
                element.Selections,
                filter(element.excusions, v => includes(ProIds, v.avl))
              );
              element.Selections = finalData.existingData;
              element.excusions = finalData.removedData;
              exclusiomArr = element.excusions;
            }
          }
        });
      } else {
        this.getJsonData.sites.forEach(element => {
          if (includes(this.selectedStoresArticleId, element.ArticleId)) {
            if (this.selectedOption == "A") {
              finalData.removedData = filter(element.Selections, v =>
                includes(ProIds, v.corporateTier)
              );
              finalData.existingData = filter(
                element.Selections,
                v => !includes(ProIds, v.corporateTier)
              );
              element.Selections = finalData.existingData;
              element.excusions = concat(
                element.excusions,
                finalData.removedData
              );
              exclusiomArr = element.excusions;
            } else {
              finalData.removedData = filter(
                element.excusions,
                v => !includes(ProIds, v.corporateTier)
              );
              finalData.existingData = concat(
                element.Selections,
                filter(element.excusions, v =>
                  includes(ProIds, v.corporateTier)
                )
              );
              element.Selections = finalData.existingData;
              element.excusions = finalData.removedData;
              exclusiomArr = element.excusions;
            }
          }
        });
      }
      if (
        this.selectedOption === "A" &&
        finalData.removedData.length !== ProIds.length
      ) {
        this.onError = true;
      }
      if (this.selectionFilterFlag) {
        this.exclusionTable(exclusiomArr);
      }
      let disableFlag = isEqual(
        this.getTempJsonData.sites,
        this.getJsonData.sites
      );
      this.$root.$emit("step3Flag", disableFlag);
      this.forceRerender = !this.forceRerender;
    },
    exclusionTable(val) {
      let jp = require("jsonpath");
      let dataSites = [];
      let avlLocal = "";
      let lmLocal = "";
      let storeId = "";
      val.forEach(e => {
        storeId = e.store_id;
        avlLocal = e.avl;
        lmLocal = e.layout_module;
        var storeData = this.getSitesByIds.find(v => {
          return v.store_id === storeId;
        });
        let returnNewObj = this.addDataToColumns(storeData);
        returnNewObj["articleID"] = this.selectedStoresArticleId[0];
        returnNewObj["avl"] = avlLocal;
        returnNewObj["layout_module"] = lmLocal;
        dataSites.push(returnNewObj);
      });
      this.storeData = dataSites;
      this.forceRerender = !this.forceRerender;
    }
  }
};
</script>

<style scoped>
.fa,
.fas {
  font-weight: 900;
  color: royalblue;
  padding-left: 5px;
}
.col-md-6 {
  padding-left: 0px !important;
}
</style>
<style>
.btn {
  font-size: 14px !important;
  border-color: #76bf00 !important;
}
.popover {
  max-width: 50% !important;
}
</style>