<template>
  <div>
    <vue-element-loading
      width="200"
      :active="getLoader"
      spinner="bar-fade-scale"
      :text="getLoaderText || 'This step may take some time. Thank you for your patience.'"
    />
    <form-wizard
      :title="null"
      ref="formWizard"
      @on-error="handleErrorMessage"
      :subtitle="null"
      color="rgb(115, 189, 0)"
      @on-change="onStepChange"
      @on-complete="onComplete"
      :start-index="stepStartIndex"
      back-button-text="Previous"
      :next-button-text="nextButtonText"
      stepSize="sm"
    >
      <div class="AllocationDetails" v-if="getJsonData && stepIndex != 5">
        <b-row>
          <b-col cols="3">
            <div>
              <strong>Name:</strong>
              {{ getJsonData.allocationBasics.name }}
            </div>
          </b-col>
          <b-col cols="2">
            <div>
              <strong>Push:</strong>
              {{ getJsonData.allocationBasics.push }}
            </div>
          </b-col>
          <b-col cols="3">
            <div>
              <strong>Created At:</strong>
              {{ format(getJsonData.allocationBasics.createdAt, "MM/DD/YYYY") }}
            </div>
          </b-col>
          <b-col cols="4">
            <div>
              <strong>File:</strong>
              {{ getJsonData.allocationBasics.fileName }}
            </div>
          </b-col>
        </b-row>
      </div>
      <tab-content title="Allocation Basics" :before-change="beforeStep2">
        <allocation-basics
          v-if="stepIndex === 0"
          :basicsForm="basicsform"
          :allocationId="allocationId"
        ></allocation-basics>
      </tab-content>
      <tab-content title="Article Selection" :before-change="beforeStep3">
        <article-selection
          :isStep2ForceRenderer="isStep2ForceRenderer"
          v-if="stepIndex === 1"
          :allocationId="allocationId"
        ></article-selection>
      </tab-content>
      <tab-content title="Site Exclusion" :before-change="beforeStep4">
        <site-selection
          :isStep3ForceRenderer="isStep3ForceRenderer"
          v-if="stepIndex === 2"
          :allocationId="allocationId"
        ></site-selection>
      </tab-content>
      <tab-content title="Strategy" :before-change="beforeStep5">
        <strategy
          v-if="stepIndex === 3"
          :key="calSumValue"
          @PAYLOAD_GENERATED="payloadGenerated = true"
          :allocationId="allocationId"
          :calSum1="calSum1"
          :calSum2="calSum2"
          :isStep4ForceRenderer="isStep4ForceRenderer"
          :salesHistoryForm="salesHistoryForm"
        ></strategy>
      </tab-content>
      <tab-content title="Finalize Allocation" :before-change="beforeStep6">
        <finalize-allocation
          :isStep5ForceRenderer="isStep5ForceRenderer"
          v-if="stepIndex === 4"
          :allocationId="allocationId"
          :updatePayloadForR="updatePayloadForRForFinallizeAllocation"
        ></finalize-allocation>
      </tab-content>
      <tab-content title="Report" :before-change="beforeStep7">
        <allocation-report
          :Reports_Joann1="Reports_Joann"
          v-if="stepIndex === 5"
          :allocationId="allocationId"
        ></allocation-report>
      </tab-content>
      <template slot="footer" slot-scope="props">
        <div class="wizard-footer-left">
          <wizard-button
            v-if="stepIndex != 0"
            @click.native="props.prevTab()"
            class="btn-save"
            >Previous</wizard-button
          >
        </div>
        <div class="wizard-footer-right">
          <!-- <wizard-button class="btn-save" v-if="!payloadGenerated" :disabled="true"><b-spinner small type="grow" label="Loading..."/> Preparing Data</wizard-button> -->
          <wizard-button
            id="step6NextButton"
            v-if="stepIndex === 5"
            @click.native="props.nextTab()"
            class="wizard-footer-right btn-save"
            >Finish</wizard-button
          >
          <wizard-button
            id="step5NextButton"
            v-if="stepIndex === 4"
            @click.native="props.nextTab()"
            class="wizard-footer-right btn-save"
            >Next</wizard-button
          >
          <wizard-button
            id="step4NextButton"
            v-if="stepIndex === 3"
            @click.native="props.nextTab()"
            class="wizard-footer-right btn-save"
            >Run Algorithm</wizard-button
          >
          <wizard-button
            id="step3NextButton"
            v-if="stepIndex === 2"
            :disabled="ifStep3NextEnable"
            @click.native="props.nextTab()"
            class="wizard-footer-right btn-save"
            >Next</wizard-button
          >
          <wizard-button
            id="step2NextButton"
            :disabled="ifStep2NextEnable"
            v-if="stepIndex === 1"
            @click.native="props.nextTab()"
            class="wizard-footer-right btn-save"
            >Next</wizard-button
          >
          <wizard-button
            id="step1NextButton"
            v-if="stepIndex === 0"
            @click.native="callStep1SaveDetails"
            class="wizard-footer-right btn-save"
            >Next</wizard-button
          >
          <wizard-button
            style="margin-right: 5px"
            v-if="props.activeTabIndex === 1"
            class="btn-save"
            :disabled="step2Flag"
            @click.native="callStep2SaveDetails()"
            >Save</wizard-button
          >
          <wizard-button
            style="margin-right: 5px"
            v-if="props.activeTabIndex === 2"
            class="btn-save"
            :disabled="step3Flag"
            @click.native="callStep3SaveDetails()"
            >Save</wizard-button
          >
          <wizard-button
            v-if="props.activeTabIndex === 3"
            class="btn-save"
            :disabled="step4Flag"
            @click.native="callStep4SaveDetails()"
            style="margin-right: 5px"
            >Save</wizard-button
          >
          <wizard-button
            style="margin-right: 5px"
            v-if="props.activeTabIndex === 4"
            class="btn-save"
            :disabled="step5Flag"
            @click.native="callStep5SaveDetails()"
            >Save</wizard-button
          >
        </div>
      </template>
      <!-- <template slot="custom-buttons-right" slot-scope="props">
        
        
      </template>-->
      <!-- <b-alert v-model="errorMsgFlag" variant="danger" dismissible>!! {{errorMesg}}</b-alert> -->
      <!-- <div v-if="errorMsg" style="text-align: center;">
        <span class="error" style="color: red;">{{errorMsg}}</span>
      </div>-->
    </form-wizard>
    <!-- <b-modal v-model="confirmModal" centered title="BootstrapVue" no-close-on-backdrop no-close-on-esc hide-header-close @ok="onOkClick" @cancel="onOkClickFlag = false">
      <p class="my-4">Vertically centered modal!</p>
    </b-modal>-->
  </div>
</template>

<script>
import { format } from "date-fns";
import Strategy from "@/components/CreateAllocation/Step4Strategy";
import AllocationBasics from "@/components/CreateAllocation/Step1AllocationBasics";
import { FormWizard, TabContent } from "vue-form-wizard";
import ArticleSelection from "@/components/CreateAllocation/Step2ArticleSelection";
import SiteSelection from "@/components/CreateAllocation/Step3SiteSelection";
import FinalizeAllocation from "@/components/CreateAllocation/Step5FinalizeAllocation";
import AllocationReport from "@/components/CreateAllocation/Step6Report";
import { isEqual, cloneDeep, pick, filter, includes } from "lodash";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

import { mapGetters, mapActions, mapMutations } from "vuex";
import { debug, debuglog } from "util";

export default {
  data() {
    return {
      isStep5ForceRenderer: false,
      isStep4ForceRenderer: false,
      isStep3ForceRenderer: false,
      isStep2ForceRenderer: false,
      ifStep2NextEnable: false,
      ifStep3NextEnable: false,
      isStep1Validated: true,
      step4Flag: true,
      step3Flag: true,
      step2Flag: true,
      step5Flag: true,
      flagForCalSum: {
        flagSelectedMerchSalesHistoryByDC: false,
        flagSelectedMerchSalesHistoryByDCDash: false,
      },
      calSum1: false,
      calSum2: false,
      message: "This step may take some time. Thank you for your patience.",
      format,
      isEqual,
      Reports_Joann: [],
      siteData: [],
      prevState: true,
      confirmModal: false,
      onOkClickFlag: false,
      salesHistoryForm: {
        articleOwnSalesHistorys: false,
        targrtAllocationUnit: null,
        targrtAllocationPer: null,
        // saleHistoryEndDate: "",
        // saleHistoryStartDate: ""
        saleHistoryEndDate1: "",
        saleHistoryStartDate1: "",
        saleHistoryEndDate2: "",
        saleHistoryStartDate2: "",
      },
      dialogOptions: {
        cancelText: "Reset",
        animation: "bounce",
        backdropClose: true,
      },
      errorMesg: null,
      errorMsgFlag: null,
      count: 0,
      stepIndex: null,
      payloadGenerated: false,
    };
  },
  components: {
    FormWizard,
    TabContent,
    Strategy,
    AllocationBasics,
    ArticleSelection,
    SiteSelection,
    FinalizeAllocation,
    AllocationReport,
  },
  computed: {
    calSumValue() {
      return this.flagForCalSum
        ? this.flagForCalSum.flagSelectedMerchSalesHistoryByDC
        : this.flagForCalSum.flagSelectedMerchSalesHistoryByDCDash;
    },
    ...mapGetters({
      getLoader: "CreateAllocation/getLoader",
      getLoaderText: "CreateAllocation/getLoaderText",
      getJsonData: "CreateAllocation/getJsonData",
      getTempJsonData: "CreateAllocation/getTempJsonData",
    }),
    allocationId() {
      return this.$route.params.allocationId;
    },
    basicsform() {
      let obj = {
        name: "",
        programName: "BCTW1 Disposable Bakeware FW47",
        mmName: "A. Chicatelli",
        push: "",
        file: [],
        fileName: "",
        allocationStage: "",
        comments: "",
        toBeLinked: false,
        toBeOverridden: true,
      };
      if (this.getJsonData && this.getJsonData.allocationBasics) {
        obj.name = this.getJsonData.allocationBasics.name;
        obj.comments = this.getJsonData.allocationBasics.comments;
        obj.fileName = this.getJsonData.allocationBasics.fileName;
        obj.push = this.getJsonData.allocationBasics.push;
      }
      return obj;
    },
    isAllocation() {
      if (this.allocationId) {
        return this.allocationId;
      } else {
        return "";
      }
    },
    nextButtonText() {
      if (this.stepIndex === 3 && this.payloadGenerated === true) {
        return "Run Algorithm";
      } else if (this.stepIndex === 3 && this.payloadGenerated === false) {
        return "Preparing Data";
      } else {
        return "Next";
      }
    },
  },
  props: ["stepStartIndex"],
  watch: {
    stepStartIndex(newVal, oldVal) {
      this.stepIndex = newVal;
      this.$refs.formWizard.changeTab(0, newVal);
    },
  },
  mounted() {
    console.log(this);
  },
  // mounted() {

  //   // this.calSum2 = this.createAllocationCallSum2
  //   if(parseInt(this.stepStartIndex) >= 5) {
  //     this.calSum2 = true
  //     this.flagForCalSum.flagSelectedMerchSalesHistoryByDC = true
  //   }
  // },
  created() {
    this.$root.$on("step4Flag", this.flagSetStep4);
    this.$root.$on("step3Flag", this.flagSetStep3);
    this.$root.$on("step2Flag", this.flagSetStep2);
    this.$root.$on("step5Flag", this.flagSetStep5);
    this.$root.$on("goToTabOne", () => {
      this.$refs.formWizard.changeTab(4, 0);
    });

    let siteDataTemp = window.localStorage.getItem("siteData");
    this.siteData = JSON.parse(siteDataTemp);
    if (this.getJsonData && this.getJsonData.articles.selections.length < 0) {
      this.ifStep2NextEnable = true;
    } else {
      this.ifStep2NextEnable = false;
    }
  },
  methods: {
    ...mapMutations({
      setLoader: "CreateAllocation/setLoader",
      setLoaderText: "CreateAllocation/setLoaderText",
      setJsonData: "CreateAllocation/setJsonData",
    }),
    callStep1SaveDetails() {
      let context = this;
      this.isStep1Validated = true;
      if (
        this.basicsform.name === "" ||
        this.basicsform.programName === "" ||
        this.basicsform.mmName === "" ||
        this.basicsform.push === "" ||
        (this.allocationId
          ? this.basicsform.fileName === ""
          : this.basicsform.file === "")
      ) {
        this.isStep1Validated = false;
        Swal.fire({
          text: "Please enter required(*) fields.",
          icon: "warning",
          confirmButtonText: "Ok",
        });
        this.$refs.formWizard.nextTab();
        return;
      } else {
        if (this.allocationId) {
          this.getTempJsonData.allocationBasics.name = this.basicsform.name;
          this.getTempJsonData.allocationBasics.comments = this.basicsform.comments;
          this.getTempJsonData.allocationBasics.fileName = this.basicsform.fileName;
          this.getTempJsonData.allocationBasics.file = this.basicsform.file;
          if (
            !isEqual(
              this.getTempJsonData.allocationBasics,
              this.getJsonData.allocationBasics
            )
          ) {
            let payload = {};
            payload.allocationID = this.allocationId;

            if (
              this.getTempJsonData.allocationBasics.name ==
                this.getJsonData.allocationBasics.name &&
              this.getTempJsonData.allocationBasics.comments ==
                this.getJsonData.allocationBasics.comments &&
              !(this.basicsform.file && this.basicsform.file.name)
            ) {
              this.isStep1Validated = true;
              this.$refs.formWizard.nextTab();
              return;
            }

            if (
              this.getTempJsonData.allocationBasics.name !=
              this.getJsonData.allocationBasics.name
            ) {
              payload.name = this.basicsform.name;
            }

            if (
              this.getTempJsonData.allocationBasics.comments !=
              this.getJsonData.allocationBasics.comments
            ) {
              payload.comments = this.basicsform.comments;
            }

            if (this.basicsform.file && this.basicsform.file.name) {
              payload.file = this.basicsform.file;
              payload.fileName = this.basicsform.file.name;
            }

            if (Array.isArray(payload.file) || !payload.file) {
              delete payload.file;
              delete payload.fileName;
            }
            let updateAllocationBasics = () => {
              this.updateAllocationBasics(payload)
                .then((res) => (this.isStep1Validated = true))
                .catch((err) => {
                  this.isStep1Validated = false;
                  Swal.fire({
                    text: err.message,
                    icon: "warning",
                    confirmButtonText: "Ok",
                  });
                })
                .finally(() => {
                  this.$root.$emit("clearUploadBar");
                  this.$refs.formWizard.nextTab();
                });
            };

            if (this.basicsform.file && this.basicsform.file.name) {
              this.$dialog
                .confirm(
                  "Are you really want to update the template ? By clicking on continue your data will be clean from further screens."
                )
                .then((res) => updateAllocationBasics());
            } else {
              updateAllocationBasics();
            }
          } else if (
            this.allocationId &&
            isEqual(
              this.getTempJsonData.allocationBasics,
              this.getJsonData.allocationBasics
            )
          ) {
            this.isStep1Validated = true;
            this.$refs.formWizard.nextTab();
          }
        } else {
          context
            .createAllocationStep1Next(context.basicsform)
            .then((resp) => {
              context.$router.push(
                `/createAllocation/${resp.allocationBasics.allocationID}`
              );
              context.stepIndex = 1;
              this.isStep1Validated = true;
            })
            .catch((e) => {
              Swal.fire({
                text: e.message,
                icon: "warning",
                confirmButtonText: "Ok",
              });
              this.isStep1Validated = false;
              if (e.template_error) {
                let file = document.getElementById("buyUploadFile");
                file.value = "";
              }
            })
            .finally(() => this.$refs.formWizard.nextTab());
        }
      }
    },
    flagSetStep4(val) {
      this.step4Flag = val;
    },
    flagSetStep3(val) {
      this.step3Flag = val;
      let flag = true;
      this.getJsonData.sites.forEach((obj) => {
        if (obj.Selections.length != 0) {
          flag = false;
        }
      });
      this.ifStep3NextEnable = flag;
    },
    flagSetStep5(val) {
      this.step5Flag = val;
    },
    flagSetStep2(val) {
      this.step2Flag = val;
      if (this.getJsonData.articles.selections.length > 0) {
        this.ifStep2NextEnable = false;
      } else this.ifStep2NextEnable = true;
    },
    getNextState() {
      let flag = true;
      this.getJsonData.sites.forEach((ele) => {
        if (ele.Selections.length) {
          flag = false;
        }
      });
      return flag;
    },
    getReportAllocation() {
      this.Reports_Joann = [];
      // let siteDataTemp = window.localStorage.getItem("siteData");
      // var siteData = JSON.parse(siteDataTemp)
      // let lenSelSite = siteData.length
      // let siteSelected = {}
      // for (let i = 0; i < lenSelSite; i++) {
      //   siteSelected[Number(siteData[i].store_id)] = siteData[i]
      // }
      const sites = this.getJsonData.sites;
      const len1 = sites.length;
      let articleSites = {};
      for (let i = 0; i < len1; i++) {
        const site = sites[i];
        const len2 = site.Selections.length;
        const Selections = site.Selections;
        for (let j = 0; j < len2; j++) {
          const Selection = Selections[j];
          const storeID = Number(Selection.store_id);
          articleSites[Number(site.ArticleId) + "-" + storeID] = Number(
            Selection.avl
          );
        }
      }
      this.getJsonData.finalizeAllocation.forEach((element) => {
        var NewObj = {};
        const aid = Number(element["Article ID"]);
        const sid = Number(element["Store ID"]);
        NewObj["Store_ID"] = sid;
        NewObj["Product_ID"] = aid;
        NewObj["OH"] = parseFloat(element["Store OH"]);
        NewObj["OO"] = parseFloat(element["Store OO"]);
        NewObj["Current_Allocation"] = element["Allocation"];
        NewObj["Sales_Basis_1_SD"] = "1970-01-01";
        NewObj["Sales_Basis_1_ED"] = "1970-01-01";
        NewObj["Sales_Basis_2_SD"] = "1970-01-01";
        NewObj["Sales_Basis_2_ED"] = "1970-01-01";
        NewObj["Merch_cat"] = element.merch_cat_descr;
        NewObj["Category"] = element.category_descr;
        // let siteObj = siteSelected[Number(element['Store ID'])]
        // var templeteData = tempSiteData.find(v => {
        //   return v.store_id === element["Store ID"];
        // });
        NewObj["Tier"] = articleSites[aid + "-" + sid];
        this.Reports_Joann.push(NewObj);
      });
      this.AllocationReport({
        Reports_Joann: this.Reports_Joann,
        allocationID: this.getJsonData.allocationBasics.allocationID,
      });
    },
    updatePayloadForR(jsonData) {
      return new Promise((resolve) => {
        if (!!window.Worker) {
          const payloadWorker = new Worker("PayloadCreatorDedicatedWorker.js");

          payloadWorker.postMessage(jsonData);
          const _this = this;
          payloadWorker.onmessage = function (e) {
            if (typeof e.data !== "undefined") {
              _this.setJsonData(e.data);
              resolve(e.data);
            }
          };
        }
      });
    },
    ...mapActions({
      createAllocationStep1Next: "CreateAllocation/createAllocationStep1Next",
      updateAllocationBasics: "CreateAllocation/updateAllocationBasics",
      CalSumOfQtySold: "CreateAllocation/CalSumOfQtySold",
      actionGetStaticData: "CreateAllocation/actionGetStaticData",
      saveAllocationData: "CreateAllocation/saveAllocationData",
      saveAllocationDetails: "CreateAllocation/saveAllocationDetails",
      dashboardTable: "Dashboard/dashboardTable",
      AllocationReport: "CreateAllocation/AllocationReport",
      checkRefAndPoissonDistributionHasUpdate:
        "CreateAllocation/checkRefAndPoissonDistributionHasUpdate",
    }),
    callStep2SaveDetails() {
      this.step2Flag = true;
      this.getJsonData.articles.selections.forEach((element) => {
        this.getJsonData.allocationTemplateFileContents.forEach((obj) => {
          if (obj.articleID === element.article) {
            obj.seasonStartFiscalWeek = element.seasonStartFiscalWeek;
          }
        });
      });
      if (this.getJsonData.sites) {
        if (
          !isEqual(this.getTempJsonData.articles, this.getJsonData.articles)
        ) {
          let context = this;
          context.$dialog
            .confirm(
              "Next steps data will be lost. Do you want to Save?",
              this.dialogOptions
            )
            .then(() => {
              context.getJsonData.allocationBasics.allocationStage = "2";
              let jsonData = pick(context.getJsonData, [
                "allocationTemplateFileContents",
                "allocationBasics",
                "articles",
              ]);
              context
                .saveAllocationDetails({
                  allocationID:
                    context.getJsonData.allocationBasics.allocationID,
                  jsonData,
                })
                .then((resp) => {
                  context.$refs.formWizard.reset();
                  context.$refs.formWizard.changeTab(0, 1);
                })
                .catch((e) => {
                  reject(e.message);
                });
            })
            .catch(function () {
              context.isStep2ForceRenderer = !context.isStep2ForceRenderer;
              context.getJsonData.articles = cloneDeep(
                context.getTempJsonData.articles
              );
              reject();
            });
        }
      } else {
        this.getJsonData.allocationBasics.allocationStage = "2";
        // this.getJsonData.allocationTemplateFileContents.forEach(obj => {
        //   if (obj.articleID === element.data.article) {
        //     obj.seasonStartFiscalWeek = element.data.seasonStartFiscalWeek
        //   }
        // })
        this.saveAllocationDetails({
          allocationID: this.getJsonData.allocationBasics.allocationID,
          jsonData: this.getJsonData,
        })
          .then((resp) => {
            resolve(true);
          })
          .catch((e) => {
            reject(e.message);
          });
      }

      this.$gtag.event('Clicked_ArticleSelectionScreenSaveButton', {
        'event_category': 'Article Selection Screen',
        'event_label': 'Click on Save button',
      })
    },
    callStep3SaveDetails() {
      this.step3Flag = true;
      if (this.getJsonData.strategy) {
        if (!isEqual(this.getTempJsonData.sites, this.getJsonData.sites)) {
          let context = this;
          context.$dialog
            .confirm(
              "Next steps data will be lost. Do you want to Save?",
              this.dialogOptions
            )
            .then(() => {
              context.getJsonData.allocationBasics.allocationStage = "3";
              let jsonData = pick(context.getJsonData, [
                "allocationTemplateFileContents",
                "allocationBasics",
                "articles",
                "sites",
              ]);
              context
                .saveAllocationDetails({
                  allocationID:
                    context.getJsonData.allocationBasics.allocationID,
                  jsonData,
                })
                .then((resp) => {
                  context.$refs.formWizard.reset();
                  context.$refs.formWizard.changeTab(0, 2);
                })
                .catch((e) => {
                  reject(e.message);
                });
            })
            .catch(function () {
              context.isStep3ForceRenderer = !context.isStep3ForceRenderer;
              context.getJsonData.sites = cloneDeep(
                context.getTempJsonData.sites
              );
              reject();
            });
        }
      } else {
        this.getJsonData.allocationBasics.allocationStage = "3";
        this.saveAllocationDetails({
          allocationID: this.getJsonData.allocationBasics.allocationID,
          jsonData: this.getJsonData,
        })
          .then((resp) => {
            resolve(true);
          })
          .catch((e) => {
            reject(e.message);
          });
      }

      this.$gtag.event('Clicked_SiteExclusionScreenSaveButton', {
        'event_category': 'Site Exclusion Screen',
        'event_label': 'Click on Save button',
      })
    },
    callStep4SaveDetails() {
      this.step4Flag = true;
      if (this.getJsonData.finalizeAllocation) {
        if (
          !isEqual(this.getTempJsonData.strategy, this.getJsonData.strategy)
        ) {
          let context = this;
          context.$dialog
            .confirm(
              "Next steps data will be lost. Do you want to Save?",
              this.dialogOptions
            )
            .then(() => {
              context.getJsonData.allocationBasics.allocationStage = "4";
              let jsonData = pick(context.getJsonData, [
                "allocationTemplateFileContents",
                "allocationBasics",
                "articles",
                "sites",
                "strategy",
                "Allocation_JoAnn",
              ]);
              context
                .saveAllocationDetails({
                  allocationID:
                    context.getJsonData.allocationBasics.allocationID,
                  jsonData,
                })
                .then((resp) => {
                  context.$refs.formWizard.reset();
                  context.$refs.formWizard.changeTab(0, 3);
                })
                .catch((e) => {
                  reject(e.message);
                });
            })
            .catch(function () {
              context.isStep4ForceRenderer = !context.isStep4ForceRenderer;
              context.getJsonData.strategy = cloneDeep(
                context.getTempJsonData.strategy
              );
              reject();
            });
        }
      } else {
        this.getJsonData.allocationBasics.allocationStage = "4";
        this.saveAllocationDetails({
          allocationID: this.getJsonData.allocationBasics.allocationID,
          jsonData: this.getJsonData,
        })
          .then((resp) => {
            resolve(true);
          })
          .catch((e) => {
            reject(e.message);
          });
      }

      this.$gtag.event('Clicked_StrategyScreenSaveButton', {
        'event_category': 'Strategy Screen',
        'event_label': 'Click on Save Button',
      })
    },
    callStep5SaveDetails() {
      this.step5Flag = true;
      if (parseInt(this.getJsonData.allocationBasics.allocationStage) > 5) {
        if (
          !isEqual(
            this.getTempJsonData.finalizeAllocation,
            this.getJsonData.finalizeAllocation
          )
        ) {
          let context = this;
          context.$dialog
            .confirm(
              "Next steps data will be lost. Do you want to Save?",
              this.dialogOptions
            )
            .then(() => {
              context.getJsonData.allocationBasics.allocationStage = "5";
              let jsonData = pick(context.getJsonData, [
                "allocationTemplateFileContents",
                "allocationBasics",
                "articles",
                "sites",
                "strategy",
                "finalizeAllocation",
                "Allocation_JoAnn",
              ]);
              context
                .saveAllocationDetails({
                  allocationID:
                    context.getJsonData.allocationBasics.allocationID,
                  jsonData,
                })
                .then((resp) => {
                  context.$refs.formWizard.reset();
                  context.$refs.formWizard.changeTab(0, 4);
                  // if (!this.getJsonData.getDcLevelData) {
                  //   this.updateDcLevelData();
                  // }
                })
                .catch((e) => {
                  reject(e.message);
                });
            })
            .catch(function () {
              context.isStep5ForceRenderer = !context.isStep5ForceRenderer;
              context.getJsonData.finalizeAllocation = cloneDeep(
                context.getTempJsonData.finalizeAllocation
              );
              reject();
            });
        }
      } else {
        this.getJsonData.allocationBasics.allocationStage = "5";
        this.saveAllocationDetails({
          allocationID: this.getJsonData.allocationBasics.allocationID,
          jsonData: this.getJsonData,
        })
          .then((resp) => {
            resolve(true);
          })
          .catch((e) => {
            reject(e.message);
          });
      }

      this.$gtag.event('Clicked_FinalizeAllocationScreenSaveButton', {
        'event_category': 'Finalize Allocation Screen',
        'event_label': 'Click on Save button',
      })
    },
    onComplete() {
      this.getJsonData.allocationBasics.allocationStage = "6";
      this.saveAllocationData({
        allocationID: this.getJsonData.allocationBasics.allocationID,
        jsonData: this.getJsonData,
      });
      this.dashboardTable();

      this.$gtag.event('Clicked_ReportScreenSaveButton', {
        'event_category': 'Report Screen',
        'event_label': 'Click on Report Screen Save button',
      })
    },
    beforeStep2() {
      return this.isStep1Validated;
    },
    beforeStep3() {
      this.step2Flag = true;
      if (this.getJsonData.sites) {
        if (
          !isEqual(this.getTempJsonData.articles, this.getJsonData.articles)
        ) {
          let context = this;
          return new Promise((resolve, reject) => {
            context.$dialog
              .confirm(
                "Next steps data will be lost. Do you want to proceed?",
                this.dialogOptions
              )
              .then(function (dialog) {
                context.getJsonData.allocationBasics.allocationStage = "2";
                let jsonData = pick(context.getJsonData, [
                  "allocationTemplateFileContents",
                  "allocationBasics",
                  "articles",
                ]);
                context
                  .saveAllocationData({
                    allocationID:
                      context.getJsonData.allocationBasics.allocationID,
                    jsonData,
                  })
                  .then((resp) => {
                    context.$refs.formWizard.reset();
                    context.$refs.formWizard.changeTab(0, 1);
                    resolve(true);
                  })
                  .catch((e) => {
                    reject(e.message);
                  });
              })
              .catch(function () {
                context.isStep2ForceRenderer = !context.isStep2ForceRenderer;
                context.getJsonData.articles = cloneDeep(
                  context.getTempJsonData.articles
                );
                reject();
              });
          });
        } else {
          return true;
        }
      } else {
        let context = this;
        return new Promise((resolve, reject) => {
          context.getJsonData.allocationBasics.allocationStage = "2";
          context
            .saveAllocationData({
              allocationID: context.getJsonData.allocationBasics.allocationID,
              jsonData: context.getJsonData,
            })
            .then((resp) => {
              resolve(true);
            })
            .catch((e) => {
              reject(e.message);
            });
        });
      }
    },
    beforeStep4() {
      this.step3Flag = true;
      this.calSum2 = false;
      this.flagForCalSum.flagSelectedMerchSalesHistoryByDC = !this.flagForCalSum
        .flagSelectedMerchSalesHistoryByDC;
      if (this.getJsonData.strategy) {
        if (!isEqual(this.getTempJsonData.sites, this.getJsonData.sites)) {
          let context = this;
          return new Promise((resolve, reject) => {
            context.$dialog
              .confirm(
                "Next steps data will be lost. Do you want to proceed?",
                this.dialogOptions
              )
              .then(function (dialog) {
                context.getJsonData.allocationBasics.allocationStage = "3";
                let jsonData = pick(context.getJsonData, [
                  "allocationTemplateFileContents",
                  "allocationBasics",
                  "articles",
                  "sites",
                ]);
                context
                  .saveAllocationData({
                    allocationID:
                      context.getJsonData.allocationBasics.allocationID,
                    jsonData,
                  })
                  .then((resp) => {
                    context.$refs.formWizard.reset();
                    context.$refs.formWizard.changeTab(0, 2);
                    resolve(true);
                  })
                  .catch((e) => {
                    reject(e.message);
                  });
              })
              .catch(function () {
                context.isStep3ForceRenderer = !context.isStep3ForceRenderer;
                context.getJsonData.sites = cloneDeep(
                  context.getTempJsonData.sites
                );
                reject();
              });
          });
        } else {
          return true;
        }
      } else {
        let context = this;
        return new Promise((resolve, reject) => {
          context.getJsonData.allocationBasics.allocationStage = "3";
          context
            .saveAllocationData({
              allocationID: context.getJsonData.allocationBasics.allocationID,
              jsonData: context.getJsonData,
            })
            .then((resp) => {
              resolve(true);
            })
            .catch((e) => {
              reject(e.message);
            });
        });
      }
    },
    checkUpdateRefIndex() {
      return new Promise((resolve, reject) => {
        if (isEqual(this.getTempJsonData.strategy, this.getJsonData.strategy)) {
          this.checkRefAndPoissonDistributionHasUpdate({
            updatedAt: this.getTempJsonData.allocationBasics.updatedAt,
          }).then((resp) => {
            if (resp.data.wasReferenceStoreMappingUpdated)
              resolve({
                status: "Proceed",
                msg:
                  "Ref Store/Index values has been changed. Next steps data will be lost. Do you want to proceed?",
              });
            else
              resolve({
                status: "No-Proceed",
                msg: "",
              });
          });
        } else {
          resolve({
            status: "Proceed",
            msg: "Next steps data will be lost. Do you want to proceed?",
          });
        }
      });
    },
    beforeStep5() {
      this.step4Flag = true;
      let checkFlag = true;
      if (this.getJsonData.finalizeAllocation) {
        return this.checkUpdateRefIndex().then((resp) => {
          if (resp.status === "Proceed") {
            let context = this;
            return new Promise((resolve, reject) => {
              context.$dialog
                .confirm(resp.msg, this.dialogOptions)
                .then(function (dialog) {
                  context
                    .updatePayloadForR()
                    .then(() => {
                      context.getJsonData.allocationBasics.allocationStage =
                        "4";
                      let jsonData = pick(context.getJsonData, [
                        "allocationTemplateFileContents",
                        "allocationBasics",
                        "articles",
                        "sites",
                        "strategy",
                        "Allocation_JoAnn",
                      ]);
                      context
                        .saveAllocationData({
                          allocationID:
                            context.getJsonData.allocationBasics.allocationID,
                          jsonData,
                        })
                        .then((resp) => {
                          context.$refs.formWizard.reset();
                          context.$refs.formWizard.changeTab(0, 3);
                          resolve(true);
                        })
                        .catch((e) => {
                          reject(e.message);
                        });
                    })
                    .catch((e) => {
                      reject(e);
                    });
                })
                .catch(function () {
                  context.isStep4ForceRenderer = !context.isStep4ForceRenderer;
                  context.getJsonData.strategy = cloneDeep(
                    context.getTempJsonData.strategy
                  );
                  reject();
                });
            });
          } else {
            return true;
          }
        });
      } else {
        let context = this;
        return new Promise((resolve, reject) => {
          context
            .updatePayloadForR()
            .then(() => {
              context.getJsonData.allocationBasics.allocationStage = "4";
              context
                .saveAllocationData({
                  allocationID:
                    context.getJsonData.allocationBasics.allocationID,
                  jsonData: context.getJsonData,
                })
                .then((resp) => {
                  resolve(true);
                })
                .catch((e) => {
                  reject(e.message);
                });
            })
            .catch(() => {
              reject();
            });
        });
      }
    },
    updatePayloadForRForFinallizeAllocation() {
      return new Promise(resolve => {
        let newRunAlgoPayload = [];
        let emptySaleAndEmptyTA = false;
        let emptyTaAndSale = false;
        this.getJsonData.strategy.forEach((element) => {
          let tempObj = {};
          if (
            !element.salesHistoryStartDate1 &&
            !element.salesHistoryEndDate1 &&
            !element.merchHierarchy1 &&
            !element.merchSelections1 &&
            (!element.targetAllocationPercentage ||
              !element.targetAllocationUnits)
          ) {
            emptySaleAndEmptyTA = true;
          }
          if (
            (!element.targetAllocationPercentage ||
              !element.targetAllocationUnits) &&
            element.salesHistoryStartDate1 &&
            element.salesHistoryEndDate1 &&
            element.merchHierarchy1 &&
            element.merchSelections1
          ) {
            emptyTaAndSale = true;
          }
          if (
            !element.salesHistoryStartDate2 &&
            !element.salesHistoryEndDate2 &&
            !element.merchHierarchy2 &&
            !element.merchSelections2 &&
            (!element.targetAllocationPercentage ||
              !element.targetAllocationUnits)
          ) {
            emptySaleAndEmptyTA = true;
          }
          if (
            (!element.targetAllocationPercentage ||
              !element.targetAllocationUnits) &&
            element.salesHistoryStartDate2 &&
            element.salesHistoryEndDate2 &&
            element.merchHierarchy2 &&
            element.merchSelections2
          ) {
            emptyTaAndSale = true;
          }
          tempObj = element;
          let articleObj = this.getJsonData.sites.find(
            (article) =>
              Number(article.ArticleId) === Number(element["productID"])
          );
          let storeIds = filter(articleObj.Selections, (v) =>
            includes(element.supplyingDC, v.dc)
          );
          let storeIdsTemp1 = {};
          let storeIdsTemp2 = {};
          let tempIndices = {};
          tempObj["referenceStores1"] = {};
          tempObj["referenceStores2"] = {};
          tempObj["indices"] = {};

          storeIds.forEach((ele) => {
            storeIdsTemp1[ele.store_id] = ele.store_id;
          });
          storeIds.forEach((ele) => {
            storeIdsTemp2[ele.store_id] = ele.store_id;
          });
          storeIds.forEach((ele) => {
            tempIndices[ele.store_id] = 1;
          });
          tempObj["referenceStores1"] = storeIdsTemp1;
          tempObj["referenceStores2"] = storeIdsTemp2;
          tempObj["indices"] = tempIndices;

          newRunAlgoPayload.push(tempObj);
        });
        this.CalSumOfQtySold({
          inputForSumOfQuantitySold: newRunAlgoPayload,
          weightNumber: 1,
        }).then((response) => {
          let newPayload = response.sumOfQuantitySold;
          this.CalSumOfQtySold({
            inputForSumOfQuantitySold: newPayload,
            weightNumber: 2,
          }).then((res) => {
            this.$root.$emit("forceRenderCalSumDat", true);
            {
              let flagDialog = false;
              res.sumOfQuantitySold.forEach((ele) => {
                if (
                  (ele.salesHistoryStartDate1 &&
                    ele.salesHistoryEndDate1 &&
                    ele.merchHierarchy1 &&
                    ele.weightBasis1 &&
                    ele.salesHistoryStartDate2 &&
                    ele.salesHistoryEndDate2 &&
                    ele.merchHierarchy2 &&
                    ele.weightBasis2 &&
                    ele.targetAllocationPercentage) ||
                  (ele.salesHistoryStartDate1 &&
                    ele.salesHistoryEndDate1 &&
                    ele.merchHierarchy1 &&
                    ele.weightBasis1 &&
                    !ele.salesHistoryStartDate2 &&
                    !ele.salesHistoryEndDate2 &&
                    !ele.merchHierarchy2 &&
                    !ele.weightBasis2 &&
                    ele.targetAllocationPercentage) ||
                  (!ele.salesHistoryStartDate1 &&
                    !ele.salesHistoryEndDate1 &&
                    !ele.merchHierarchy1 &&
                    !ele.weightBasis1 &&
                    !ele.salesHistoryStartDate2 &&
                    !ele.salesHistoryEndDate2 &&
                    !ele.merchHierarchy2 &&
                    !ele.weightBasis2 &&
                    !ele.targetAllocationPercentage)
                ) {
                  // ele.sum1 = "-";
                  // ele.sum2 = "-";
                } else {
                  this.flagForCalSum.flagSelectedMerchSalesHistoryByDC = !this
                    .flagForCalSum.flagSelectedMerchSalesHistoryByDC;
                  this.calSum2 = true;
                  flagDialog = true;
                }
                if (ele.sum1 === 0 || ele.sum2 === 0) {
                  flagDialog = true;
                  if (this.flagForCalSum.flagSelectedMerchSalesHistoryByDC) {
                    this.flagForCalSum.flagSelectedMerchSalesHistoryByDC = !this
                      .flagForCalSum.flagSelectedMerchSalesHistoryByDC;
                    this.calSum1 = true;
                  } else {
                    this.flagForCalSum.flagSelectedMerchSalesHistoryByDC = !this
                      .flagForCalSum.flagSelectedMerchSalesHistoryByDC;
                    this.calSum1 = true;
                  }
                }
                this.getJsonData.strategy = res.sumOfQuantitySold;
              });
              if (flagDialog) {
                let payloadWorker;
                let flagTemp = new Promise((resolve) => {
                  if (!!window.Worker) {
                    payloadWorker = new Worker(
                      "PayloadCreatorDedicatedWorker.js"
                    );
                    payloadWorker.postMessage(this.getJsonData);
                    const _this = this;
                    payloadWorker.onmessage = function (e) {
                      if (typeof e.data !== "undefined") {
                        _this.setJsonData(e.data);
                        resolve(e.data);
                      }
                    };
                  }
                });
                flagTemp.then((data) => {
                  payloadWorker.postMessage("DIE");
                  resolve(true);
                });
              } else {
                let payloadWorker;
                let flagTemp = new Promise((resolve) => {
                  if (!!window.Worker) {
                    payloadWorker = new Worker(
                      "PayloadCreatorDedicatedWorker.js"
                    );
                    payloadWorker.postMessage(this.getJsonData);
                    const _this = this;
                    payloadWorker.onmessage = function (e) {
                      if (typeof e.data !== "undefined") {
                        _this.setJsonData(e.data);
                        resolve(e.data);
                      }
                    };
                  }
                });
                flagTemp.then((data) => {
                  payloadWorker.postMessage("DIE");
                  resolve(true);
                });
              }
            }
            this.isStep4ForceRenderer = !this.isStep4ForceRenderer;
          });
        });
      });
    },
    updatePayloadForR() {
      const flag = new Promise((resolve, reject) => {
        this.setLoader(true);
        let messageArr = [];
        let newRunAlgoPayload = [];
        let emptySaleAndEmptyTA = false;
        let emptyTaAndSale = false;
        this.getJsonData.strategy.forEach((element) => {
          let tempObj = {};
          if (
            !element.salesHistoryStartDate1 &&
            !element.salesHistoryEndDate1 &&
            !element.merchHierarchy1 &&
            !element.merchSelections1 &&
            (!element.targetAllocationPercentage ||
              !element.targetAllocationUnits)
          ) {
            emptySaleAndEmptyTA = true;
          }
          if (
            (!element.targetAllocationPercentage ||
              !element.targetAllocationUnits) &&
            element.salesHistoryStartDate1 &&
            element.salesHistoryEndDate1 &&
            element.merchHierarchy1 &&
            element.merchSelections1
          ) {
            emptyTaAndSale = true;
          }
          if (
            !element.salesHistoryStartDate2 &&
            !element.salesHistoryEndDate2 &&
            !element.merchHierarchy2 &&
            !element.merchSelections2 &&
            (!element.targetAllocationPercentage ||
              !element.targetAllocationUnits)
          ) {
            emptySaleAndEmptyTA = true;
          }
          if (
            (!element.targetAllocationPercentage ||
              !element.targetAllocationUnits) &&
            element.salesHistoryStartDate2 &&
            element.salesHistoryEndDate2 &&
            element.merchHierarchy2 &&
            element.merchSelections2
          ) {
            emptyTaAndSale = true;
          }
          tempObj = element;
          // tempObj['articleID'] = element.productID,
          // tempObj['salesHistoryStartDate1'] =element.salesHistoryStartDate1
          // tempObj['salesHistoryEndDate1'] = element.salesHistoryEndDate1
          // tempObj['merchHierarchy1']= element.merchHierarchy1
          // if(element.merchHierarchy == 'MerchCat' || element.merchHierarchy == 'Cat') {
          //   tempObj['merchSelections1'] = element.merchSelections1.substring(element.merchSelections.indexOf("-")+2, element.merchSelections.length)
          // } else {
          //   }
          // tempObj['merchSelections'] = element.merchSelections
          let articleObj = this.getJsonData.sites.find(
            (article) =>
              Number(article.ArticleId) === Number(element["productID"])
          );
          let storeIds = filter(articleObj.Selections, (v) =>
            includes(element.supplyingDC, v.dc)
          );
          let storeIdsTemp1 = {};
          let storeIdsTemp2 = {};
          let tempIndices = {};
          tempObj["referenceStores1"] = {};
          tempObj["referenceStores2"] = {};
          tempObj["indices"] = {};
          // if(tempObj.useReferenceStore1 === "Y") {
          //   storeIds.forEach(ele=>{
          //     storeIdsTemp1[ele.store_id] = ele.referenceStoreID
          //   })
          //   // storeIdsTemp1 = storeIds.map(number => number.referenceStoreID);
          // } else {
          //     storeIds.forEach(ele=>{
          //     storeIdsTemp1[ele.store_id] = ele.store_id
          //   })
          //   // storeIdsTemp1 = storeIds.map(number => number.store_id);
          // }
          // if(tempObj.useReferenceStore2 === "Y") {
          //     storeIds.forEach(ele=>{
          //     storeIdsTemp2[ele.store_id] = ele.referenceStoreID
          //   })
          //   // storeIdsTemp2 = storeIds.map(number => number.referenceStoreID);
          // } else {
          //     storeIds.forEach(ele=>{
          //     storeIdsTemp2[ele.store_id] = ele.store_id
          //   })
          //   // storeIdsTemp2 = storeIds.map(number => number.store_id);
          // }
          // if(tempObj.useAllIndexValues === "Y") {
          //     storeIds.forEach(ele=>{
          //     tempIndices[ele.store_id] = ele.indexValue
          //   })
          //   //  tempIndices = storeIds.map(number => number.indexValue)
          // } else {
          //     storeIds.forEach(ele=>{
          //     tempIndices[ele.store_id] = 1
          //   })
          //   // tempIndices = storeIds.map(number => 1)
          // }
          storeIds.forEach((ele) => {
            storeIdsTemp1[ele.store_id] = ele.store_id;
          });
          storeIds.forEach((ele) => {
            storeIdsTemp2[ele.store_id] = ele.store_id;
          });
          storeIds.forEach((ele) => {
            tempIndices[ele.store_id] = 1;
          });
          tempObj["referenceStores1"] = storeIdsTemp1;
          tempObj["referenceStores2"] = storeIdsTemp2;
          tempObj["indices"] = tempIndices;

          newRunAlgoPayload.push(tempObj);
        });
        this.CalSumOfQtySold({
          inputForSumOfQuantitySold: newRunAlgoPayload,
          weightNumber: 1,
        }).then((response) => {
          let newPayload = response.sumOfQuantitySold;
          this.CalSumOfQtySold({
            inputForSumOfQuantitySold: newPayload,
            weightNumber: 2,
          }).then((res) => {
            this.$root.$emit("forceRenderCalSumDat", true);
            {
              let flagDialog = false;
              res.sumOfQuantitySold.forEach((ele) => {
                if (
                  (ele.salesHistoryStartDate1 &&
                    ele.salesHistoryEndDate1 &&
                    ele.merchHierarchy1 &&
                    ele.weightBasis1 &&
                    ele.salesHistoryStartDate2 &&
                    ele.salesHistoryEndDate2 &&
                    ele.merchHierarchy2 &&
                    ele.weightBasis2 &&
                    ele.targetAllocationPercentage) ||
                  (ele.salesHistoryStartDate1 &&
                    ele.salesHistoryEndDate1 &&
                    ele.merchHierarchy1 &&
                    ele.weightBasis1 &&
                    !ele.salesHistoryStartDate2 &&
                    !ele.salesHistoryEndDate2 &&
                    !ele.merchHierarchy2 &&
                    !ele.weightBasis2 &&
                    ele.targetAllocationPercentage) ||
                  (!ele.salesHistoryStartDate1 &&
                    !ele.salesHistoryEndDate1 &&
                    !ele.merchHierarchy1 &&
                    !ele.weightBasis1 &&
                    !ele.salesHistoryStartDate2 &&
                    !ele.salesHistoryEndDate2 &&
                    !ele.merchHierarchy2 &&
                    !ele.weightBasis2 &&
                    !ele.targetAllocationPercentage)
                ) {
                  // ele.sum1 = "-";
                  // ele.sum2 = "-";
                } else {
                  this.flagForCalSum.flagSelectedMerchSalesHistoryByDC = !this
                    .flagForCalSum.flagSelectedMerchSalesHistoryByDC;
                  this.calSum2 = true;
                  flagDialog = true;
                }
                if (ele.sum1 === 0 || ele.sum2 === 0) {
                  flagDialog = true;
                  if (this.flagForCalSum.flagSelectedMerchSalesHistoryByDC) {
                    this.flagForCalSum.flagSelectedMerchSalesHistoryByDC = !this
                      .flagForCalSum.flagSelectedMerchSalesHistoryByDC;
                    this.calSum1 = true;
                  } else {
                    this.flagForCalSum.flagSelectedMerchSalesHistoryByDC = !this
                      .flagForCalSum.flagSelectedMerchSalesHistoryByDC;
                    this.calSum1 = true;
                  }
                }
                this.getJsonData.strategy = res.sumOfQuantitySold;
              });
              if (flagDialog) {
                messageArr.push(
                  "The highlighted cells do not have a sales history for the selected time period for the selected/ mapped merch hierarchy. Do you want to make changes or proceed with running the algorithm"
                );
                messageArr.push(
                  "There is no sales history for the period or level you have chosen. Minimums specified will be allocated across stores. Do you still want to proceed?"
                );
                if (emptySaleAndEmptyTA) {
                  messageArr.push(
                    "Due to no input of Sales History and target allocation %, only minimums specified will be allocated across stores. Do you still want to proceed? "
                  );
                }
                if (this.calSum2) {
                  messageArr.push(
                    "Please enter valid combination of inputs for the highlighted rows"
                  );
                }
                if (emptyTaAndSale) {
                  messageArr.push(
                    "As no allocation percentage is chosen, by default the entire inventory at the DC will get allocated across stores. Do you wish to Proceed?"
                  );
                }
                let messageBody =
                  '<div><div align="middle" style="font-size: 1rem; margin-bottom:0.5rem;"><strong>Warning</strong></div>';
                messageArr.forEach((ele) => {
                  messageBody = messageBody + `<li>${ele}</li>`;
                });
                messageBody = messageBody + "</div>";
                this.$dialog
                  .confirm(messageBody, { html: true, customClass: "bg" })
                  .then(() => {
                    this.setLoader(true);
                    let payloadWorker;
                    let flagTemp = new Promise((resolve) => {
                      if (!!window.Worker) {
                        payloadWorker = new Worker(
                          "PayloadCreatorDedicatedWorker.js"
                        );
                        payloadWorker.postMessage(this.getJsonData);
                        const _this = this;
                        payloadWorker.onmessage = function (e) {
                          if (typeof e.data !== "undefined") {
                            _this.setJsonData(e.data);
                            resolve(e.data);
                          }
                        };
                      }
                    });
                    flagTemp.then((data) => {
                      payloadWorker.postMessage("DIE");
                      this.setLoader(false);
                      resolve(true);
                    });
                  })
                  .catch(() => {
                    this.setLoader(false);
                    reject();
                  });
              } else {
                this.setLoader(true);
                let payloadWorker;
                let flagTemp = new Promise((resolve) => {
                  if (!!window.Worker) {
                    payloadWorker = new Worker(
                      "PayloadCreatorDedicatedWorker.js"
                    );
                    payloadWorker.postMessage(this.getJsonData);
                    const _this = this;
                    payloadWorker.onmessage = function (e) {
                      if (typeof e.data !== "undefined") {
                        _this.setJsonData(e.data);
                        resolve(e.data);
                      }
                    };
                  }
                });
                flagTemp.then((data) => {
                  payloadWorker.postMessage("DIE");
                  this.setLoader(false);
                  resolve(true);
                });
              }
            }
            this.isStep4ForceRenderer = !this.isStep4ForceRenderer;
          });
        });
      });
      // this.flagForCalSum.flagSelectedMerchSalesHistoryByDC = !this.flagForCalSum.flagSelectedMerchSalesHistoryByDC
      return flag;
    },
    beforeStep6() {
      this.step5Flag = true;
      if (parseInt(this.getJsonData.allocationBasics.allocationStage) > 5) {
        if (
          !isEqual(
            this.getTempJsonData.finalizeAllocation,
            this.getJsonData.finalizeAllocation
          )
        ) {
          let context = this;
          return new Promise((resolve, reject) => {
            context.$dialog
              .confirm(
                "Next steps data will be lost. Do you want to proceed?",
                this.dialogOptions
              )
              .then(function (dialog) {
                context.getJsonData.allocationBasics.allocationStage = "5";
                let jsonData = pick(context.getJsonData, [
                  "allocationTemplateFileContents",
                  "allocationBasics",
                  "articles",
                  "sites",
                  "strategy",
                  "finalizeAllocation",
                  "Allocation_JoAnn",
                ]);
                context
                  .saveAllocationData({
                    allocationID:
                      context.getJsonData.allocationBasics.allocationID,
                    jsonData,
                  })
                  .then((resp) => {
                    context.isStep5ForceRenderer = !context.isStep5ForceRenderer;
                    context.$refs.formWizard.reset();
                    context.$refs.formWizard.changeTab(0, 4);
                    // context.getReportAllocation()

                    resolve(true);
                  })
                  .catch((e) => {
                    reject(e.message);
                  });
              })
              .catch(function () {
                context.getJsonData.finalizeAllocation = cloneDeep(
                  context.getTempJsonData.finalizeAllocation
                );
                reject();
              });
          });
        } else {
          return true;
        }
      } else {
        let context = this;
        return new Promise((resolve, reject) => {
          context.getJsonData.allocationBasics.allocationStage = "5";
          context
            .saveAllocationData({
              allocationID: context.getJsonData.allocationBasics.allocationID,
              jsonData: context.getJsonData,
            })
            .then((resp) => {
              // this.getReportAllocation()
              resolve(true);
            })
            .catch((e) => {
              reject(e.message);
            });
        });
      }
    },
    beforeStep7() {
      let context = this;
      return new Promise((resolve, reject) => {
        context.getJsonData.allocationBasics.allocationStage = "6";
        context
          .saveAllocationData({
            allocationID: context.getJsonData.allocationBasics.allocationID,
            jsonData: context.getJsonData,
          })
          .then((resp) => {
            resolve(true);
          })
          .catch((e) => {
            reject(e.message);
          });
      });
    },
    /************************************************************* removed DC Level Code Computation *********************************** */

    // updateDcLevelData() {
    //   let returnedObj = [];
    //   let len = this.getJsonData.finalizeAllocation.length;
    //   let obj = {};

    //   for (let i = 0; i < len; i++) {
    //     let aa = this.getJsonData.finalizeAllocation[i];
    //     let xx = Number(aa["Article ID"]);
    //     let supplyxx = aa["Supplying DC"];
    //     // let x = obj[xx +  supplyxx]
    //     if (typeof obj[xx + supplyxx] !== "undefined") {
    //       obj[xx + supplyxx].push(aa);
    //     } else {
    //       obj[xx + supplyxx] = [aa];
    //     }
    //   }
    //   let sum = 0;

    //   this.getJsonData.strategy.forEach(element => {
    //     let tempObj = {};
    //     tempObj = element;
    //     tempObj.allocatedTillDate =
    //       element.inSeasonSales + element.storeTotalOH + element.storeTotalOO;
    //     if (obj[Number(tempObj.productID) + tempObj.supplyingDC]) {
    //       sum = obj[Number(tempObj.productID) + tempObj.supplyingDC].reduce(
    //         function(a, b) {
    //           return parseInt(a) + parseInt(b.Allocation);
    //         },
    //         0
    //       );
    //     } else {
    //       sum = 0;
    //     }
    //     tempObj.allocation = sum;
    //     tempObj.dcInventoryPostAllocation =
    //       element.availableInventory - tempObj.allocation;
    //     returnedObj.push(tempObj);
    //   });
    //   this.getJsonData.getDcLevelData = returnedObj;
    // },
    /************************************************************* removed DC Level Code Computation *********************************** */

    onStepChange(prev, next) {
      let context = this.getJsonData;
      let articleCount = context && context.articles && context.articles.selections && context.articles.selections.length
      // this.errorMsgFlag = false;
      // if ((prev == 5 && next == 4) || (prev == 3 && next == 4)) {
      //   if (!this.getJsonData.getDcLevelData) {
      //     this.updateDcLevelData();
      //   }
      // }
      if (
        (prev == 4 && next == 3) ||
        (prev == 2 && next == 3) ||
        (prev == 5 && next == 3)
      ) {
        // this.calSum2 = true
        this.flagForCalSum.flagSelectedMerchSalesHistoryByDC = !this
          .flagForCalSum.flagSelectedMerchSalesHistoryByDC;
      }
      if (next > -1) {
        this.stepIndex = next;
      }

      if(next == 1 && prev == 0){
      this.$gtag.event('Clicked_AllocationBasicsNextButton', {
        'event_category': 'Allocation Basics Screen',
        'event_label': 'Click on Next button',
        'value': articleCount
      })
      }

      if(next == 2 && prev == 1){
        this.$gtag.event('Clicked_ArticleSelectionScreenNextButton', {
        'event_category': 'Article Selection Screen',
        'event_label': 'Click on Next Button',
        'value': articleCount
      })
      }

      if(next == 3 && prev == 2){
        this.$gtag.event('Clicked_SiteExclusionScreenNextButton', {
        'event_category': 'Site Exclusion Screen',
        'event_label': 'Click on Next Button',
      })
      }

      if(next == 4 && prev == 3){
        this.$gtag.event('Clicked_RunAlgorithmButton', {
        'event_category': 'Strategy Screen',
        'event_label': 'Click on Run Algorithm Button ',
      })
      }

      if(next == 5 && prev == 4){
        this.$gtag.event('Clicked_FinalizeAllocationScreenNextButton', {
        'event_category': 'Finalize Allocation Screen',
        'event_label': 'Click on Next button',
      })
      }

      if(next == -1 && prev == 0){
        this.$gtag.event('Clicked_ReportScreenFinishButton', {
        'event_category': 'Report Screen',
        'event_label': 'Click on Finish button',
      })
      }

      if(next == 0 && prev == 1){
       this.$gtag.event('Clicked_ArticleSelectionScreenPreviousButton', {
        'event_category': 'Article Selection Screen',
        'event_label': 'Click on Previous button',
      })
      }

      if(next == 1 && prev == 2){
        this.$gtag.event('Clicked_SiteExclusionScreenPreviousButton', {
        'event_category': 'Site Exclusion Screen',
        'event_label': 'Click on Previous Button',
      })
      }

      if(next == 2 && prev == 3){
        this.$gtag.event('Clicked_StrategyScreenPreviousButton', {
        'event_category': 'Strategy Screen',
        'event_label': 'Click on Previous button',
      })
      }

      if(next == 3 && prev == 4){
        this.$gtag.event('Clicked_FinalizeAllocationScreenPreviousButton', {
        'event_category': 'Finalize Allocation Screen',
        'event_label': 'Click on Previous button',
      })
      }

      if(next == 4 && prev == 5){
        this.$gtag.event('Clicked_ReportScreenPreviousButton', {
        'event_category': 'Report Screen',
        'event_label': 'Click on Previous button',
      })
      }
    },
    handleErrorMessage: function (errorMesg) {
      if (errorMesg) {
        console.error(errorMesg);
        console.dir(errorMesg);

        Swal.fire({
          // title: 'Error!',
          text: errorMesg,
          icon: "warning",
          confirmButtonText: "Ok",
        });
      }
      // this.errorMsgFlag = true;
      // this.errorMesg = errorMesg;
    },
  },
};
</script>

<style>
.btn-save {
  background-color: rgb(115, 189, 0) !important;
  border-color: rgb(115, 189, 0) !important;
  color: white;
  box-sizing: border-box;
  border-width: 2px;
  font-size: 14px;
  font-weight: 600;
  padding: 6px 12px;
  min-width: 100px;
}
.wizard-btn {
  background-color: rgb(115, 189, 0) !important;
  border-color: rgb(115, 189, 0) !important;
  color: white;
  box-sizing: border-box;
  border-width: 2px;
  font-size: 14px;
  font-weight: 600;
  padding: 6px 12px;
  min-width: 100px;
}
.wizard-btn:hover {
  background-color: rgb(151, 221, 37) !important;
  border-color: rgb(151, 221, 37) !important;
}
.vue-form-wizard .wizard-header {
  padding: 0 !important;
}
/* .vue-form-wizard .wizard-tab-content {
    padding-top: 30px !important;
} */
.vue-form-wizard .wizard-tab-content {
  min-height: 100px;
  padding: 30px 0px 0px !important;
}
.AllocationDetails {
  padding: 0.5rem;
  border: 1px rgb(204, 204, 204) solid;
  margin-bottom: 0.25rem;
  background-color: #f0f3f5;
}
.bg {
  color: rgb(235, 103, 81);
}
.dg-main-content {
  min-width: 500px !important;
}
</style>