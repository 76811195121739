import {
  decimalFormatterStep6,
  decimalFormatterStep6withPer,
  decimalFormatter,
  percentageFormatter
} from "@/helpers/formatters";

export default {
  defaultColDef: {
    animateRows: true,
    sortable: true,
    filter: "agTextColumnFilter",
    unSortIcon: true
  },
  columnDefs: [
    {
      resizable: true,
      headerName: "",
      children: [
        {
          resizable: true,
          headerName: "STORE ID",
          field: "Store_ID"
        },
        {
          resizable: true,
          headerName: "TIER",
          field: "Tier",
          width: 100
        },
        {
          resizable: true,
          headerName: "IA CLUSTER",
          field: "IA_cluster"
        },
        {
          resizable: true,
          headerName: "NUMBER OF ELIGIBLE ARTICLE BASED ON LM",
          field: "Eligible_Products",
          width: 310
        }
      ]
    },
    {
      resizable: true,
      headerName: "UNITS",
      align: "centre",
      children: [
        {
          resizable: true,
          headerName: "STORE ON-HAND",
          field: "OH",
          valueFormatter: decimalFormatterStep6
        },
        {
          resizable: true,
          headerName: "IN TRANSIT",
          field: "OO",
          valueFormatter: decimalFormatterStep6
        },
        {
          resizable: true,
          headerName: "CURRENT ALLOCATION",
          field: "Current_Allocation",
          valueFormatter: decimalFormatter
        },
        {
          resizable: true,
          headerName: "TOTAL FILL",
          field: "Total_fill",
          valueFormatter: decimalFormatterStep6
        },
        {
          resizable: true,
          headerName: "SALE-CURRENT",
          field: "Sales_Basis_1",
          valueFormatter: decimalFormatterStep6
        },
        {
          resizable: true,
          headerName: "SALES BASIS#2",
          field: "Sales_Basis_2",
          valueFormatter: decimalFormatterStep6
        }
      ]
    },
    {
      resizable: true,
      headerName: "",
      children: [
        {
          resizable: true,
          headerName: "SALE-CURRENT",
          field: "ST_perc_basis_1",
          valueFormatter: decimalFormatterStep6
        },
        {
          resizable: true,
          headerName: "ST%BASIS#2",
          field: "ST_perc_basis_2",
          valueFormatter: decimalFormatterStep6
        },
        {
          resizable: true,
          headerName: "WOS(CURRENT)",
          field: "wos1",
          valueFormatter: decimalFormatterStep6,
          headerTooltip: "Week of Supply (Current)"
        },
        {
          resizable: true,
          headerName: "FORWARD WOS",
          field: "wos2",
          valueFormatter: decimalFormatterStep6
        }
      ]
    },
    {
      resizable: true,
      headerName: "% TO TOTAL",
      align: "centre",
      children: [
        {
          resizable: true,
          headerName: "STORE ON-HAND",
          field: "OH_perc",
          valueFormatter: percentageFormatter
        },
        {
          resizable: true,
          headerName: "IN TRANSIT",
          field: "OO_perc",
          valueFormatter: percentageFormatter
        },
        {
          resizable: true,
          headerName: "CURRENT ALOCATION",
          field: "Current_Allocation_perc",
          valueFormatter: percentageFormatter
        },
        {
          resizable: true,
          headerName: "TOTAL FILL",
          field: "Total_fill_perc",
          valueFormatter: decimalFormatterStep6withPer
        },
        {
          resizable: true,
          headerName: "SALE-CURRENT",
          field: "Sales_Basis_1_perc",
          valueFormatter: percentageFormatter
        },
        {
          resizable: true,
          headerName: "SALES BASIS#2",
          field: "Sales_Basis_2_perc",
          valueFormatter: percentageFormatter
        }
      ]
    },
    {
      resizable: true,
      headerName: "VARIANCE TO IDEAL",
      field: "Var_to_ideal",
      valueFormatter: decimalFormatterStep6
    }
  ]
};
