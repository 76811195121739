<template>
  <div style="width:100%;">
    <b-form-group>
      <b-row>
        <b-col style="padding:0 1px 0 1rem !important;">
          <label>Sales Date Range (Current)</label>
        </b-col>
        <b-col>
          <div class="datepicker-trigger">
            <input
              autocomplete="off"
              type="text"
              id="datepicker-trigger"
              placeholder="Select dates"
              style="width: 180px; padding: 0 0.5rem;"
              :value="formatDates(input.salesBasis1Start, input.salesBasis1End)"
            >

            <AirbnbStyleDatepicker
              :trigger-element-id="'datepicker-trigger'"
              :mode="'range'"
              :close-after-select="true"
              :show-action-buttons="false"
              :show-month-year-select="true"
              :show-shortcuts-menu-trigger="false"
              :fullscreen-mobile="true"
              :date-one="input.salesBasis1Start"
              :date-two="input.salesBasis1End"
              :endDate="todayDate"
              @date-one-selected="val => { input.salesBasis1Start = val }"
              @date-two-selected="val => { input.salesBasis1End = val }"
            />
          </div>
        </b-col>
        <b-col style="padding:0 1px 0 1rem !important;margin-left:0.5rem;">
          <label>Sales Date Range (Past):</label>
        </b-col>
        <b-col>
          <div class="datepicker-trigger">
            <input
              autocomplete="off"
              type="text"
              id="datepicker-trigger-date2"
              placeholder="Select dates"
              style="width: 180px; padding: 0 0.5rem;"
              :value="formatDates(input.salesBasis2Start, input.salesBasis2End)"
            >

            <AirbnbStyleDatepicker
              :trigger-element-id="'datepicker-trigger-date2'"
              :mode="'range'"
              :close-after-select="true"
              :show-action-buttons="false"
              :fullscreen-mobile="true"
              :show-shortcuts-menu-trigger="false"
              :show-month-year-select="true"
              :date-one="input.salesBasis2Start"
              :date-two="input.salesBasis2End"
              :endDate="todayDate"
              @date-one-selected="val => { input.salesBasis2Start = val }"
              @date-two-selected="val => { input.salesBasis2End = val }"
            />
          </div>
        </b-col>
        <b-col>
          <div>
          <b-button style="cursor: pointer;" @click="onSelectedSales" size="sm" :disabled="!buttonEnable">Apply</b-button>
          </div>
        </b-col>
      </b-row>   
    </b-form-group>
  </div>
</template>

<script>
import format from 'date-fns/format'

export default {
  data() {
    return {
      todayDate: new Date(),
       dateFormat: 'D MMM YYYY',
      dateOne: '',
      dateTwo: '',
      input: {
        salesBasis1Start: '',
        salesBasis1End: '',
        salesBasis2Start: '',
        salesBasis2End: ''
      }
    }
  },
  computed: {
    buttonEnable() {
      let flag;
      if (this.input.salesBasis1Start && this.input.salesBasis1End && this.input.salesBasis2Start && this.input.salesBasis2End) {
        flag = true
      }
      return flag
    },
    futureDate() {
      var date = new Date();
      var future = format(date.setDate(date.getDate()), 'MM/DD/YYYY')
      return future;
    },
  },
  methods: {
    onSelectedSales() {
      this.$emit('selectedCalendarInput', this.input)
    },
        formatDates(dateOne, dateTwo) {
      let formattedDates = ''
      if (dateOne) {
        formattedDates = format(dateOne, this.dateFormat)
      }
      if (dateTwo) {
        formattedDates += ' - ' + format(dateTwo, this.dateFormat)
      }
      return formattedDates
    }
  
  }
}
</script>

<style>
.flex-container {
  display: flex;
  flex-flow: row wrap;
  /* align-content: space-between;
  justify-content: center;
  justify-content: flex-start; */
  justify-content: space-around;
  /* align-items: baseline; */
  /* align-items: stretch; */
}
.flex-container1 {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
}
.flex-container > div {
  text-align: center;
}
.align-self{
  align-self: center
}
</style>
