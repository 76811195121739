<template>
  <div>
    <div style="border:1px solid #CCCCCC; width: 100%;" class="p-1">
      <b-row
        align="center"
        style="display: flex; align-items: center;s"
      >
        <b-col cols="6">
          <div style="border:0px solid #CCCCCC !important;">
            <input
              class="p-2"
              type="text"
              placeholder="Paste Store Exclusion IDs"
              style="width:100%;"
              v-model="selectedArticles"
            >
          </div>
        </b-col>
        <b-col cols="6" style="padding: 0 !important;">
          <b-button @click="onApplyClicked">Exclude for article {{articleId}} </b-button>
        </b-col>
        <!-- <b-col cols="1" >OR</b-col>
        <b-col cols="1"   v-if="showFilter">
          <div>
            <b-button id="siteFilter" ref="button">
              <i class="fa fa-filter fa-lg" aria-hidden="true"></i>
            </b-button>
          </div>

          <b-popover
            target="siteFilter"
            triggers="click"
            :show.sync="popoverShow"
            placement="auto"
            container="myContainer"
            ref="popover"
          >
            <template slot="title">
              <b-button @click="onClose" class="close" aria-label="Close" style="color: #FFFFFF;">
                <span class="d-inline-block" aria-hidden="true">&times;</span>
              </b-button>Store Filters
            </template>
            <div>
              <b-form-group label="Region" class="mb-1">
                <b-form-select
                  size="sm"
                  id="pop2"
                  v-model="selectedRegion"
                  :options="getFilteredData"
                />
              </b-form-group>

              <b-form-group label="District" class="mb-1">
                <b-form-select
                  :disabled="!selectedRegion"
                  size="sm"
                  id="pop2"
                  v-model="selectedDistrict"
                  :options="getDistrictData"
                />
              </b-form-group>

              <b-form-group label="State" class="mb-1">
                <b-form-select
                  :disabled="!selectedDistrict"
                  size="sm"
                  id="pop2"
                  v-model="selectedState"
                  :options="getStateData"
                />
              </b-form-group>

              <b-form-group label="City" class="mb-1">
                <b-form-select
                  :disabled="!selectedState"
                  size="sm"
                  id="pop2"
                  v-model="selectedCity"
                  :options="getCityData"
                />
              </b-form-group>

              <b-form-group label="Store ID" class="mb-1" invalid-feedback="This field is required">
                <b-form-select
                  :disabled="!selectedCity"
                  size="sm"
                  id="pop2"
                  v-model="selectedStoreId"
                  :options="getStoreIdData"
                />
              </b-form-group>
              <b-button size="sm" variant="primary" @click="onApply" style="margin-right:10px">Apply</b-button>
             <b-button @click="onReset" size="sm" variant="primary">Reset</b-button>
            </div>
          </b-popover>
        </b-col> -->
      </b-row>
    </div>
  </div>
</template>
<script>
import tableDummyData from "@/constants/tableDummyData";
import { uniq, map, filter, includes } from "lodash";
import { mapGetters } from "vuex";
import { debug } from "util";

export default {
  props: {
    showFilter: {
      type: Boolean,
      default: true
    },
    filterData: {
      type: Array,
      default: true
    },
    articleId: {
      type: String
    }
  },
  data() {
    return {
      tableDummyData,
      selectedRegion: "",
      getRegionData: [],
      selectedDistrict: "",
      getDistrictData: [],
      selectedState: "",
      getStateData: [],
      selectedCity: "",
      getCityData: [],
      selectedStoreId: "",
      getStoreIdData: [],
      selectedArticles: [],
      productIds: [],
      popoverShow: false
    };
  },
    computed: {
      ...mapGetters({
        getJsonData: "CreateAllocation/getJsonData"
      }),
      getFilteredData() {
        var out = {};
        let result = this.filterData;
        for (var i = 0; i < result.length; i++) {
          if (typeof out[result[i]["region"]] === "undefined") {
            out[result[i]["region"]] = {};
          }

        if (
          typeof out[result[i]["region"]][result[i]["sales_district"]] === "undefined"
        ) {
          out[result[i]["region"]][result[i]["sales_district"]] = {};
        }

        if (
          typeof out[result[i]["region"]][result[i]["sales_district"]][
            result[i]["state"]
          ] === "undefined"
        ) {
          out[result[i]["region"]][result[i]["sales_district"]][
            result[i]["state"]
          ] = {};
        }
        if (
          typeof out[result[i]["region"]][result[i]["sales_district"]][
            result[i]["state"]
          ][result[i]["city"]] === "undefined"
        ) {
          out[result[i]["region"]][result[i]["sales_district"]][result[i]["state"]][
            result[i]["city"]
          ] = {};
        }
        if (
          typeof out[result[i]["region"]][result[i]["sales_district"]][
            result[i]["state"]
          ][result[i]["city"]][result[i]["store_id"]] === "undefined"
        ) {
          out[result[i]["region"]][result[i]["sales_district"]][result[i]["state"]][
            result[i]["city"]
          ][result[i]["store_id"]] = {};
        }
      }
      return out;
    }
  },
  watch: {
    selectedRegion(val) {
      this.selectedDistrict = "";
      (this.getDistrictData = []),
        (this.getStateData = []),
        (this.getCityData = []),
        (this.getStoreIdData = []);
      if (val) {
        this.getDistrictData = this.getFilteredData[val];
      }
    },
    selectedDistrict(val) {
      this.selectedState = "";
      (this.getStateData = []),
        (this.getCityData = []),
        (this.getStoreIdData = []);
      if (val) {
        this.getStateData = this.getDistrictData[val];
      }
    },
    selectedState(val) {
      this.selectedCity = "";
      (this.getCityData = []), (this.getStoreIdData = []);
      if (val) {
        this.getCityData = this.getStateData[val];
      }
    },
    selectedCity(val) {
      this.selectedStoreId = "";
      this.articleList = [];
      if (val) {
        this.getStoreIdData = this.getCityData[val];
      }
    }
  },
  methods: {
    onClose() {
      this.popoverShow = false;
    },
    onOk() {
      this.onClose();
      this.popoverShow = false;
    },
    onShow() {
      this.businessGroup = "";
      this.input2 = "";
    },
    onShown() {
      this.focusRef(this.$refs.businessGroup);
    },
    onHidden() {
      this.focusRef(this.$refs.button);
    },
    onApply() {
      this.popoverShow = false;
      if (this.selectedStoreId) {
        this.onFilterData(this.selectedStoreId, "store_id");
        return;
      }
      if (this.selectedCity) {
        this.onFilterData(this.selectedCity, "city");
        return;
      }
      if (this.selectedState) {
        this.onFilterData(this.selectedState, "state");
        return;
      }
      if (this.selectedDistrict) {
        this.onFilterData(this.selectedDistrict, "sales_district");
        return;
      }
      if (this.selectedRegion) {
        this.onFilterData(this.selectedRegion, "region");
        return;
      }
    },
    clearInput() {
      this.selectedCity = ""
      this.selectedState = ""
      this.selectedDistrict = ""
      this.selectedRegion = ""
    },
    onReset(){
      this.selectedRegion= ""
     
      this.selectedDistrict= ""
    
      this.selectedState= ""
    
      this.selectedCity= ""
    },
    onFilterData(val, key) {
      let arr = []
      arr = uniq(map(filter(this.filterData, v => v[key] === val), 'store_id'))
      this.$root.$emit('siteFilteredData1', arr);
      
    },
    onApplyClicked() {
      if(this.selectedArticles  && this.selectedArticles.length) {
        let obj = {
          productIds : this.selectedArticles,
          onExclude: true,
          fromExcludeFilter: true
        }
      this.$emit("ExcludedStoreIdFromFilter", obj);
      this.selectedArticles = []
      }
      // this.productIds = this.selectedArticles;
     
      // finalData.removedData = filter(this.getJsonData.articles.selections, (v) => includes(this.productIds, v.article));
      // finalData.existingData = filter(this.getJsonData.articles.selections, (v) => !includes(this.productIds, v.article));
    }
  }
};
</script>
<style>
.btn-secondary {
  color: white;
  background-color: #76bf00;
  border-color: #76bf00;
}
.btn-secondary:hover {
  background-color: #76bf00 !important;
  color: white;
  border-color: #76bf00 !important;
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: white;
  background-color: #76bf00 !important;
  border-color: #76bf00 !important;
  font-size: 14px !important;
}
.btn {
  font-size: 14px !important;
  border-color: #76bf00 !important;
}
.dropdown-menu.show {
  display: block;
  width: 100% !important;
}
</style>
