<template>
  <div>
    <div style="border:1px solid #CCCCCC; width: 100%;" class="p-1">
      <b-row
        align="center"
        style="display: flex; align-items: center;"
      >
        <b-col cols="5">
          <div style="border:0px solid #CCCCCC !important;">
            <input
              class="p-2"
              type="text"
              placeholder="Search by pasting multiple article ID's"
              style="width:100%;"
              v-model="selectedArticles"
            >
          </div>
        </b-col>
        <b-col cols="1">
          <b-button @click="pasteSkus">Exclude</b-button>
        </b-col>
        <b-col cols="1" offset="1">OR</b-col>
        <b-col cols="1" offset="3" v-if="showFilter">
          <div>
            <b-button id="exPopoverReactive1" ref="button">
              <i class="fa fa-filter fa-lg" aria-hidden="true"></i>
            </b-button>
          </div>

          <b-popover
            target="exPopoverReactive1"
            triggers="click"
            :show.sync="popoverShow"
            placement="auto"
            container="myContainer"
            ref="popover"
          >
            <template slot="title">
              <b-button class="close" aria-label="Close" @click="onClose" style="color: #FFFFFF;">
                <span class="d-inline-block" aria-hidden="true">&times;</span>
              </b-button>Article Exclusion Filters
            </template>
            <div  v-on-click-outside="close">
              <b-form-group label="Business Group" class="mb-1">
                <b-form-select
                  size="sm"
                  id="pop2"
                  v-model="businessGroup"
                  :options="getFilteredData"
                />
              </b-form-group>

              <b-form-group label="Shop" class="mb-1">
                <b-form-select
                  :disabled="!businessGroup"
                  size="sm"
                  id="pop2"
                  v-model="shop"
                  :options="shopList"
                />
              </b-form-group>

              <b-form-group label="Category" class="mb-1">
                <b-form-select
                  :disabled="!shop"
                  size="sm"
                  id="pop2"
                  v-model="category"
                  :options="categoryList"
                />
              </b-form-group>

              <b-form-group label="Merch Category" class="mb-1">
                <b-form-select
                  :disabled="!category"
                  size="sm"
                  id="pop2"
                  v-model="merchCategory"
                  :options="merchCategoryList"
                />
              </b-form-group>

              <b-form-group label="Article" class="mb-1" invalid-feedback="This field is required">
                <b-form-select
                  :disabled="!merchCategory"
                  size="sm"
                  id="pop2"
                  v-model="article"
                  :options="articleList"
                />
              </b-form-group>

              <b-button @click="onApply" size="sm" variant="primary" style="margin-right:10px">Apply</b-button>
                <b-button @click="onReset" size="sm" variant="primary">Reset</b-button>
            </div>
          </b-popover>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import tableDummyData from "@/constants/tableDummyData";
import { uniq, map, filter, includes, cloneDeep } from "lodash";
import { mapGetters } from "vuex";
import { debug } from "util";

export default {
  props: {
    showFilter: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      tableDummyData,
      shop: "",
      category: "",
      businessGroup: "",
      merchCategory: "",
      article: "",
      shopList: [],
      categoryList: [],
      merchCategoryList: [],
      articleList: [],
      selectedArticles: [],
      productIds: [],
      popoverShow: false
    };
  },
  computed: {
    ...mapGetters({
      getJsonData: "CreateAllocation/getJsonData"
    }),
    getFilteredData() {
      var out = {};
      let result = this.getJsonData.articles.selections;
      for (var i = 0; i < result.length; i++) {
        if (typeof out[result[i]["business_descr"]] === "undefined") {
          out[result[i]["business_descr"]] = {};
        }

        if (
          typeof out[result[i]["business_descr"]][result[i]["shop_descr"]] === "undefined"
        ) {
          out[result[i]["business_descr"]][result[i]["shop_descr"]] = {};
        }

        if (
          typeof out[result[i]["business_descr"]][result[i]["shop_descr"]][
            result[i]["category_descr"]
          ] === "undefined"
        ) {
          out[result[i]["business_descr"]][result[i]["shop_descr"]][
            result[i]["category_descr"]
          ] = {};
        }
        if (
          typeof out[result[i]["business_descr"]][result[i]["shop_descr"]][
            result[i]["category_descr"]
          ][result[i]["merch_cat_descr"]] === "undefined"
        ) {
          out[result[i]["business_descr"]][result[i]["shop_descr"]][
            result[i]["category_descr"]
          ][result[i]["merch_cat_descr"]] = {};
        }
        if (
          typeof out[result[i]["business_descr"]][result[i]["shop_descr"]][
            result[i]["category_descr"]
          ][result[i]["merch_cat_descr"]][result[i]["article_descr"]] ===
          "undefined"
        ) {
          out[result[i]["business_descr"]][result[i]["shop_descr"]][
            result[i]["category_descr"]
          ][result[i]["merch_cat_descr"]][result[i]["article_descr"]] = {};
        }
      }
      return out;
    }
  },
  watch: {
    businessGroup(val) {
      this.shop = "";
      (this.shopList = []),
        (this.categoryList = []),
        (this.merchCategoryList = []),
        (this.articleList = []);
      if (val) {
        this.shopList = this.getFilteredData[val];
      }
    },
    shop(val) {
      this.category = "";
      (this.categoryList = []),
        (this.merchCategoryList = []),
        (this.articleList = []);
      if (val) {
        this.categoryList = this.shopList[val];
      }
    },
    category(val) {
      this.merchCategory = "";
      (this.merchCategoryList = []), (this.articleList = []);
      if (val) {
        this.merchCategoryList = this.categoryList[val];
      }
    },
    merchCategory(val) {
      this.article = "";
      this.articleList = [];
      if (val) {
        this.articleList = this.merchCategoryList[val];
      }
    }
  },
  methods: {
     close() {
      this.popoverShow = false;
    },
    onClose() {
      this.popoverShow = false;
    },
    onApply() {
      this.popoverShow = false;
      if (this.article) {
        this.filterData(this.article, "article_descr");
        return;
      }
      if (this.merchCategory) {
        this.filterData(this.merchCategory, "merch_cat_descr");
        return;
      }
      if (this.category) {
        this.filterData(this.category, "category_descr");
        return;
      }
      if (this.shop) {
        this.filterData(this.shop, "shop_descr");
        return;
      }
      if (this.businessGroup) {
        this.filterData(this.businessGroup, "business_descr");
        return;
      }
    },
    onReset(){
      this.businessGroup = ""
      this.shop=""
      this.category=""
      this.merchCategory=""
      this.article=""

    },
    pasteSkus() {
      let finalData = {
        existingData: [],
        removedData: []
      };
      let pastedArticles = this.selectedArticles
      for(let i = 0; i < pastedArticles.trim().split(/[\s,]+/).length ; i++) {
        this.productIds.push(pastedArticles.trim().split(/[\s,]+/)[i].toString().padStart(8, 0))
      }
      finalData.removedData = filter(this.getJsonData.articles.selections, v =>
        includes(this.productIds, v.article)
      );
      finalData.existingData = filter(
        this.getJsonData.articles.selections,
        v => !includes(this.productIds, v.article)
      );
      this.$emit("excludeByPastingArticles1", finalData);
      this.selectedArticles = []
      // this.selectedArticles = ""
    },
    filterData(val, key) {
      let finalData = {
        existingData: [],
        removedData: []
      };
      finalData.removedData = filter(
        this.getJsonData.articles.selections,
        v => val === v[key]
      );
      finalData.existingData = filter(
        this.getJsonData.articles.selections,
        v => val !== v[key]
      );
      this.$emit('filterData', finalData);
    },
  }
};
</script>
<style>
.btn-secondary {
  color: white;
  background-color: #76bf00;
  border-color: #76bf00;
}
.btn-secondary:hover {
  background-color: #76bf00 !important;
  color: white;
  border-color: #76bf00 !important;
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: white;
  background-color: #76bf00 !important;
  border-color: #76bf00 !important;
  font-size: 14px !important;
}
.btn {
  font-size: 14px !important;
  border-color: #76bf00 !important;
}
.dropdown-menu.show {
  display: block;
  width: 100% !important;
}
</style>
