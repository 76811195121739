<template>
  <div class="animated fadeIn">
    <b-card>
      <b-row slot="header">
        <b-col cols="10">
          <strong>Allocation Basics</strong>
        </b-col>
        <b-col cols="2">
          <b-button class="btn-download" @click="downloadTemplate" download>
            Download Template
            <i class="fa fa-download" style="padding-left:0.25rem;"></i>
          </b-button>
        </b-col>
        <!-- <div style="width:100%; text-align: right; color: #71BE00;" download>
          <i v-b-tooltip.hover title="Download" @click="downloadTemplate" class="fa fa-download cursor-pointer" style="padding-left:0.25rem; vertical-align: middle;">
            Template
          </i>
        </div>-->
      </b-row>
      <b-row align-h="end" class="p-2">
        <div></div>
      </b-row>
      <b-row style="padding: 1rem;">
        <b-col md="6">
          <b-form-group
            id="exampleInputGroup2"
            label-cols="3"
            label-cols-lg="3"
            label="Allocation Name*:"
            label-for="exampleInput2"
          >
            <b-form-input
              autocomplete="off"
              id="exampleInput2"
              type="text"
              maxlength="50"
              @change="allocationNameValidation"
              :state="validation"
              v-model="basicsForm.name"
              required
              placeholder="Allocation Name"
            />
            <b-form-invalid-feedback :state="validation">Allocation name already exists.</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            id="exampleInputGroup2"
            label-cols="3"
            label-cols-lg="3"
            label="Created By:"
            label-for="exampleInput2"
          >
            <b-form-input id="exampleInput2" type="text" v-model="userName" required disabled />
          </b-form-group>
        </b-col>
        <!-- <b-col md="6">
          <b-form-group id="exampleInputGroup2" label-cols="3" label-cols-lg="3" label="Merch Manager Name*:" label-for="exampleInput2">
             <b-form-select @input="onChangeMMName" v-model="basicsForm.mmName" :options="Object.keys(this.getStaticData)" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="MM Name">
              <template slot="selection" slot-scope="{ values, search, isOpen }"><span v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
            </b-form-select>
          </b-form-group>
        </b-col>-->
        <b-col md="6">
          <b-form-group
            id="exampleInputGroup2"
            label-cols="3"
            label-cols-lg="3"
            label="Created On:"
            label-for="exampleInput2"
          >
            <b-form-input id="exampleInput2" type="text" v-model="createdOn" required disabled />
          </b-form-group>
        </b-col>
        <!-- <b-col md="6">
          <b-form-group id="exampleInputGroup2" label-cols="3" label-cols-lg="3" label="Program Name*:" label-for="exampleInput2">
             <b-form-select @input="onChangeProgramName" :disabled="!basicsForm.mmName" v-model="basicsForm.programName" :options="basicsForm.mmName ? getStaticData[basicsForm.mmName] : []" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Program Name">
              <template slot="selection" slot-scope="{ values, search, isOpen }"><span v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
            </b-form-select>
          </b-form-group>
        </b-col>-->
        <b-col md="6">
          <b-form-group
            style="height:100%"
            id="exampleInputGroup2"
            label-cols="3"
            label-cols-lg="3"
            label="Comments:"
            label-for="exampleInput2"
          >
            <b-form-textarea
              id="exampleInput2"
              v-model="basicsForm.comments"
              placeholder="Enter comment"
              style="height:80%"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            id="exampleInputGroup2"
            label-cols="3"
            label-cols-lg="3"
            label="Push*:"
            label-for="exampleInput2"
          >
            <b-form-select
              v-model="basicsForm.push"
              :options="pushOptions"
              :disabled="allocationId"
              :close-on-select="true"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Push"
            >
              <template slot="selection" slot-scope="{ values, search, isOpen }">
                <span v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            id="exampleInputGroup2"
            label-cols="3"
            label-cols-lg="3"
            label="Upload Info*: "
            label-for="exampleInput2"
          >
            <div
              style="margin-top: 0.5rem;font-weight:600;"
              v-if="getJsonData && getJsonData.allocationBasics.fileName"
            >{{getJsonData.allocationBasics.fileName}}</div>
            <div
              style="border: 1px solid #e4e7ea; border-radius: 0.25rem; width: 100%;"
              class="p-1"
              v-else
            >
              <b-form-file
                style="width:100%;text-decoration: underline;"
                id="buyUploadFile"
                ref="fileinput"
                :disabled="allocationId"
                name="templateFile"
                v-model="basicsForm.file"
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col md="6" style="height:8rem;"></b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { format } from "date-fns";
import Multiselect from "vue-multiselect";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    Multiselect
  },
  props: ["basicsForm", "prevState"],
  computed: {
    ...mapGetters({
      getStaticData: "CreateAllocation/getStaticData",
      getJsonData: "CreateAllocation/getJsonData",
      getTempJsonData: "CreateAllocation/getTempJsonData"
    }),
    allocationId() {
      if (this.$route.params.allocationId) {
        return true;
      }
    },
    // allocationBasicsForm () {
    //   return this.basicsForm
    // },
    userName() {
      return localStorage.getItem("userName");
    }
  },
  data() {
    return {
      validation: null,
      createdOn: format(new Date(), "MM/DD/YYYY"),
      value: null,
      MMNameOptions: ["John", "Richard", "Misha", "Richa"],
      programOptions: ["Program 1", "Program 2", "Program 3", "Program 4"],
      pushOptions: ["INITIAL", "IN-SEASON", "CLEANOUT"]
    };
  },
  watch: {
    // 'allocationBasicsForm.mmName' (val) {
    //   if (val === null || val) {
    //     this.allocationBasicsForm.programName = null
    //   }
    // }
  },
  created() {
    
    // if (this.allocationId && !this.prevState) {
    //   this.allocationByAllocationId({allocationID: this.allocationId});
    // }
    // if (this.allocationId) {
    //   this.allocationBasicsForm.name = val.allocationBasicsForm.name
    // }
  },
  mounted() {
    let context = this;
    this.$root.$on("clearUploadBar", function() {
      if(!context.$refs.fileInput) return;
      context.basicsForm.file = null;
      context.$refs.fileInput.reset();
    });
  },
  methods: {
    ...mapActions({
      checkMMNameProgramCombination:
        "CreateAllocation/checkMMNameProgramCombination",
      allocationByAllocationId: "CreateAllocation/allocationByAllocationId",
      checkIfAllocationNameExistsForCurrentUser:
        "CreateAllocation/checkIfAllocationNameExistsForCurrentUser"
    }),
    allocationNameValidation(val) {
      this.checkIfAllocationNameExistsForCurrentUser({
        allocationName: this.basicsForm.name
      }).then(resp => {
        if (resp.exists) {
          this.validation = false;
        } else {
          this.validation = true;
        }
      });
      // return this.userId.length > 4 && this.userId.length < 13
    },
    downloadTemplate() {
      var a = document.createElement("A");
      a.href = "./joann-template.xlsx";
      a.setAttribute("target", "_blank");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

       this.$gtag.event('Clicked_DownloadSampleTemplate', {
        'event_category': 'Allocation Basics Screen',
        'event_label': 'Click on Download Template',
      })
    }
    // onChangeProgramName() {
    //   if (this.basicsForm.programName) {
    //     this.basicsForm.push = ''
    //   }
    //   let apiCall = {
    //     "mmName": this.allocationBasicsForm.mmName,
    //     "programName": this.allocationBasicsForm.programName
    //   }
    //   this.checkMMNameProgramCombination(apiCall).then(resp => {
    //     this.basicsForm.toBeLinked = false
    //   })
    // },
    // onChangeMMName() {
    //   if (this.basicsForm.mmName) {
    //     this.basicsForm.push = ''
    //   }
    // }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.btn-download {
  color: #71be00 !important;
  border-color: #71be00;
  background-color: #0000000d !important;
}
.btn-download:hover {
  color: #71be00 !important;
  border-color: #71be00;
  background-color: #7070706b !important;
}
</style>
