import { dateFormatter, dateFormate } from "@/helpers/formatters";
export default {
  defaultColDef: {
    animateRows: true,
    sortable: true,
    filter: "agTextColumnFilter",
    resizable: true,
    unSortIcon: true
  },
  columnDefs: [
    {
      headerName: "ARTICLE",
      field: "article",
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      pinned: "left",
      cellRenderer: "agGroupCellRenderer"
    },
    {
      headerName: "ARTICLE DESC",
      field: "article_descr",
      align: "centre"
    },
    {
      headerName: "CHAR COLLECTION",
      field: "collection",
      // valueFormatter: () => "GIRL/BOY/SPORTS",
    },
    {
      headerName: "MERCH CAT",
      field: "merch_cat_descr"
    },
    {
      headerName: "CATEGORY",
      field: "category_descr"
    },
    {
      headerName: "SHOP",
      field: "shop_descr"
    },
    {
      headerName: "BUSINESS",
      field: "business_descr"
    },
    {
      headerName: "IN STORE DATE",
      field: "in_store_date",
      valueFormatter: dateFormatter
    },
    {
      headerName: "DELIVERY DATE",
      field: "deliveryDate",
      valueFormatter: dateFormate
    },
    {
      headerName: "SEASON START FW",
      field: "seasonStartFiscalWeek",
      cellRenderer: "SeasonStartDate"
    },
    {
      headerName: "PACK",
      field: "pack",
      cellStyle: { "text-align": "right" }
    },
    {
      headerName: "All Store MIN",
      field: "allStoresMin",
      cellEditor: "numericEditor",
      // cellStyle: {"background-color": "#F4F6F8", "border-left": "1px solid grey"},
      editable: true,
      cellStyle: function(params) {
        if (
          (params.data.allStoresMax &&
            Number(params.data.allStoresMin) >
              Number(params.data.allStoresMax)) ||
          Math.sign(Number(params.data.allStoresMin)) == -1 ||
          Number.isInteger(Number(params.data.allStoresMin)) == false
        )
          return {
            "background-color": "#f77b65",
            "border-left": "1px solid grey",
            "text-align": "right"
          };
        else
          return {
            "background-color": "#F4F6F8",
            "border-left": "1px solid grey",
            "text-align": "right"
          };
      }
    },
    {
      headerName: "All Store MAX",
      field: "allStoresMax",
      // cellStyle: {"background-color": "#F4F6F8", "border-left": "1px solid grey"},
      editable: true,
      cellStyle: function(params) {
        if (
          (params.data.allStoresMax &&
            Number(params.data.allStoresMin) >
              Number(params.data.allStoresMax)) ||
          Math.sign(Number(params.data.allStoresMax)) == -1 ||
          Number.isInteger(Number(params.data.allStoresMax)) == false
        )
          return {
            "background-color": "#f77b65",
            "border-left": "1px solid grey",
            "text-align": "right"
          };
        else
          return {
            "background-color": "#F4F6F8",
            "border-left": "1px solid grey",
            "text-align": "right"
          };
      }
    },
    {
      headerName: "AVL01 MIN",
      field: "AVL01Min",
      cellStyle: function(params) {
        if (
          (params.data.AVL01Max &&
            Number(params.data.AVL01Min) > Number(params.data.AVL01Max)) ||
          Math.sign(Number(params.data.AVL01Min)) == -1 ||
          Number.isInteger(Number(params.data.AVL01Min)) == false
        )
          return {
            "background-color": "#f77b65",
            "border-left": "1px solid grey",
            "text-align": "right"
          };
        else
          return {
            "background-color": "#F4F6F8",
            "border-left": "1px solid grey",
            "text-align": "right"
          };
      },
      editable: true
    },
    {
      headerName: "AVL01 MAX",
      field: "AVL01Max",
      cellStyle: function(params) {
        if (
          (params.data.AVL01Max &&
            Number(params.data.AVL01Min) > Number(params.data.AVL01Max)) ||
          Math.sign(Number(params.data.AVL01Max)) == -1 ||
          Number.isInteger(Number(params.data.AVL01Max)) == false
        )
          return {
            "background-color": "#f77b65",
            "border-left": "1px solid grey",
            "text-align": "right"
          };
        else
          return {
            "background-color": "#F4F6F8",
            "border-left": "1px solid grey",
            "text-align": "right"
          };
      },
      editable: true
    },
    {
      headerName: "AVL02 MIN",
      field: "AVL02Min",
      cellStyle: function(params) {
        if (
          (params.data.AVL02Max &&
            Number(params.data.AVL02Min) > Number(params.data.AVL02Max)) ||
          Math.sign(Number(params.data.AVL02Min)) == -1 ||
          Number.isInteger(Number(params.data.AVL02Min)) == false
        )
          return {
            "background-color": "#f77b65",
            "border-left": "1px solid grey",
            "text-align": "right"
          };
        else
          return {
            "background-color": "#F4F6F8",
            "border-left": "1px solid grey",
            "text-align": "right"
          };
      },
      editable: true
    },
    {
      headerName: "AVL02 MAX",
      field: "AVL02Max",
      cellStyle: function(params) {
        if (
          (params.data.AVL02Max &&
            Number(params.data.AVL02Min) > Number(params.data.AVL02Max)) ||
          Math.sign(Number(params.data.AVL02Max)) == -1 ||
          Number.isInteger(Number(params.data.AVL02Max)) == false
        )
          return {
            "background-color": "#f77b65",
            "border-left": "1px solid grey",
            "text-align": "right"
          };
        else
          return {
            "background-color": "#F4F6F8",
            "border-left": "1px solid grey",
            "text-align": "right"
          };
      },
      editable: true
    },
    {
      headerName: "AVL03 MIN",
      field: "AVL03Min",
      cellStyle: function(params) {
        if (
          (params.data.AVL03Max &&
            Number(params.data.AVL03Min) > Number(params.data.AVL03Max)) ||
          Math.sign(Number(params.data.AVL03Min)) == -1 ||
          Number.isInteger(Number(params.data.AVL03Min)) == false
        )
          return {
            "background-color": "#f77b65",
            "border-left": "1px solid grey",
            "text-align": "right"
          };
        else
          return {
            "background-color": "#F4F6F8",
            "border-left": "1px solid grey",
            "text-align": "right"
          };
      },
      editable: true
    },
    {
      headerName: "AVL03 MAX",
      field: "AVL03Max",
      cellStyle: function(params) {
        if (
          (params.data.AVL03Max &&
            Number(params.data.AVL03Min) > Number(params.data.AVL03Max)) ||
          Math.sign(Number(params.data.AVL03Max)) == -1 ||
          Number.isInteger(Number(params.data.AVL03Max)) == false
        )
          return {
            "background-color": "#f77b65",
            "border-left": "1px solid grey",
            "text-align": "right"
          };
        else
          return {
            "background-color": "#F4F6F8",
            "border-left": "1px solid grey",
            "text-align": "right"
          };
      },
      editable: true
    },
    {
      headerName: "AVL04 MIN",
      field: "AVL04Min",
      cellStyle: function(params) {
        if (
          (params.data.AVL04Max &&
            Number(params.data.AVL04Min) > Number(params.data.AVL04Max)) ||
          Math.sign(Number(params.data.AVL04Min)) == -1 ||
          Number.isInteger(Number(params.data.AVL04Min)) == false
        )
          return {
            "background-color": "#f77b65",
            "border-left": "1px solid grey",
            "text-align": "right"
          };
        else
          return {
            "background-color": "#F4F6F8",
            "border-left": "1px solid grey",
            "text-align": "right"
          };
      },
      editable: true
    },
    {
      headerName: "AVL04 MAX",
      field: "AVL04Max",
      cellStyle: function(params) {
        if (
          (params.data.AVL04Max &&
            Number(params.data.AVL04Min) > Number(params.data.AVL04Max)) ||
          Math.sign(Number(params.data.AVL04Max)) == -1 ||
          Number.isInteger(Number(params.data.AVL04Max)) == false
        )
          return {
            "background-color": "#f77b65",
            "border-left": "1px solid grey",
            "text-align": "right"
          };
        else
          return {
            "background-color": "#F4F6F8",
            "border-left": "1px solid grey",
            "text-align": "right"
          };
      },
      editable: true
    },
    {
      headerName: "AVL05 MIN",
      field: "AVL05Min",
      cellStyle: function(params) {
        if (
          (params.data.AVL05Max &&
            Number(params.data.AVL05Min) > Number(params.data.AVL05Max)) ||
          Math.sign(Number(params.data.AVL05Min)) == -1 ||
          Number.isInteger(Number(params.data.AVL05Min)) == false
        )
          return {
            "background-color": "#f77b65",
            "border-left": "1px solid grey",
            "text-align": "right"
          };
        else
          return {
            "background-color": "#F4F6F8",
            "border-left": "1px solid grey",
            "text-align": "right"
          };
      },
      editable: true
    },
    {
      headerName: "AVL05 MAX",
      field: "AVL05Max",
      cellStyle: function(params) {
        if (
          (params.data.AVL05Max &&
            Number(params.data.AVL05Min) > Number(params.data.AVL05Max)) ||
          Math.sign(Number(params.data.AVL05Max)) == -1 ||
          Number.isInteger(Number(params.data.AVL05Max)) == false
        )
          return {
            "background-color": "#f77b65",
            "border-left": "1px solid grey",
            "text-align": "right"
          };
        else
          return {
            "background-color": "#F4F6F8",
            "border-left": "1px solid grey",
            "text-align": "right"
          };
      },
      editable: true
    },
    {
      headerName: "AVL06 MIN",
      field: "AVL06Min",
      cellStyle: function(params) {
        if (
          (params.data.AVL06Max &&
            Number(params.data.AVL06Min) > Number(params.data.AVL06Max)) ||
          Math.sign(Number(params.data.AVL06Min)) == -1 ||
          Number.isInteger(Number(params.data.AVL06Min)) == false
        )
          return {
            "background-color": "#f77b65",
            "border-left": "1px solid grey",
            "text-align": "right"
          };
        else
          return {
            "background-color": "#F4F6F8",
            "border-left": "1px solid grey",
            "text-align": "right"
          };
      },
      editable: true
    },
    {
      headerName: "AVL06 MAX",
      field: "AVL06Max",
      cellStyle: function(params) {
        if (
          (params.data.AVL06Max &&
            Number(params.data.AVL06Min) > Number(params.data.AVL06Max)) ||
          Math.sign(Number(params.data.AVL06Max)) == -1 ||
          Number.isInteger(Number(params.data.AVL06Max)) == false
        )
          return {
            "background-color": "#f77b65",
            "border-left": "1px solid grey",
            "text-align": "right"
          };
        else
          return {
            "background-color": "#F4F6F8",
            "border-left": "1px solid grey",
            "text-align": "right"
          };
      },
      editable: true
    },
    {
      headerName: "AVL07 MIN",
      field: "AVL07Min",
      cellStyle: function(params) {
        if (
          (params.data.AVL07Max &&
            Number(params.data.AVL07Min) > Number(params.data.AVL07Max)) ||
          Math.sign(Number(params.data.AVL07Min)) == -1 ||
          Number.isInteger(Number(params.data.AVL07Min)) == false
        )
          return {
            "background-color": "#f77b65",
            "border-left": "1px solid grey",
            "text-align": "right"
          };
        else
          return {
            "background-color": "#F4F6F8",
            "border-left": "1px solid grey",
            "text-align": "right"
          };
      },
      editable: true
    },
    {
      headerName: "AVL07 MAX",
      field: "AVL07Max",
      cellStyle: function(params) {
        if (
          (params.data.AVL07Max &&
            Number(params.data.AVL07Min) > Number(params.data.AVL07Max)) ||
          Math.sign(Number(params.data.AVL07Max)) == -1 ||
          Number.isInteger(Number(params.data.AVL07Max)) == false
        )
          return {
            "background-color": "#f77b65",
            "border-left": "1px solid grey",
            "text-align": "right"
          };
        else
          return {
            "background-color": "#F4F6F8",
            "border-left": "1px solid grey",
            "text-align": "right"
          };
      },
      editable: true
    },
    {
      headerName: "AVL08 MIN",
      field: "AVL08Min",
      cellStyle: function(params) {
        if (
          (params.data.AVL08Max &&
            Number(params.data.AVL08Min) > Number(params.data.AVL08Max)) ||
          Math.sign(Number(params.data.AVL08Min)) == -1 ||
          Number.isInteger(Number(params.data.AVL08Min)) == false
        )
          return {
            "background-color": "#f77b65",
            "border-left": "1px solid grey",
            "text-align": "right"
          };
        else
          return {
            "background-color": "#F4F6F8",
            "border-left": "1px solid grey",
            "text-align": "right"
          };
      },
      editable: true
    },
    {
      headerName: "AVL08 MAX",
      field: "AVL08Max",
      cellStyle: function(params) {
        if (
          (params.data.AVL08Max &&
            Number(params.data.AVL08Min) > Number(params.data.AVL08Max)) ||
          Math.sign(Number(params.data.AVL08Max)) == -1 ||
          Number.isInteger(Number(params.data.AVL08Max)) == false
        )
          return {
            "background-color": "#f77b65",
            "border-left": "1px solid grey",
            "text-align": "right"
          };
        else
          return {
            "background-color": "#F4F6F8",
            "border-left": "1px solid grey",
            "text-align": "right"
          };
      },
      editable: true
    },
    {
      headerName: "AVL09 MIN",
      field: "AVL09Min",
      cellStyle: function(params) {
        if (
          (params.data.AVL09Max &&
            Number(params.data.AVL09Min) > Number(params.data.AVL09Max)) ||
          Math.sign(Number(params.data.AVL09Min)) == -1 ||
          Number.isInteger(Number(params.data.AVL09Min)) == false
        )
          return {
            "background-color": "#f77b65",
            "border-left": "1px solid grey",
            "text-align": "right"
          };
        else
          return {
            "background-color": "#F4F6F8",
            "border-left": "1px solid grey",
            "text-align": "right"
          };
      },
      editable: true
    },
    {
      headerName: "AVL09 MAX",
      field: "AVL09Max",
      cellStyle: function(params) {
        if (
          (params.data.AVL09Max &&
            Number(params.data.AVL09Min) > Number(params.data.AVL09Max)) ||
          Math.sign(Number(params.data.AVL09Max)) == -1 ||
          Number.isInteger(Number(params.data.AVL09Max)) == false
        )
          return {
            "background-color": "#f77b65",
            "border-left": "1px solid grey",
            "text-align": "right"
          };
        else
          return {
            "background-color": "#F4F6F8",
            "border-left": "1px solid grey",
            "text-align": "right"
          };
      },
      editable: true
    },
    {
      headerName: "DROP SHIP",
      field: "supplyingSource",
      cellRenderer: "CellRenderCheckBox",
      pinned: "right",
      filter: false,
      cellStyle: function(params) {
        return { "text-align": "right" };
      }
    },
    {
      headerName: "DC20",
      field: "dcTwenty",
      cellRenderer: "CellRenderCheckBoxDcTwenty",
      pinned: "right",
      filter: false,
      cellStyle: function(params) {
        return { "text-align": "right" };
      }
    }
  ]
};
