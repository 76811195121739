import { dateFormate, dateFormatter } from '@/helpers/formatters'
export default {
  "defaultColDef": 
  {
    "animateRows": true,
    "sortable": true,
    "unSortIcon": true
  },
  animateRows: true,
  animateRows: true,
  sortable: true,
  filter: true,
  unSortIcon: true,
  suppressCellSelection: true,
  cellClass: 'no-border',

  columnDefs: [

    {
      resizable: true,
      headerName: 'ARTICLE',
      filter: 'agTextColumnFilter',
      field: 'article',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: params => {
        if (params.data.lms.length == 0 || params.data.ohi.length == 0 || params.data.ohia.length == 0 || params.data.ooi.length == 0 || params.data.ooia.length == 0) {
          params.node.selectable = false
          return false
        }
        else {
          params.node.selectable = true
          return true
        }
      },
      selectable: false,
      pinned: 'left',
      width: 150,
      cellClassRules: 'readonly-cell',
      suppressCellSelection: true,
      cellClass: 'no-border',
      cellRenderer: 'agGroupCellRenderer'
    },
    {
      resizable: true,
      headerName: 'ARTICLE DESC',
      filter: 'agTextColumnFilter',
      field: 'article_descr',
      align: 'centre'
    },
    {
      resizable: true,
      headerName: 'CHAR COLLECTION',
      filter: 'agTextColumnFilter',
      field: 'collection',
    },
    {
      resizable: true,
      headerName: 'MERCH CAT',
      filter: 'agTextColumnFilter',
      field: 'merch_cat_descr',
      width: 150
    },
    {
      resizable: true,
      headerName: 'CATEGORY',
      filter: 'agTextColumnFilter',
      field: 'category_descr',
      width: 120
    },
    {
      resizable: true,
      headerName: 'SHOP',
      filter: 'agTextColumnFilter',
      field: 'shop_descr',
      width: 150
    },
    {
      resizable: true,
      headerName: 'BUSINESS',
      filter: 'agTextColumnFilter',
      field: 'business_descr'
    },
    {
      resizable: true,
      headerName: 'IN STORE DATE',
      filter: 'agTextColumnFilter',
      field: 'in_store_date',
      width: 120,
      valueFormatter: dateFormatter
    },
    {
      resizable: true,
      headerName: 'SEASON START FW',
      filter: 'agTextColumnFilter',
      field: 'seasonStartFiscalWeek',
      width: 120
    },
    {
      headerName: "PACK",
      field: "pack",
      filter: 'agTextColumnFilter',
    },
    {
      resizable: true,
      headerName: 'DELIVERY DATE',
      filter: 'agTextColumnFilter',
      field: 'deliveryDate',
      width: 120,
      valueFormatter: dateFormate
    }
  ]
}
