<template>
  <div>
    <b-tabs content-class="mt-3 mb-3">
      <b-tab title="Store Level" active>
        <p></p>
        <div v-if="getJsonData">
          <b-card>
            <div slot="header">
              <strong>Finalize Allocation</strong>
            </div>
            <div>
              <data-table
                :data="getJsonData.finalizeAllocation"
                :count="getJsonData.finalizeAllocation.length"
                :colDefs="finalixeAllocationColDef"
                :allowUpdateInDataTable="false"
                :key="forceRender1"
                @cellValueChanged="cellValueChanged"
                @selectedItems="(v) => (selectedItems = v)"
                @updatedSuccesfully="massUpdateTargetCells"
                :isTextWrap="true"
              >
                <!-- @onUpdateDcLevelData="updateDcLevelData" -->
                <template slot-scope="props">
                  Actions:
                  <span>
                    <!-- <i

                      v-b-tooltip.hover
                      title="Please select an article to use this option"
                      class="fas fa-paint-brush cursor-not-allowed paintBrush"
                      style="margin-right:1rem; font-size: 0.8rem"
                    ></i>-->
                    <i
                      class="fas fa-paint-brush cursor-pointer paintBrush"
                      style="margin-right: 1rem; font-size: 0.8rem"
                      id="massUpdatePopOverStep5"
                    ></i>
                    <!-- <i
                      v-if="!selectedItems.length"
                      v-b-tooltip.hover
                      title="Please select an article to use this option"
                      class="fas fa-paint-brush cursor-not-allowed"
                      style="margin-right:0.5rem"
                    ></i>
                    <i
                      v-else
                      class="fas fa-paint-brush cursor-pointer"
                      style="margin-right:0.5rem; font-size: 0.8rem"
                      id="massUpdatePopOverStep5"
                    ></i>-->
                  </span>
                  <!-- <span>
                    <i
                      :class="`fas fa-paint-brush ${!selectedItems.length ? 'cursor-not-allowed' : 'cursor-pointer'}`"
                      style="margin-right:1rem"
                      :disabled="!selectedItems.length"
                      id="massUpdatePopOverStep5"
                    ></i>
                  </span>-->
                  <span>
                    <b-button
                      class="btn-download"
                      @click="editTemplatedClicked"
                      download
                    >
                      Edit Template
                      <i class="fa fa-edit" style="padding-left: 0.25rem"></i>
                    </b-button>
                    <input
                      type="file"
                      @change="onFileUpload"
                      hidden
                      ref="inputFile"
                    />
                  </span>
                  <span>
                    <b-button
                      class="btn-download"
                      style="margin-left: 5px"
                      @click="onDownloadClick"
                      download
                    >
                      Download Allocation
                      <i
                        class="fa fa-download"
                        style="padding-left: 0.25rem"
                      ></i>
                    </b-button>
                    <!-- <i
                      v-b-tooltip.hover
                      title="Download Template"
                      class="fa fa-download cursor-pointer"
                      style="margin-right:1rem;font-size: 1.5rem; color: #70BE00;font-weight: 500;"
                      @click="onDownloadClick"
                    ></i>-->
                  </span>
                  <span>
                    <b-button
                      class="btn-download"
                      style="margin-left: 5px"
                      @click="onUploadClick"
                      download
                    >
                      Upload Allocation
                      <i class="fa fa-upload" style="padding-left: 0.25rem"></i>
                    </b-button>
                    <!-- <i
                      v-b-tooltip.hover
                      title="Upload Template"
                      class="fa fa-upload cursor-pointer"
                      style="margin-right:1rem;font-size: 1.1rem; color: #70BE00;font-weight: 500;"
                      @click="onUploadClick"
                    ></i>-->
                  </span>
                  <b-popover
                    target="massUpdatePopOverStep5"
                    triggers="click"
                    :show.sync="popoverShow"
                    placement="auto"
                    container="myContainer"
                    ref="popover"
                    @show="onShow"
                  >
                    <div v-on-click-outside="close">
                      <template slot="title">
                        <b-button
                          class="close"
                          style="color: #ffffff"
                          aria-label="Close"
                          @click="onClosePopOver"
                        >
                          <span class="d-inline-block" aria-hidden="true"
                            >&times;</span
                          > </b-button
                        >Apply Values on Multiple Selection
                      </template>

                      <div>
                        <b-form-group>
                          <b-row class="my-1">
                            <b-col sm="8">
                              <label style="margin-top: 0.5rem"
                                >Allocation in Units:</label
                              >
                            </b-col>
                            <b-col sm="4">
                              <b-form-input
                                placeholder="Units"
                                v-model="setTargetAllocationUnit"
                                @change="onTargetAllocation"
                                type="text"
                                width="50%"
                              />
                            </b-col>
                          </b-row>
                        </b-form-group>

                        <b-button
                          @click="onApplyClicked(props.onUpdate)"
                          size="sm"
                          variant="primary"
                          >Apply</b-button
                        >
                      </div>
                    </div>
                  </b-popover>
                </template>
              </data-table>
            </div>
            <!-- <div class="mt-2">
          <b-button style="height:100%; width: 200px; background-color:red; color:white" class="p-2">RESET ALLOCATION</b-button>
            </div>-->
          </b-card>
          <b-modal
            v-model="onSuccess"
            centered
            title="BootstrapVue"
            ok-only
            hide-header
          >
            <div align="middle">
              <h5 class="my-4">This information was saved successfully.</h5>
            </div>
          </b-modal>
        </div>
      </b-tab>
      <b-tab title="DC Level" @click="onTabChange">
        <div v-if="dcLevelData1 && dcLevelData1.length">
          <b-card>
            <div slot="header">
              <strong>Finalize Allocation</strong>
            </div>
            <div>
              <data-table
                :data="dcLevelData1"
                :count="dcLevelData1.length"
                :colDefs="FinalizeAllocationDCLevelColDef"
                :autoResize="false"
                :key="forceRender2"
                :dcLevelColor="true"
                :allowUpdateInDataTable="false"
              >
                <template slot-scope="prop">
                  <span>
                    <b-button
                      class="btn-download"
                      style="margin-left: 5px"
                      @click="
                        prop.downloadTable('Download_DC_Level_Allocation')
                      "
                      download
                    >
                      Download DC Level Allocation
                      <i
                        class="fa fa-download cursor-not-allowed"
                        style="padding-left: 0.25rem; color: #73bd00 !important"
                      ></i>
                    </b-button>
                  </span>
                </template>
              </data-table>
            </div>
          </b-card>
          <b-modal
            v-model="onSuccess"
            centered
            title="BootstrapVue"
            ok-only
            hide-header
          >
            <div align="middle">
              <h5 class="my-4">This information was saved successfully.</h5>
            </div>
          </b-modal>
        </div>
      </b-tab>
    </b-tabs>
    <!-- <div
      style="color: red;"
      align="center"
      v-if="uploadFlag"
    >{{errorMesg}}</div>-->
    <b-alert v-model="uploadFlag" variant="danger" dismissible
      >!! {{ errorMesg }}</b-alert
    >
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import DataTable from "@/components/DataTable";
import { isEqual, pick } from "lodash";
import Swal from "sweetalert2/dist/sweetalert2.js";
import finalixeAllocationColDef from "@/constants/tableDefinitions/finalixeAllocationColDef";
import FinalizeAllocationDCLevelColDef from "@/constants/tableDefinitions/FinalizeAllocationDCLevelColDef";
var handler = function (e) {
  var confirmationMessage = "Your unsave data will lost.";
  let tab = e || window.event;
  tab.returnValue = confirmationMessage;
  return confirmationMessage;
};
export default {
  data() {
    return {
      dcLevelData1: null,
      forceRender1: false,
      forceRender2: false,
      errorMesg: "",
      uploadFlag: false,
      DcLevelData: [],
      FinalizeAllocationDCLevelColDef,
      dialogOptions: {
        cancelText: "Cancel",
        animation: "bounce",
        backdropClose: true,
      },
      fileSelector: null,
      flagDownload: false,
      selectedFile: null,
      tempGridApi: null,
      selectedItems: [],
      message: "This step may take some time. Thank you for your patience.",
      onSuccess: false,
      objsToUpdate: {},
      popoverShow: false,
      columToUpdae: "",
      valueToUpdate: "",
      setTargetAllocationUnit: "",
      finalixeAllocationColDef,
    };
  },
  props: ["isStep5ForceRenderer", "updatePayloadForR"],
  methods: {
    ...mapActions({
      downloadFinalizeAllocationReport:
        "CreateAllocation/downloadFinalizeAllocationReport",
      uploadFinalizeAllocationReport:
        "CreateAllocation/uploadFinalizeAllocationReport",
      saveAllocationDetails: "CreateAllocation/saveAllocationDetails",
      saveAllocationData: "CreateAllocation/saveAllocationData",
      editTemplate: "CreateAllocation/editTemplate",
    }),
    ...mapMutations({
      setLoader: "CreateAllocation/setLoader",
      setJsonData: "CreateAllocation/setJsonData",
      setTempJsonData: "CreateAllocation/setTempJsonData",
      setLoaderText: "CreateAllocation/setLoaderText",
    }),
    ...mapGetters({      
      getLoaderText: "CreateAllocation/getLoaderText",
    }),
    editTemplatedClicked() {
      this.$refs.inputFile.click();

      this.$gtag.event('Clicked_EditTemplateButton', {
        'event_category': 'Finalize Allocation Screen',
        'event_label': 'Click on Edit Template Button',
      })
    },
    onFileUpload() {
      this.$dialog
        .confirm("Do you really want to update the template ? By clicking on continue your data will be updated in all screens.")
        .then((res) => {
          this.setLoaderText("Re-allocation is in progress. Thanks for your patience");
          this.setLoader(true);
          this.editTemplate({
            allocationID: this.getJsonData.allocationBasics.allocationID,
            file: this.$refs.inputFile.files[0],
            name: this.getTempJsonData.allocationBasics.name,
            comments: this.getTempJsonData.allocationBasics.comments,
          })
            .then((res) => {
              this.setLoader(true);

              this.updatePayloadForR()
                .then(() => {
                  this.getJsonData.allocationBasics.allocationStage = "4";
                  let jsonData = this.getJsonData;
                  this.setLoader(true);
                  this.saveAllocationData({
                    allocationID: this.getJsonData.allocationBasics.allocationID,
                    jsonData,
                  })
                    .then((res) => {
                      this.$dialog.alert(`Template has been updated.`, {html: true, okText: 'OK',});
                      // Swal.fire({
                      //   text: "Template has been updated.",
                      //   icon: "success",
                      //   confirmButtonText: "Ok",
                      // });

                      this.$gtag.event('Clicked_ReuploadTemplateSuccessOKButton', {
                        'event_category': 'Finalize Allocation Screen',
                        'event_label': 'Click on Template Uploaded OK button',
                      })
                    })
                    .catch((err) => {
                    Swal.fire({
                      text: err.message,
                      icon: "warning",
                      confirmButtonText: "Ok",
                    });
                  }).finally(() => {
                    this.setLoaderText('');
                    this.setLoader(false);
                  });
                })
                .catch((err) => {
                  this.setLoaderText('')
                  Swal.fire({
                    text: err,
                    icon: "warning",
                    confirmButtonText: "Ok",
                  });
                });
            })
            .catch((err) => {
              this.setLoaderText('');
              this.setLoader(false);

              Swal.fire({
                text: err.message,
                icon: "warning",
                confirmButtonText: "Ok",
              });
            })
          this.$refs.inputFile.value = "";

          this.$gtag.event('Clicked_ReUploadTemplateContinueButton', {
        'event_category': 'Finalize Allocation Screen',
        'event_label': 'Click on Edit Template Continue Button',
      })
        })
        .catch(() => {
          this.$refs.inputFile.value = "";
        });
    },
    onTabChange(val) {
      let returnedObj = [];
      let len = this.getJsonData.finalizeAllocation.length;
      let articleDCRow = {};
      for (let i = 0; i < len; i++) {
        let finalizeAllocationRow = this.getJsonData.finalizeAllocation[i];
        let articleId = Number(finalizeAllocationRow["Article ID"]);
        let supplyDC = finalizeAllocationRow["Supplying DC"];
        if (typeof articleDCRow[articleId + supplyDC] !== "undefined") {
          articleDCRow[articleId + supplyDC].push(finalizeAllocationRow);
        } else {
          articleDCRow[articleId + supplyDC] = [finalizeAllocationRow];
        }
        finalizeAllocationRow = null;
      }
      let sum = 0;
      this.getJsonData.strategy.forEach((element) => {
        let tempObj = {};
        tempObj = { ...element };
        tempObj.allocatedTillDate =
          element.inSeasonSales + element.storeTotalOH + element.storeTotalOO;
        if (articleDCRow[Number(tempObj.productID) + tempObj.supplyingDC]) {
          sum = articleDCRow[
            Number(tempObj.productID) + tempObj.supplyingDC
          ].reduce(function (a, b) {
            return Number(a) + Number(b.Allocation);
          }, 0);
        } else {
          sum = 0;
        }
        tempObj.allocation = sum;
        tempObj.dcInventoryPostAllocation =
          element.availableInventory - tempObj.allocation;
        returnedObj.push(tempObj);
        tempObj = null;
      });
      this.dcLevelData1 = returnedObj;
      returnedObj = null;
    },
    close() {
      this.popoverShow = false;
    },
    onTargetAllocation(val) {
      if (val) {
        this.setTargetAllocationUnit = Number(val);
        this.objsToUpdate = { value: Number(val) };
      }
    },
    /************************************************************* removed DC Level Code Computation *********************************** */

    // updateDcLevelData() {
    //   let returnedObj = [];
    //   let len = this.getJsonData.finalizeAllocation.length;
    //   let obj = {};

    //   for (let i = 0; i < len; i++) {
    //     let aa = this.getJsonData.finalizeAllocation[i];
    //     let xx = Number(aa["Article ID"]);
    //     let supplyxx = aa["Supplying DC"];
    //     // let x = obj[xx +  supplyxx]
    //     if (typeof obj[xx + supplyxx] !== "undefined") {
    //       obj[xx + supplyxx].push(aa);
    //     } else {
    //       obj[xx + supplyxx] = [aa];
    //     }
    //   }
    //   let sum = 0;

    //   this.getJsonData.strategy.forEach(element => {
    //     let tempObj = {};
    //     tempObj = element;
    //     tempObj.allocatedTillDate =
    //       element.inSeasonSales + element.storeTotalOH + element.storeTotalOO;
    //     if (obj[Number(tempObj.productID) + tempObj.supplyingDC]) {
    //       sum = obj[Number(tempObj.productID) + tempObj.supplyingDC].reduce(
    //         function(a, b) {
    //           return parseInt(a) + parseInt(b.Allocation);
    //         },
    //         0
    //       );
    //     } else {
    //       sum = 0;
    //     }
    //     tempObj.allocation = sum;
    //     tempObj.dcInventoryPostAllocation =
    //       element.availableInventory - tempObj.allocation;
    //     returnedObj.push(tempObj);
    //   });
    //   this.getJsonData.getDcLevelData = returnedObj;
    //   returnedObj = [];
    //   // if (this.getJsonData && this.getJsonData.getDcLevelData) {
    //   //   this.setTempJsonData(this.getJsonData);
    //   // }
    // },
    /************************************************************* removed DC Level Code Computation *********************************** */

    cellValueChanged(val) {
      this.uploadFlag = false;
      if (val.eventVal.colDef.field == "Allocation") {
        let allocationInput = parseInt(val.eventVal.value);
        let pack = parseInt(val.eventVal.data.Pack);
        if (allocationInput % pack == 0) {
          // this.updateDcLevelData();
          val.eventVal.data["Total Fill"] =
            allocationInput +
            val.eventVal.data["Store OO"] +
            val.eventVal.data["Store OH"];
          val.gridOption.rowStyle = { background: "white" };
          //val.gridApi.redrawRows({ rowNodes: [element] });
          let disableFlag = isEqual(
            this.getTempJsonData.finalizeAllocation,
            this.getJsonData.finalizeAllocation
          );
          this.$root.$emit("step5Flag", disableFlag);
          this.forceRender2 = !this.forceRender2;
        } else {
          val.eventVal.data.Allocation = val.eventVal.oldValue;
          val.gridOption.rowStyle = { background: "#ecd1cc" };
          val.gridApi.redrawRows({ rowNodes: [element] });
        }
      }
      let disableFlag = isEqual(
        this.getTempJsonData.finalizeAllocation,
        this.getJsonData.finalizeAllocation
      );
      this.$root.$emit("step5Flag", disableFlag);
    },
    onUploadClick() {
      // let onChangeFlag = false
      if (this.fileSelector) {
        this.fileSelector.removeAttribute("type");
        this.fileSelector.removeAttribute("id");
      }
      this.fileSelector = document.createElement("input");
      this.fileSelector.setAttribute("type", "file");
      this.fileSelector.setAttribute("id", "finalizeUploadTemplate1");
      var selectDialogueLink = document.createElement("a");
      selectDialogueLink.setAttribute("href", "");
      selectDialogueLink.innerText = "Select File";
      document.body.appendChild(this.fileSelector);
      this.fileSelector.click();
      this.fileSelector.onchange = () => {
        // onChangeFlag = true
        this.selectedFile = document.getElementById(
          "finalizeUploadTemplate1"
        ).files[0];
        this.uploadFinalizeAllocationReport({
          allocationID: this.getJsonData.allocationBasics.allocationID,
          file: this.selectedFile,
        })
          .then((res) => {
            // this.setJsonData(res.jsonData)
            // this.setLoader(false)
            this.flagDownload = true;
            // this.updateDcLevelData();
            let disableFlag = isEqual(
              this.getTempJsonData.finalizeAllocation,
              this.getJsonData.finalizeAllocation
            );
            this.$root.$emit("step5Flag", disableFlag);
            this.forceRender2 = !this.forceRender2;
          })
          .catch((err) => {
            this.uploadFlag = true;
            this.errorMesg = err.message;
            // this.setLoader(false)
            this.flagDownload = true;
          });
        this.selectedFile = null;
      };
      // if(!onChangeFlag) {
      //     this.flagDownload = true;
      //   }

      this.$gtag.event('Clicked_UploadAllocationButton', {
        'event_category': 'Finalize Allocation Screen',
        'event_label': 'Click on Upload Allocation Button',
      })
    },
    massUpdateTargetCells(val) {
      // val.gridOption.api.refreshCells()
      // this.forceRender = !this.forceRender
      // this.updateDcLevelData();
      // let disableFlag = isEqual(this.getTempJsonData.finalizeAllocation, this.getJsonData.finalizeAllocation)
      // this.$root.$emit("step5Flag",disableFlag)
    },
    //   massUpdateTargetCells(val) {
    //     this.uploadFlag = false
    //     let allocationInput = parseInt(this.setTargetAllocationUnit);
    //     let context = this;
    //     let rows = [];
    //     this.selectedItems.forEach(element => {
    //      // let element = element;
    //       let pack = parseInt(element.Pack);
    //       if (allocationInput % pack == 0) {
    //         val.gridOption.rowStyle = null;
    //         val.gridApi.redrawRows({ rowNodes: [element] });
    //         element["Allocation"] = allocationInput;
    //         element["Total Fill"] =
    //           allocationInput + element["Store OO"] + element["Store OH"];
    //          // element.Allocation = allocationInput.toString()
    //       } else {
    //         // rows.push(rowNode);
    //         val.gridOption.rowStyle = { background: "#ecd1cc" };
    //         val.gridApi.redrawRows({ rowNodes: [element] });
    //       }
    //     });
    //     this.updateDcLevelData();
    //  // val.gridOptions.api.deselectAll();
    //  let disableFlag = isEqual(this.getTempJsonData.finalizeAllocation, this.getJsonData.finalizeAllocation)
    //   this.$root.$emit("step5Flag",disableFlag)
    // //  this.forceRender = !this.forceRender
    //    //this.selectedItems = []
    //   // this.selectedItems = null;
    //   },
    onDownloadClick() {
      let disableFlag = true;
      this.$root.$emit("step5Flag", disableFlag);
      this.uploadFlag = false;
      if (
        !isEqual(
          this.getTempJsonData.finalizeAllocation,
          this.getJsonData.finalizeAllocation
        )
      ) {
        this.$dialog
          .confirm(
            "Do you want to save changes and then download the template",
            this.dialogOptions
          )
          .then(() => {
            this.saveAllocationDetails({
              allocationID: this.getJsonData.allocationBasics.allocationID,
              jsonData: this.getJsonData,
            }).then(() => {
              this.downloadFinalizeAllocationReport({
                allocationID: this.getJsonData.allocationBasics.allocationID,
              }).then((data) => {
                let a2 = document.createElement("A");
                a2.href = data.data.url;
                a2.setAttribute("target", "_blank");
                document.body.appendChild(a2);
                a2.click();
                document.body.removeChild(a2);
              });
            });
          })
          .catch((e) => {
            this.downloadFinalizeAllocationReport({
              allocationID: this.getJsonData.allocationBasics.allocationID,
            }).then((data) => {
              let a2 = document.createElement("A");
              a2.href = data.data.url;
              a2.setAttribute("target", "_blank");
              document.body.appendChild(a2);
              a2.click();
              document.body.removeChild(a2);
            });
          });
      } else {
        this.downloadFinalizeAllocationReport({
          allocationID: this.getJsonData.allocationBasics.allocationID,
        }).then((data) => {
          let a2 = document.createElement("A");
          a2.href = data.data.url;
          a2.setAttribute("target", "_blank");
          document.body.appendChild(a2);
          a2.click();
          document.body.removeChild(a2);
        });
      }

      this.$gtag.event('Clicked_DownloadAllocationButton', {
        'event_category': 'Finalize Allocation Screen',
        'event_label': 'Click on Download Allocation Button',
      })
    },
    onClosePopOver() {
      this.popoverShow = false;
    },
    onShow() {
      this.setTargetAllocationUnit = "";
    },
    onApplyClicked(onUpdateFunc) {
      this.uploadFlag = false;
      this.popoverShow = false;
      onUpdateFunc(this.objsToUpdate);
      // this.updateDcLevelData();
      let disableFlag = isEqual(
        this.getTempJsonData.finalizeAllocation,
        this.getJsonData.finalizeAllocation
      );
      this.$root.$emit("step5Flag", disableFlag);
      this.forceRender2 = !this.forceRender2;

      this.$gtag.event('Clicked_FinalizeAllocationApplyButton', {
        'event_category': 'Finalize Allocation Screen',
        'event_label': 'Click on Finalize Allocation Apply button',
      })
    },
  },
  components: {
    DataTable,
  },
  computed: {
    ...mapGetters({
      getJsonData: "CreateAllocation/getJsonData",
      getTempJsonData: "CreateAllocation/getTempJsonData",
    }),
    // getDcLevelData() {
    //   this.loaderState = true;

    // },
  },
  updated() {
    // this.setLoader(false)
  },
  mounted() {
    // this.getJsonData.finalizeAllocation.forEach(item => {
    //   if (item['supplying_dc'] == "DC01" || item['supplying_dc'] == "DC06") {
    //     item["supplyingSource"] = "DC";
    //     item["supplying_dc"] = "DC20";
    //   }
    // });
    // this.setJsonData(this.getJsonData)
    // this.setLoader(true)
    // let returnedObj = [];
    // let len = this.getJsonData.finalizeAllocation.length;
    // let obj = {}
    // for(let i=0 ; i<len; i++ ){
    //   let aa = this.getJsonData.finalizeAllocation[i]
    //   let xx = Number(aa['Article ID'])
    //   let supplyxx = aa['Supplying DC']
    //   //let x = obj[xx+supplyxx]
    //   if(typeof obj[xx+supplyxx] !== 'undefined') {
    //    obj[xx+supplyxx].push(aa)
    //   } else {
    //     obj[xx+supplyxx] = [aa]
    //   }
    // }
    // let sum = 0;
    // this.getJsonData.strategy.forEach(element => {
    //   let tempObj = {}
    //     tempObj = element
    //     tempObj.allocatedTillDate =
    //       element.inSeasonSales +
    //       element.storeTotalOH +
    //       element.storeTotalOO;
    // if(obj[Number(tempObj.productID)+tempObj.supplyingDC]){
    //   sum = obj[Number(tempObj.productID)+tempObj.supplyingDC].reduce(function(a, b) {
    //     return parseInt(a) + parseInt(b.Allocation);
    //   }, 0);}
    //   else
    //   {
    //     sum = 0
    //   }
    //   tempObj.allocation = sum;
    //   tempObj.dcInventoryPostAllocation =
    //     element.availableInventory - tempObj.allocation;
    //   returnedObj.push(tempObj);
    // });
    //  this.getDcLevelData= returnedObj;
    //  returnedObj = []
  },
  beforeDestroy() {
    //   if(this.getTempJsonData){
    //    this.setTempJsonData(null)
    //   //  delete this.getTempJsonData
    //  }
    this.selectedItems = null;
    // delete this.getJsonData.DCLevelData
    // this.getJsonData.destroy()
    // this.getTempJsonData.destroy()
    window.removeEventListener("beforeunload", handler);
    this.getDcLevelData = null;
    this.finalixeAllocationColDef = null;
    this.FinalizeAllocationDCLevelColDef = null;
    this.dcLevelData1 = null;
  },
  // beforeDestroy() {
  //   this.setJsonData(null)
  // },
  created() {
    window.addEventListener("beforeunload", handler);
  },
  watch: {
    isStep5ForceRenderer() {
      this.forceRender1 = !this.forceRender1;
    },
    flagDownload(val) {
      if (val) {
        document.body.removeChild(this.fileSelector);
        this.flagDownload = false;
      }
    },
    getJsonData(val) {
      val.finalizeAllocation.forEach((obj) => {
        if (!obj.Max) {
          obj.Max = "NA";
        }
      });
      // this.DcLevelData = val.strategy;
    },
  },
};
</script>

<style scoped>
.fa,
.fas {
  font-weight: 900;
  color: royalblue;
  padding-left: 5px;
}
.btn-save {
  background-color: rgb(115, 189, 0);
  border-color: rgb(115, 189, 0);
  color: white;
  box-sizing: border-box;
  border-width: 2px;
  font-size: 14px;
  font-weight: 600;
  padding: 6px 12px;
  min-width: 100px;
}
.modal-footer .btn {
  background-color: rgb(115, 189, 0) !important;
  border-color: rgb(115, 189, 0) !important;
  color: white !important;
}
.btn-download {
  color: #71be00 !important;
  border-color: #71be00;
  background-color: rgb(255, 255, 255) !important;
}
.btn-download:hover {
  color: #71be00 !important;
  border-color: #71be00;
  background-color: rgb(202, 213, 219) !important;
}
</style>
