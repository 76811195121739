<template>
  <div>
    <!-- <vue-element-loading :active="getLoader" spinner="bar-fade-scale" text="This step may take a couple of minutes. Thank you for your patience.."/> -->
    <wizard @onStep1form="onStep1form" :stepStartIndex="stepStartIndex"></wizard>
    <!-- <offline-page :show="show"></offline-page> -->
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import Wizard from "@/components/Wizard";
import OfflinePage from "@/components/offlinePage";
export default {
  components: {
    Wizard,
    OfflinePage
  },
  computed: {
    ...mapGetters({
      getLoader: "CreateAllocation/getLoader",
      getJsonData: "CreateAllocation/getJsonData",
      getSitesByIds: "CreateAllocation/getSitesByIds"
    })
  },
  data() {
    return {
      stepStartIndex: null,
      show: false
    };
  },
  created() {
    this.actionGetStaticData();
    this.fetchMinMaxDateAndFW();
  },
  mounted() {
    if (this.$route.params.allocationId) {
      let context = this;
      this.sitesByIds({ siteIds: [] }).then(resp => {
        if (resp.sites.length) {
          this.allocationByAllocationId({
            allocationID: this.$route.params.allocationId
          })
            .then(result => {
              if (result.allocationBasics.allocationState === "DRAFT") {
                context.stepStartIndex =
                  parseInt(result.allocationBasics.allocationStage) - 1;
              } else {
                this.setJsonData(null);
                this.$router.push("/createAllocation");
                context.stepStartIndex = 0;
              }
            })
            .catch(err => {});
        }
      });
    } else {
      this.stepStartIndex = 0;
    }
  },
  beforeMount() {
    if (!window.navigator.onLine) {
      this.show = true;
    }
  },
  beforeDestroy() {
    this.setJsonData(null);
    this.setTempJsonData(null);
    this.setAllocationReport(null);
    window.localStorage.removeItem("siteData");
  },
  // watch: {
  //   getSitesByIds (val) {
  //     window.localStorage.setItem('siteData', JSON.stringify(val))
  //   }
  // },
  methods: {
    ...mapActions({
      sitesByIds: "CreateAllocation/sitesByIds",
      actionGetStaticData: "CreateAllocation/actionGetStaticData",
      fetchMinMaxDateAndFW: "CreateAllocation/fetchMinMaxDateAndFW",
      allocationByAllocationId: "CreateAllocation/allocationByAllocationId"
    }),
    ...mapMutations({
      setJsonData: "CreateAllocation/setJsonData",
      setTempJsonData: "CreateAllocation/setTempJsonData",
      setAllocationReport: "CreateAllocation/setAllocationReport"
    }),
    onStep1form(val) {
      this.basicsform = val;
    }
  }
};
</script>

<style>
div.card-header {
  padding: 0.75rem 1.25rem;
  color: #ffffff !important;
  margin-bottom: 0;
  background-color: #000000 !important;
  border-bottom: 1px solid #c8ced3;
}
h3.popover-header {
  color: #ffffff;
  background-color: #000000;
}
</style>
