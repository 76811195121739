import {
  percentageFormatternew, percentageFormatternew1,
  percentageFormatterWeight,
  percentageFormatternew2, decimalFormatter, decimalFormatterSalesBasis, decimalFormatterStep4
} from '@/helpers/formatters'

export default {
  animateRow: true,
  columnDefs: [
    {
      resizable: true,
      headerName: 'ARTICLE',
      field: 'productID',
      filter: 'agTextColumnFilter',
      sortable: true,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      sortable: true,
      pinned: "left",
      unSortIcon: true
    },
    {
      resizable: true,
      headerName: 'DC ID',
      field: 'supplyingDC',
      filter: 'agTextColumnFilter',
      sortable: true,
      pinned: "left",
      unSortIcon: true,
    },
    {
      resizable: true,
      headerName: 'CHAR COLLECTION',
      field: 'collection',
      filter: 'agTextColumnFilter',
      // valueFormatter: () => "GIRL/BOY",
      sortable: true,
    },
    {
      resizable: true,
      headerName: 'MERCH CAT',
      field: 'merchCategory',
      filter: 'agTextColumnFilter',
      sortable: true,
      unSortIcon: true,
    },
    {
      resizable: true,
      headerName: 'CATEGORY',
      field: 'category',
      filter: 'agTextColumnFilter',
      sortable: true,
      unSortIcon: true,
    },
    {
      resizable: true,
      headerName: 'UNRESTRICTED OH',
      field: 'unrestrictedInventory',
      filter: 'agTextColumnFilter',
      sortable: true,
      unSortIcon: true,
      headerTooltip: "Unrestricted OH",
      cellStyle: { "text-align": "right" }
    },
    {
      resizable: true,
      headerName: 'ON ORDER',
      field: 'orderedQuantity',
      filter: 'agTextColumnFilter',
      sortable: true,
      unSortIcon: true,
      headerTooltip: "DC On Order",
      cellStyle: { "text-align": "right" }
    },
    {
      resizable: true,
      headerName: 'IN TRANSIT',
      field: 'inTransitQuantity',
      filter: 'agTextColumnFilter',
      sortable: true,
      unSortIcon: true,
      headerTooltip: "DC In Transit",
      cellStyle: { "text-align": "right" }
    },
    {
      resizable: true,
      headerName: 'DC DEMAND',
      field: 'dcDemand',
      filter: 'agTextColumnFilter',
      sortable: true,
      unSortIcon: true,
      cellStyle: { "text-align": "right" }
    },
    {
      resizable: true,
      headerName: 'AVAILABLE OH+OO',
      field: 'availableInventory',
      filter: 'agTextColumnFilter',
      sortable: true,
      unSortIcon: true,
      valueFormatter: decimalFormatter,
      headerTooltip: "(Unrestricted Inventory - DC Demand + Ordered Quantity)",
      cellStyle: { "text-align": "right" }
    },
    {
      resizable: true,
      headerName: 'TOTAL BUY',
      field: 'totalBuy',
      valueSetter: function (params) {
        params.newValue = Number(params.newValue)
        if (typeof params.newValue === "number" && !isNaN(params.newValue)) {
          params.data.totalBuy = params.newValue
        } else {
          params.data.totalBuy = params.oldValue
        }
      },
      filter: false,
      sortable: true,
      editable: true,
      cellStyle: { 'background-color': '#F4F6F8', 'border-left': '1px solid grey', "text-align": "right" },
      unSortIcon: true,
      valueFormatter: decimalFormatter
    },
    {
      resizable: true,
      headerName: 'IN SEASON SALES',
      field: 'inSeasonSales',
      filter: false,
      sortable: true,
      unSortIcon: true,
      valueFormatter: decimalFormatter,
      cellStyle: { "text-align": "right" }
    },

    {
      resizable: true,
      headerName: 'STORE OH',
      field: 'storeTotalOH',
      filter: false,
      sortable: true,
      unSortIcon: true,
      valueFormatter: decimalFormatter,
      headerTooltip: "(Sum of inventory on hand at all stores in DC)",
      cellStyle: { "text-align": "right" }
    },
    {
      resizable: true,
      headerName: 'STORE OO',
      field: 'storeTotalOO',
      filter: false,
      sortable: true,
      unSortIcon: true,
      valueFormatter: decimalFormatter,
      headerTooltip: "(Sum of inventory on order at all stores in DC)",
      cellStyle: { "text-align": "right" }
    },
    {
      resizable: true,
      headerName: '% ALREADY ALLOC',
      field: 'percentageAlreadyAllocated',
      valueFormatter: percentageFormatternew1,
      filter: false,
      sortable: true,
      unSortIcon: true,
      cellStyle: function (params) {
        if (params.value >= 100) {
          return { "background-color": "#f77b65", "border-left": "1px solid grey", "text-align": "right" }
        } else {
          return { "background-color": "#FFFFFF", "border-left": "1px solid grey", "text-align": "right" }
        }
      }
    },
    {
      resizable: true,
      headerName: 'MIN INVENTORY',
      field: 'inventoryUnitsAllocatedForMinPres',
      filter: false,
      sortable: true,
      hide: true,
      valueFormatter: decimalFormatter,
      unSortIcon: true,
    },
    {
      resizable: true,
      headerName: '% ALLOCATION AFTER MIN',
      field: 'percentageAllocatedAfterMin',
      filter: false,
      valueFormatter: percentageFormatternew1,
      headerClass: 'multiline',
      sortable: true,
      hide: true,
      unSortIcon: true,
      aggFunc: 'sum',
      cellStyle: function (params) {
        if (params.value > 100)
          return { "background-color": "#f77b65", "border-left": "1px solid grey" }
      }
    },
    {
      resizable: true,
      headerName: 'TARGET ALLOC',
      field: 'targetAllocationUnits',
      filter: false,
      editable: true,
      valueFormatter: decimalFormatterStep4,
      sortable: true,
      valueSetter: function (params) {
        params.newValue = Number(params.newValue)
        if (typeof params.newValue === "number" && !isNaN(params.newValue)) {
          params.data.targetAllocationUnits = params.newValue
        } else {
          params.data.targetAllocationUnits = params.oldValue
        }
      },
      unSortIcon: true,
      cellStyle: function (params) {
        let targetUnit = Number(params.value);
        let targetPer = (targetUnit * 100) / params.data.totalBuy;
        if (params.value === "") {
          return { "background-color": "#F4F6F8", "border-left": "1px solid grey", "text-align": "right" }
        } else if (targetPer <= params.data.percentageAlreadyAllocated) {
          return { "background-color": "#f77b65", "border-left": "1px solid grey", "text-align": "right" }
        } else {
          return { 'background-color': '#F4F6F8', 'border-left': '1px solid grey', "text-align": "right" }
        }
      },
    },
    {
      resizable: true,
      headerName: 'TARGET ALLOC %',
      field: 'targetAllocationPercentage',
      valueFormatter: percentageFormatternew2,
      filter: false,
      editable: true,
      valueSetter: function (params) {
        params.newValue = Number(params.newValue)
        if (typeof params.newValue === "number" && !isNaN(params.newValue)) {
          params.data.targetAllocationPercentage = params.newValue
        } else {
          params.data.targetAllocationPercentage = params.oldValue
        }
      },
      // cellStyle: { 'background-color': '#F4F6F8', 'border-left': '1px solid grey' },
      cellStyle: function (params) {
        if (params.value === "") {
          return { "background-color": "#F4F6F8", "border-left": "1px solid grey", "text-align": "right" }
        } else if ((Number(params.value) <= params.data.percentageAlreadyAllocated)) {
          return { "background-color": "#f77b65", "border-left": "1px solid grey", "text-align": "right" }
        } else {
          return { "background-color": "#F4F6F8", "border-left": "1px solid grey", "text-align": "right" }
        }
      },
      sortable: true,
      unSortIcon: true,
    },
    {
      resizable: true,
      headerName: 'SALES START DT 1',
      field: 'salesHistoryStartDate1',
      filter: false,
      sortable: true,
      unSortIcon: true,
      cellRenderer: "SalesStartDateCellRender"

    },
    {
      resizable: true,
      headerName: 'SALES END DT 1',
      field: 'salesHistoryEndDate1',
      filter: false,
      sortable: true,
      unSortIcon: true,
      cellRenderer: "SalesEndDateCellRender"
    },

    {
      resizable: true,
      headerName: 'SALES DEPTH 1',
      field: 'merchHierarchy1',
      filter: false,
      sortable: true,
      unSortIcon: true,
      editable: false
    },
    {
      resizable: true,
      headerName: 'SALES ELEMENT 1',
      field: 'merchSelections1',
      filter: 'agTextColumnFilter',
      sortable: true,
      unSortIcon: true,
      editable: false
    },
    {
      resizable: true,
      headerName: 'WEIGHT 1',
      field: 'weightBasis1',
      filter: false,
      sortable: true,
      unSortIcon: true,
      editable: true,
      valueSetter: function (params) {
        params.newValue = Number(params.newValue)
        if (typeof params.newValue === "number" && !isNaN(params.newValue)) {
          params.data.weightBasis1 = params.newValue
        } else {
          params.data.weightBasis1 = params.oldValue
        }
      },
      valueFormatter: percentageFormatterWeight,
      cellStyle: { 'background-color': '#F4F6F8', 'border-left': '1px solid grey', "text-align": "right" },
    },
    {
      resizable: true,
      headerName: 'REF STORE I',
      field: 'useReferenceStore1',
      filter: false,
      sortable: true,
      unSortIcon: true,
      cellRenderer: "StrategySelectCellRenderer",
      cellStyle: { 'background-color': '#F4F6F8', 'border-left': '1px solid grey', "text-align": "right" },
    },
    {
      resizable: true,
      headerName: 'SALES BASIS 1',
      field: 'sum1',
      filter: false,
      sortable: true,
      unSortIcon: true,
      editable: false,
      valueFormatter: decimalFormatterSalesBasis,
      cellStyle: function (param) {
        if (param.data.sum1 === 0) {
          return { "background-color": "#f77b65", "border-left": "1px solid grey", "text-align": "right" }
        }
      }
    },
    {
      resizable: true,
      headerName: 'SALES START DT 2',
      field: 'salesHistoryStartDate2',
      filter: false,
      sortable: true,
      unSortIcon: true,
      cellRenderer: "SalesStartDateCellRender2"

    },
    {
      resizable: true,
      headerName: 'SALES END DT 2',
      field: 'salesHistoryEndDate2',
      filter: false,
      sortable: true,
      unSortIcon: true,
      cellRenderer: "SalesEndDateCellRender2"
    },
    {
      resizable: true,
      headerName: 'Exclude OO',
      field: 'excludeOO',
      filter: false,
      sortable: true,
      unSortIcon: true,
      cellStyle: { "text-align": "right" },
      cellRenderer: "IncludeUnRestrictedDCOO"
    },
    {
      resizable: true,
      headerName: 'SALES DEPTH 2',
      field: 'merchHierarchy2',
      filter: false,
      sortable: true,
      unSortIcon: true,
      editable: false
    },
    {
      resizable: true,
      headerName: 'SALES ELEMENT 2',
      field: 'merchSelections2',
      filter: 'agTextColumnFilter',
      sortable: true,
      unSortIcon: true,
      editable: false
    },
    {
      resizable: true,
      headerName: 'WEIGHT 2',
      field: 'weightBasis2',
      filter: false,
      sortable: true,
      unSortIcon: true,
      editable: true,
      valueSetter: function (params) {
        params.newValue = Number(params.newValue)
        if (typeof params.newValue === "number" && !isNaN(params.newValue)) {
          params.data.weightBasis2 = params.newValue
        } else {
          params.data.weightBasis2 = params.oldValue
        }
      },
      valueFormatter: percentageFormatterWeight,
      cellStyle: { 'background-color': '#F4F6F8', 'border-left': '1px solid grey', "text-align": "right" },
    },
    {
      resizable: true,
      headerName: 'REF STORE II',
      field: 'useReferenceStore2',
      filter: false,
      cellRenderer: "StrategySelectRefStore2",
      cellStyle: { 'background-color': '#F4F6F8', 'border-left': '1px solid grey', "text-align": "right" },
    },
    {
      resizable: true,
      headerName: 'SALES BASIS 2',
      field: 'sum2',
      filter: false,
      sortable: true,
      unSortIcon: true,
      editable: false,
      valueFormatter: decimalFormatterSalesBasis,
      cellStyle: function (param) {
        if (param.data.sum2 === 0) {
          return { "background-color": "#f77b65", "border-left": "1px solid grey", "text-align": "right" }
        }
      }
    },
    {
      resizable: true,
      headerName: 'INDEX (Y/N)',
      field: 'useAllIndexValues',
      filter: false,
      sortable: true,
      cellRenderer: "StrategySelectAllStoreCellRender",
      cellStyle: { 'background-color': '#F4F6F8', 'border-left': '1px solid grey', "text-align": "right" },
    },
    {
      resizable: true,
      headerName: 'POISSON INPUT',
      field: 'Target_index',
      filter: false,
      sortable: true,
      unSortIcon: true,
      editable: true,
      valueSetter: function (params) {
        params.newValue = Number(params.newValue)
        if (typeof params.newValue === "number" && !isNaN(params.newValue)) {
          params.data.Target_index = params.newValue
        } else {
          params.data.Target_index = params.oldValue
        }
      },
      valueFormatter: percentageFormatternew,
      cellStyle: { 'background-color': '#F4F6F8', 'border-left': '1px solid grey', "text-align": "right" }
    },
  ]
}