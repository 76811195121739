<template>
  <div style="margin-bottom: 0.5rem;">
    <div class="AllocationDetails" v-if="getJsonData">
      <b-row>
        <b-col cols="3" style="margin-left:0.5rem;">
          <div>
            <strong>Allocation Name:</strong>
            {{getJsonData.allocationBasics.name}}
          </div>
        </b-col>
        <b-col cols="2">
          <div>
            <strong>Push:</strong>
            {{getJsonData.allocationBasics.push}}
          </div>
        </b-col>
        <b-col cols="2">
          <div>
            <strong>Created At:</strong>
            {{format(getJsonData.allocationBasics.createdAt, 'MM/DD/YYYY')}}
          </div>
        </b-col>
        <b-col cols="4">
          <div>
            <strong>File Name:</strong>
            {{getJsonData.allocationBasics.fileName}}
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="AllocationDetails">
      <b-row>
        <b-col cols="8">
          <calendar-filter @selectedCalendarInput="selectedCalendarInput"></calendar-filter>
        </b-col>
        <b-col cols="4">
          <b-button
            class="btn-download"
            style="margin-left: 1rem;"
            :disabled="downloadFlag"
            @click="downloadSAp"
            download
          >
            SAP Report
            <i style="padding-left:0.25rem;" class="fa fa-download"></i>
          </b-button>
          <b-button
            v-if="!reportSummaryFlag"
            class="btn-download cursor-not-allowed"
            style="margin-left: 5px;"
            @click="downloadAllocationReports"
            download
          >
            Summary Report
            <i style="padding-left:0.25rem;" class="fa fa-download"></i>
          </b-button>
          <b-button
            v-else
            class="btn-download cursor-pointer"
            style="margin-left: 5px;"
            @click="downloadAllocationReports"
            download
          >
            Summary Report
            <i style="padding-left:0.25rem;" class="fa fa-download"></i>
          </b-button>
        </b-col>
      </b-row>
      <div align="right"></div>
    </div>

    <b-tabs content-class="mt-3">
      <b-tab title="Store Level Reports" active>
        <div v-if="getAllocationReport && getAllocationReport!=null">
          <b-card>
            <div slot="header">
              <strong>Allocation Report</strong>
            </div>
            <div role="tablist">
              <b-card no-body class="mb-1">
                <b-card-header
                  style="background-color:#A9A9A9 !important;"
                  header-tag="header"
                  class="p-0"
                  role="tab"
                >
                  <b-button
                    block
                    style="color: black; background-color:#A9A9A9 !important; border-color: #A9A9A9 !important;"
                    href="#"
                    v-b-toggle.storeLevelTotal
                    variant="info"
                  >{{reportHeaderTab1Arr[0]}}</b-button>
                </b-card-header>
                <b-collapse id="storeLevelTotal" visible accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <data-table
                      :data="getAllocationReport.TotalStores"
                      :colDefs="StoreLevelTotal"
                      :autoResize="false"
                      :count="getAllocationReport.TotalStores?getAllocationReport.TotalStores.length:0"
                    ></data-table>
                  </b-card-body>
                </b-collapse>
              </b-card>
              <b-card no-body class="mb-1">
                <b-card-header
                  style="background-color:#C0C0C0 !important;"
                  header-tag="header"
                  class="p-0"
                  role="tab"
                >
                  <b-button
                    block
                    style="color: black; background-color:#C0C0C0 !important; border-color: #C0C0C0 !important;"
                    href="#"
                    v-b-toggle.storeLevelStore
                    variant="info"
                  >{{reportHeaderTab1Arr[1]}}</b-button>
                </b-card-header>
                <b-collapse id="storeLevelStore" visible accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <data-table
                      :data="getAllocationReport.store"
                      :colDefs="StoreLevelStore"
                      :autoResize="false"
                      :count="getAllocationReport.store?getAllocationReport.store.length:0"
                    ></data-table>
                  </b-card-body>
                </b-collapse>
              </b-card>
              <b-card no-body class="mb-1">
                <b-card-header
                  style="background-color:#D3D3D3 !important;"
                  header-tag="header"
                  class="p-0"
                  role="tab"
                >
                  <b-button
                    block
                    style="color: black; background-color:#D3D3D3 !important; border-color: #D3D3D3 !important;"
                    href="#"
                    v-b-toggle.storeLevelIACluster
                    variant="info"
                  >{{reportHeaderTab1Arr[2]}}</b-button>
                </b-card-header>
                <b-collapse
                  id="storeLevelIACluster"
                  visible
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <data-table
                      :data="getAllocationReport.IACluster"
                      :colDefs="StoreLevelIACluster"
                      :autoResize="false"
                      :count="getAllocationReport.IACluster?getAllocationReport.IACluster.length:0"
                    ></data-table>
                  </b-card-body>
                </b-collapse>
              </b-card>
              <b-card no-body class="mb-1">
                <b-card-header
                  style="background-color:#DCDCDC !important;"
                  header-tag="header"
                  class="p-0"
                  role="tab"
                >
                  <b-button
                    block
                    style="color: black; background-color:#DCDCDC !important; border-color: #DCDCDC !important;"
                    href="#"
                    v-b-toggle.storeLevelTier
                    variant="info"
                  >{{reportHeaderTab1Arr[3]}}</b-button>
                </b-card-header>
                <b-collapse id="storeLevelTier" visible accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <data-table
                      :data="getAllocationReport.tier"
                      :colDefs="StoreLevelTier"
                      :autoResize="false"
                      :count="getAllocationReport.tier?getAllocationReport.tier.length:0"
                    ></data-table>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </b-card>
        </div>
      </b-tab>
      <b-tab title="Article Level Reports">
        <p>Article Level Reports</p>
        <div v-if="getAllocationReport  && getAllocationReport!=null">
          <b-card>
            <div slot="header">
              <strong>Allocation Report</strong>
            </div>
            <div role="tablist">
              <b-card no-body class="mb-1">
                <b-card-header
                  header-tag="header"
                  style="background-color:#A9A9A9 !important;"
                  class="p-0"
                  role="tab"
                >
                  <b-button
                    block
                    href="#"
                    style="color: black; background-color:#A9A9A9 !important; border-color: #A9A9A9 !important;"
                    v-b-toggle="reportHeaderTab2Arr[0].replace(' ', '-')"
                    variant="info"
                  >{{reportHeaderTab2Arr[0]}}</b-button>
                </b-card-header>
                <b-collapse
                  :id="reportHeaderTab2Arr[0].replace(' ', '-')"
                  visible
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <data-table
                      :data="getAllocationReport.TotalProducts"
                      :colDefs="ReportScreenColDef2"
                      :autoResize="false"
                      :count="getAllocationReport.TotalProducts?getAllocationReport.TotalProducts.length:0"
                    ></data-table>
                  </b-card-body>
                </b-collapse>
              </b-card>
              <b-card no-body class="mb-1">
                <b-card-header
                  header-tag="header"
                  style="background-color:#C0C0C0 !important;"
                  class="p-0"
                  role="tab"
                >
                  <b-button
                    block
                    href="#"
                    style="color: black; background-color:#C0C0C0 !important; border-color: #C0C0C0 !important;"
                    v-b-toggle="reportHeaderTab2Arr[1].replace(' ', '-')"
                    variant="info"
                  >{{reportHeaderTab2Arr[1]}}</b-button>
                </b-card-header>
                <b-collapse
                  :id="reportHeaderTab2Arr[1].replace(' ', '-')"
                  visible
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <data-table
                      :data="getAllocationReport.articles"
                      :colDefs="ReportScreenColDef2"
                      :autoResize="false"
                      :count="getAllocationReport.articles?getAllocationReport.articles.length:0"
                    ></data-table>
                  </b-card-body>
                </b-collapse>
              </b-card>
              <b-card no-body class="mb-1">
                <b-card-header
                  header-tag="header"
                  style="background-color:#D3D3D3 !important;"
                  class="p-0"
                  role="tab"
                >
                  <b-button
                    block
                    href="#"
                    style="color: black; background-color:#D3D3D3 !important; border-color: #D3D3D3 !important;"
                    v-b-toggle="reportHeaderTab2Arr[2].replace(' ', '-')"
                    variant="info"
                  >{{reportHeaderTab2Arr[2]}}</b-button>
                </b-card-header>
                <b-collapse
                  :id="reportHeaderTab2Arr[2].replace(' ', '-')"
                  visible
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <data-table
                      :data="getAllocationReport.Collection"
                      :colDefs="ReportScreenColDef2"
                      :autoResize="false"
                      :count="getAllocationReport.Collection?getAllocationReport.Collection.length:0"
                    ></data-table>
                  </b-card-body>
                </b-collapse>
              </b-card>
              <b-card no-body class="mb-1">
                <b-card-header
                  header-tag="header"
                  style="background-color:#D3D3D3 !important;"
                  class="p-0"
                  role="tab"
                >
                  <b-button
                    block
                    href="#"
                    style="color: black; background-color:#D3D3D3 !important; border-color: #D3D3D3 !important;"
                    v-b-toggle="reportHeaderTab2Arr[3].replace(' ', '-')"
                    variant="info"
                  >{{reportHeaderTab2Arr[3]}}</b-button>
                </b-card-header>
                <b-collapse
                  :id="reportHeaderTab2Arr[3].replace(' ', '-')"
                  visible
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <data-table
                      :data="getAllocationReport.merchCat"
                      :colDefs="ReportScreenColDef2"
                      :autoResize="false"
                      :count="getAllocationReport.merchCat?getAllocationReport.merchCat.length:0"
                    ></data-table>
                  </b-card-body>
                </b-collapse>
              </b-card>
              <b-card no-body class="mb-1">
                <b-card-header
                  header-tag="header"
                  style="background-color:#DCDCDC !important;"
                  class="p-0"
                  role="tab"
                >
                  <b-button
                    block
                    href="#"
                    style="color: black; background-color:#DCDCDC !important; border-color: #DCDCDC !important;"
                    v-b-toggle="reportHeaderTab2Arr[4].replace(' ', '-')"
                    variant="info"
                  >{{reportHeaderTab2Arr[4]}}</b-button>
                </b-card-header>
                <b-collapse
                  :id="reportHeaderTab2Arr[4].replace(' ', '-')"
                  visible
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <data-table
                      :data="getAllocationReport.cat"
                      :colDefs="ReportScreenColDef2"
                      :autoResize="false"
                      :count="getAllocationReport.cat?getAllocationReport.cat.length:0"
                    ></data-table>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </b-card>
        </div>
      </b-tab>
      <b-tab title="Article-Tier Reports">
         <div v-if="getAllocationReport && getAllocationReport!=null">
         <!-- <div> -->
          <b-card>
            <div slot="header">
              <strong>Average of Allocation</strong>
            </div>
            <div role="tablist">
              <b-card no-body class="mb-1">
                <b-card-body>
                  <data-table
                    :data="getAllocationReport.articleTierReport"
                    :colDefs="ArticleTierReport"
                    :autoResize="false"
                    :count="getAllocationReport.articleTierReport?getAllocationReport.articleTierReport.length:0"
                  ></data-table>
                </b-card-body>
              </b-card>
            </div>
          </b-card>
        </div>
      </b-tab>
      <!-- <b-tab title="LM level report"><p>LM level report</p></b-tab> -->
    </b-tabs>
  </div>
</template>

<script>
import calendarFilter from "@/components/ViewPortCalenderFilter";
import DataTable from "@/components/DataTable";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { filter, cloneDeep } from "lodash";
import { format } from "date-fns";
import ReportScreenColDef from "@/constants/tableDefinitions/ReportScreenColDef";
import StoreLevelTotal from "@/constants/tableDefinitions/StoreLevelTotal";
import StoreLevelStore from "@/constants/tableDefinitions/StoreLevelStore";
import StoreLevelIACluster from "@/constants/tableDefinitions/StoreLevelIACluster";
import StoreLevelTier from "@/constants/tableDefinitions/StoreLevelTier";
import ArticleTierReport from "@/constants/tableDefinitions/ArticleTierReport";
import ReportScreenColDef2 from "@/constants/tableDefinitions/ReportScreenColDef2";
export default {
  data() {
    return {
      reportSummaryFlag: false,
      AllocationReportArr: [],
      AllocationReportArr2: [],
      reportHeaderTab1Arr: ["Total", "Store", "IA Cluster", "Tier"],
      reportHeaderTab2Arr: ["Total", "Article", "Char Collection","Merch Category", "Category"],
      downloadFlag: false,
      format,
      siteData: [],
      Reports_Joann: [],
      calendarInput: {},
      ReportScreenColDef,
      StoreLevelTotal,
      StoreLevelStore,
      StoreLevelIACluster,
      StoreLevelTier,
      ArticleTierReport,
      ReportScreenColDef2,
      selected: "total",
      options: [
        { value: "total", text: "Total" },
        { value: "comp", text: "Comp" },
        { value: "store", text: "Store" }
      ],
      popoverShow: false,
      columToUpdae: "",
      valueToUpdate: "",
      targrtAllocationUnit: ""
    };
  },
  props: ["Reports_Joann1"],
  components: {
    DataTable,
    calendarFilter
  },
  created() {
    // let siteDataTemp = window.localStorage.getItem("siteData");
    // this.siteData = JSON.parse(siteDataTemp);
  },
  beforeDestroy(){
    // this.setAllocationReport(null)
    this.StoreLevelTotal=null
    this.StoreLevelStore=null
    this.StoreLevelIACluster=null
    this.StoreLevelTier=null
    this.ReportScreenColDef2=null
  },
  mounted() {
    this.Reports_Joann = JSON.parse(JSON.stringify(this.Reports_Joann1));
      this.Reports_Joann = [];
      const sites = this.getJsonData.sites
      const len1 = sites.length
      let articleSites = {}
      for (let i = 0; i < len1; i++) {
        const site = sites[i]
        const len2 = site.Selections.length
        const Selections = site.Selections
        for (let j = 0; j < len2; j++) {
          const Selection = Selections[j]
          const storeID = Number(Selection.store_id)
          articleSites[Number(site.ArticleId)+'-'+storeID] = Number(Selection.avl)
        }
      }
      // let lenSelSite = this.siteData.length;
      // let siteSelected = {};

      // for (let i = 0; i < lenSelSite; i++) {
      //   siteSelected[Number(this.siteData[i].store_id)] = this.siteData[i];
      // }
      this.getJsonData.finalizeAllocation.forEach(element => {
        var NewObj = {};
        const aid = Number(element["Article ID"])
        const sid = Number(element["Store ID"])
        NewObj["Store_ID"] = sid;
        NewObj["Product_ID"] = aid;
        NewObj["OH"] = parseFloat(element["Store OH"]);
        NewObj["OO"] = parseFloat(element["Store OO"]);
        NewObj["Current_Allocation"] = element["Allocation"];
        NewObj["Sales_Basis_1_SD"] = "1970-01-01";
        NewObj["Sales_Basis_1_ED"] = "1970-01-01";
        NewObj["Sales_Basis_2_SD"] = "1970-01-01";
        NewObj["Sales_Basis_2_ED"] = "1970-01-01";
        NewObj["Merch_cat"] = element.merch_cat_descr;
        NewObj["Category"] = element.category_descr;
        // let siteObj = siteSelected[Number(element["Store ID"])];

        NewObj["Tier"] = articleSites[aid+'-'+sid];
        this.Reports_Joann.push(NewObj);
      });
    
    this.AllocationReport({
      Reports_Joann: this.Reports_Joann,
      allocationID: this.getJsonData.allocationBasics.allocationID
    });
  },
  computed: {
    ...mapGetters({
      getAllocationReport: "CreateAllocation/getAllocationReport",
      getJsonData: "CreateAllocation/getJsonData",
      getTempJsonData: "CreateAllocation/getTempJsonData",
      getSAPReports: "CreateAllocation/getSAPReports",
      getDownloadReport: "CreateAllocation/getDownloadReport",
      getAllocationReportDownload: "CreateAllocation/getAllocationReportDownload"
    })
  },
  methods: {
    ...mapActions({
      AllocationReport: "CreateAllocation/AllocationReport",
      downloadSAPReports: "CreateAllocation/downloadSAPReports",
      AllocationReportDownload: "CreateAllocation/AllocationReportDownload",
      DownloadAllocation: "CreateAllocation/DownloadAllocation"
    }),
    ...mapMutations({
      setAllocationReport: "CreateAllocation/setAllocationReport",
      setSAPReports: "CreateAllocation/setSAPReports",
      setAllocationReportDownload: "CreateAllocation/setAllocationReportDownload",
      setDownloadReport: "CreateAllocation/setDownloadReport",
    }),
    selectedCalendarInput(val) {
      this.setAllocationReport(null);
      this.Reports_Joann = [];
      const sites = this.getJsonData.sites
      const len1 = sites.length
      let articleSites = {}
      for (let i = 0; i < len1; i++) {
        const site = sites[i]
        const len2 = site.Selections.length
        const Selections = site.Selections
        for (let j = 0; j < len2; j++) {
          const Selection = Selections[j]
          const storeID = Number(Selection.store_id)
          articleSites[Number(site.ArticleId)+'-'+storeID] = Number(Selection.avl)
        }
      }
      // this.calendarInput = val
      this.getJsonData.finalizeAllocation.forEach(element => {
        var NewObj = {};
        const aid = Number(element["Article ID"])
        const sid = Number(element["Store ID"])
        NewObj["Store_ID"] = sid;
        NewObj["Product_ID"] = aid;
        NewObj["OH"] = parseFloat(element["Store OH"]);
        NewObj["OO"] = parseFloat(element["Store OO"]);
        NewObj["Current_Allocation"] = element["Allocation"];
        NewObj["Sales_Basis_1_SD"] = val.salesBasis1Start;
        NewObj["Sales_Basis_1_ED"] = val.salesBasis1End;
        NewObj["Sales_Basis_2_SD"] = val.salesBasis2Start;
        NewObj["Sales_Basis_2_ED"] = val.salesBasis2End;
        NewObj["Merch_cat"] = element.merch_cat_descr;
        NewObj["Category"] = element.category_descr;
        NewObj["Tier"] = articleSites[aid+'-'+sid];
        this.Reports_Joann.push(NewObj);
      });
      this.AllocationReport({
        Reports_Joann: this.Reports_Joann,
        allocationID: this.getJsonData.allocationBasics.allocationID
      });

      this.$gtag.event('Clicked_SalesDateRangeApplyButton', {
        'event_category': 'Report Screen',
        'event_label': 'Click on Apply Button',
      })
    },
    downloadAllocationReports(donloadClick) {
      this.AllocationReportDownload({
        Reports_Joann: this.Reports_Joann,
        allocationID: this.getJsonData.allocationBasics.allocationID
      });

      this.$gtag.event('Clicked_SummaryReportDownloadButton', {
        'event_category': 'Report Screen',
        'event_label': 'Click on Summary report Button',
      })
    },
    downloadSAp(downloadSAP) {
      this.downloadSAPReports({
        allocationID: this.getJsonData.allocationBasics.allocationID
      });

      this.$gtag.event('Clicked_SAPReportDownloadButton', {
        'event_category': 'Report Screen',
        'event_label': 'Click on SAP Report button',
      })
    },
    downloadReports(downloadReports) {
      this.DownloadAllocation({
        allocationID: this.getJsonData.allocationBasics.allocationID
      });
    }
  },
  watch: {
    getJsonData(newVal, OldVal) {
      if (newVal.Reports_Joann.length) {
        this.reportSummaryFlag = true;
      }
      // if(newVal.results) {
      //    newVal.results.store.forEach(obj1 => {
      //   obj1.OH_perc = (obj1.OH_perc ).toFixed(2)
      //   obj1.OO_perc = (obj1.OO_perc ).toFixed(2)
      //   obj1.Current_Allocation_perc = (obj1.Current_Allocation_perc ).toFixed(2)
      //   obj1.Total_fill_perc = (obj1.Total_fill_perc ).toFixed(2)
      // })
      // newVal.results.tier.forEach(obj2 => {
      //   obj2.OH_perc = (obj2.OH_perc ).toFixed(2)
      //   obj2.OO_perc = (obj2.OO_perc ).toFixed(2)
      //   obj2.Current_Allocation_perc = (obj2.Current_Allocation_perc ).toFixed(2)
      //   obj2.Total_fill_perc = (obj2.Total_fill_perc ).toFixed(2)
      // })

      // this.AllocationReportArr[0] = newVal.results.TotalStores
      // this.AllocationReportArr[1] = newVal.results.store
      // this.AllocationReportArr[2] = newVal.results.IACluster
      // this.AllocationReportArr[3] = newVal.results.tier

      // this.AllocationReportArr2[0] = newVal.results.TotalProducts
      // this.AllocationReportArr2[1] = newVal.results.articles
      // this.AllocationReportArr2[2] = newVal.results.merchCat
      // this.AllocationReportArr2[3] = newVal.results.cat

      // }
      if (
        newVal.allocationBasics.allocationState === "SUBMITTED" &&
        OldVal.allocationBasics.allocationState === "DRAFT"
      ) {
        this.$router.push("/dashboard");
      }
    },
    targrtAllocationUnit(val) {
      this.valueToUpdate = val;
      this.columToUpdae = "allocation";
    },
    getSAPReports(val) {
      let a = document.createElement("A");
      a.href = val.data.url;
      a.setAttribute("target", "_blank");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      this.setSAPReports(null)
    },
    getDownloadReport(val) {
      let a = document.createElement("A");
      a.href = val.data.url;
      a.setAttribute("target", "_blank");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      this.setDownloadReport(null);
    },
    getAllocationReportDownload(val) {
      let a = document.createElement("A");
      a.href = val.data.url;
      a.setAttribute("target", "_blank");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      this.setAllocationReportDownload(null)
    }
  }
};
</script>

<style scoped>
.ag-theme-balham .ag-header-group-cell-label span {
  float: centre;
}
.btn-info {
  color: white;
  font-weight: 600;
  background-color: #72bd00;
}
.btn-info:hover {
  color: white;
  background-color: #6aad00;
}
.btn-download {
  color: #71be00 !important;
  border-color: #71be00;
  background-color: rgb(255, 255, 255) !important;
}
.btn-download:hover {
  color: #71be00 !important;
  border-color: #71be00;
  background-color: rgb(202, 213, 219) !important;
}
.card-body {
  padding: 0.25rem !important;
}
</style>
<style>
</style>

