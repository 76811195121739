import { decimalFormatterStep6 } from "@/helpers/formatters";

export default {
  defaultColDef: {
    animateRows: true,
    sortable: true,
    filter: "agTextColumnFilter",
    unSortIcon: true
  },
  columnDefs: [
    {
      resizable: true,
      headerName: "",
      children: [
        {
          resizable: true,
          headerName: "Article".toUpperCase(),
          field: "Product_ID"
        },
        {
          resizable: true,
          headerName: "Article Desc".toUpperCase(),
          field: "Article_Description"
        }
      ]
    },
    {
      resizable: true,
      headerName: "Units Allocated".toUpperCase(),
      children: new Array(9).fill("").map((item, index) => {
        return {
          resizable: true,
          headerName: "AVL0" + (index + 1),
          field: `AVL_${index + 1}_alloc`,
          valueFormatter: params => {
            if(params.value == "-"){
              return params.value
            } else {
              return decimalFormatterStep6(params);
            }
          }
        };
      })
    },
    {
      resizable: true,
      headerName: "Units Allocated Compared To Average Store".toUpperCase(),
      children: new Array(9).fill("").map((item, index) => {
        return {
          resizable: true,
          headerName: "AVL0" + (index + 1),
          field: `AVL_${index + 1}_relative`,
          valueFormatter: params => {
            if(params.value == "-"){
              return params.value
            } else {
              return decimalFormatterStep6(params);
            }
          }
        };
      })
    }
  ]
};